import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-virtual-assistant',
    templateUrl: './virtual-assistant.component.html',
    styleUrls: ['./virtual-assistant.component.scss']
})
export class VirtualAssistantComponent implements OnInit {
    @Input() text: string;
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    closeAssistant() {
        this.close.emit();
    }

}
