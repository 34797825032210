import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SnackService {
    public showSnack: Subject<{ code, message }>;

    constructor() {
        this.showSnack = new Subject<{ code, message }>();
    }

    /* get $checkStatus(): Observable<{ code, message }> {
         console.log('checkpermission');
         return this.hasPermission.asObservable();
     }

     public setRequestStatus(requestStatus: { code, message }) {
         console.log('setRequestStatus', requestStatus);
         this.hasPermission.next(requestStatus);
     }*/
}
