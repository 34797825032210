<m-portlet>
	<!-- use ng-container[mPortletHeadTitle] for the title -->
	<ng-container mPortletHeadTitle>
		<div class="m-portlet__head-title">
			<h3 class="m-portlet__head-text">
				Support Tickets
			</h3>
		</div>
	</ng-container>

	<!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
	<ng-container mPortletHeadTools>
		<ul class="m-portlet__nav">
			<li class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover" aria-expanded="true">
				<a href="#" class="m-portlet__nav-link m-portlet__nav-link--icon m-portlet__nav-link--icon-xl m-dropdown__toggle">
					<i class="la la-ellipsis-h m--font-brand"></i>
				</a>
				<div class="m-dropdown__wrapper">
					<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
					<div class="m-dropdown__inner">
						<div class="m-dropdown__body">
							<div class="m-dropdown__content">
								<ul class="m-nav">
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-share"></i>
											<span class="m-nav__link-text">Activity</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-chat-1"></i>
											<span class="m-nav__link-text">Messages</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-info"></i>
											<span class="m-nav__link-text">FAQ</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-lifebuoy"></i>
											<span class="m-nav__link-text">Support</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</ng-container>

	<!-- use ng-container[mPortletBody] for the body content -->
	<ng-container mPortletBody>
		<div class="m-widget3">
			<div class="m-widget3__item">
				<div class="m-widget3__header">
					<div class="m-widget3__user-img">
						<img class="m-widget3__img" src="./assets/app/media/img/users/user1.jpg" alt="">
					</div>
					<div class="m-widget3__info">
						<span class="m-widget3__username">
							Melania Trump
						</span>
						<br>
						<span class="m-widget3__time">
							2 day ago
						</span>
					</div>
					<span class="m-widget3__status m--font-info">
						Pending
					</span>
				</div>
				<div class="m-widget3__body">
					<p class="m-widget3__text">
						Lorem ipsum dolor sit amet,consectetuer edipiscing elit,sed diam nonummy nibh euismod tinciduntut laoreet doloremagna aliquam erat volutpat.
					</p>
				</div>
			</div>
			<div class="m-widget3__item">
				<div class="m-widget3__header">
					<div class="m-widget3__user-img">
						<img class="m-widget3__img" src="./assets/app/media/img/users/user4.jpg" alt="">
					</div>
					<div class="m-widget3__info">
						<span class="m-widget3__username">
							Lebron King James
						</span>
						<br>
						<span class="m-widget3__time">
							1 day ago
						</span>
					</div>
					<span class="m-widget3__status m--font-brand">
						Open
					</span>
				</div>
				<div class="m-widget3__body">
					<p class="m-widget3__text">
						Lorem ipsum dolor sit amet,consectetuer edipiscing elit,sed diam nonummy nibh euismod tinciduntut laoreet doloremagna aliquam erat volutpat.Ut wisi enim ad minim veniam,quis nostrud exerci tation ullamcorper.
					</p>
				</div>
			</div>
			<div class="m-widget3__item">
				<div class="m-widget3__header">
					<div class="m-widget3__user-img">
						<img class="m-widget3__img" src="./assets/app/media/img/users/user5.jpg" alt="">
					</div>
					<div class="m-widget3__info">
						<span class="m-widget3__username">
							Deb Gibson
						</span>
						<br>
						<span class="m-widget3__time">
							3 weeks ago
						</span>
					</div>
					<span class="m-widget3__status m--font-success">
						Closed
					</span>
				</div>
				<div class="m-widget3__body">
					<p class="m-widget3__text">
						Lorem ipsum dolor sit amet,consectetuer edipiscing elit,sed diam nonummy nibh euismod tinciduntut laoreet doloremagna aliquam erat volutpat.
					</p>
				</div>
			</div>
		</div>
	</ng-container>
</m-portlet>
