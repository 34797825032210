import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-file',
    template: `
        <div class="upload">
            <input [formControl]="formControl" [formlyAttributes]="field" type="file"  name="imagen" id="imagen">
            <label for="imagen">{{'SELECT' | translate}}</label>
        </div>
  `,
})
export class FormlyFieldFile extends FieldType {}
