// Spain
export const locale = {
    lang: 'es',
    data: {
        TRANSLATOR: {SELECT: 'Elige tu idioma'},
        HORA: 'Hora',
        PACIENTE: 'Paciente',
        PACIENTES: 'Pacientes',
        INVOICES: 'Facturas',
        SALA: 'Sala',
        MODIFICAR: 'Modificar',
        MODIFYANDEXIT: 'Guardar y salir',
        CANCELAR: 'Cancelar',
        BORRAR: 'Borrar',
        ERRORGUARDANDO: 'Error al guardar',
        ERROROBTENERDATOS: 'Error al obtener los datos',
        BUSCARALARMA: 'Buscar Alarma',
        SEARCH: 'Buscar',
        PATIENTFULLNAME: 'Nombre del paciente',
        TITLE: 'Título',
        VALUE: 'Valor',
        NOTAS: 'Notas',
        GUARDAR: 'Guardar',
        NEW: 'Nueva',
        NEWM: 'Nuevo',
        MEDICO: 'Médico',
        FECHA: 'Fecha',
        TIPSHOWALLFILTER: 'Usa * para mostrar todo',
        SELECT: 'Seleccionar',
        DRUGS: 'Fármacos',
        PRINT: 'Imprimir',
        INSERT: 'Insertar',
        LOADING: 'Cargando',
        EXPORTANDO: 'Obteniendo datos para exportación',
        UDS: 'Uds',
        AMOUNT: 'Importe',
        COD: 'Cod',
        DTO: 'Dto Imp',
        DTOPORC: 'Dto %',
        DESCRIPTION: 'Desc',
        TOTAL: 'Total',
        PHONE: 'Teléfono',
        MOBILE: 'Móvil',
        SIGNUP: 'Dar de alta',
        SIGNINGUP: 'Dando de alta el usuario',
        SIGNUPOK: 'Alta correcta',
        SIGNUPKO: 'Error al dar de alta',
        ERRORPASSWORDNOTMATCH: 'Las contraseñas no coinciden',
        ERRORMINLENGTH: 'Debe tener al menos {{num}} caracteres',
        INITDATE: 'Fecha Inicio',
        INITHOUR: 'Hora Inicio',
        ENDDATE: 'Fecha Fin',
        ENDHOUR: 'Hora Fin',
        PRINTCALENDAR: 'Imprimir calendario',
        SELECTPERSONALCLINICAL: 'Seleccione personal sanitario',
        FIELDMANDATORY: '{{nameField}} es obligatorio',
        LOGOUT: 'Cerrar sesión',
        SAVEOK: 'Guardado correctamente',
        SAVEERROR: 'Error al guardar',
        SELECTLOGO: 'Seleccione logo',
        CLOSE: 'Cerrar',
        SCHEDULEREPORTVOID: 'No se pudo generar el informe porque no hay citas en el intervalo / profesional seleccionado',
        NOAUTH: 'No tiene permiso para acceder a esta funcionalidad, consulte con su administrador.',
        APPOINTMENT: 'Cita',
        IMAGES: 'Imágenes',
        IMAGEGALLERY: 'Galería de imágenes',
        IMAGETOOBIG: 'La imagen es muy grande, el tamaño máximo es de {{size}}',
        SELECTINGIMAGE: 'Seleccionando imagen',
        SAVING: 'Guardando',
        CLINIC: 'Clínica',
        RECOVERYPASS: 'Recuperar contraseña',
        ADDDIAGNOSTIC: 'Añadir diagnóstico',
        ADDSELECTEDTREATMENT: 'Añadir tratamiento seleccionado',
        EXPAND: 'Expandir',
        CONTRACT: 'Contraer',
        ACCEPT: 'Aceptar',
        FILTERBYDESCRIPTION: 'Filtrar por descripción',
        FILTERBYCODE: 'Filtrar por código',
        BILLTYPE: 'Tipo de factura',
        NOLINESINVOICE: 'No tienes lineas asociadas a las factura',
        CREATINGINVOICE: 'Generando factura',
        IVATYPE: 'Tipo IVA',
        ADDIVA: 'Añadir IVA',
        CANCELANDRESETFORM: 'Cancelar y limpiar formulario',
        CODEEXISTS: 'El código ya existe',
        NCONTRACT: 'Nº Contrato',
        DOCTORNAME: 'Personal clínico',
        CONCEPT: 'Concepto',
        GOTOUNSUBSCRIBE: 'Se dispone a cancelar la suscripción {{text}}',
        GOTOREACTIVESUBSCRIBE: 'Se dispone a reactivar la suscripción {{text}}',
        CANTSUBSCRIBECAUSEINTERVAL: 'No puede contratar este plan. Pongase en contacto con INCEME en info@inceme.com o en el teléfono 966787629',
        NOCHANGES: 'No se han hecho cambios',
        ACTIVE: 'Activo',
        NOACTIVE: 'No activo',
        year: 'año',
        month: 'mes',
        WILLCANCELAT: 'Se cancelará el ',
        WILLRENEWAT: 'Se renovará el ',
        REMOVESUBSCRIPTION: 'Eliminar suscripción',
        REACTIVESUBSCRIPTION: 'Re-activar suscripción',
        CARDEXPIRATIONDATE: 'Fecha de cad',
        CHANGECARD: 'Cambiar Tarjeta',
        ADDCARD: 'Añadir Tarjeta',
        NOACTIVESUBSCRIPTION: 'No hay ninguna suscripción activa',
        FREESUBSCRIPTIONTEXT: 'Suscripción Gratuita',
        OTHERPLANS: 'Otros Planes Disponibles',
        USERS: 'Usuarios',
        CALENDARS: 'Agendas',
        ESTABLISHMENTS: 'Establecimientos',
        UNLIMITED: 'ilimitado',
        SUBSCRIBETOIT: 'Suscribirse',
        YES: 'Si',
        MODIFYSUBSCRIPTION: 'Modificar suscripcion',
        SUBSCRIPTIONDETAILS: 'Detalles de la suscripción',
        User: 'Usuario normal',
        Administrator: 'Administrador',
        BACKUP: 'Copia de Seguridad',
        INCLUDEINYOURPLAN: 'Tu plan incluye',
        MOREINFOPLAN: 'Mi plan (+ info)',
        REQUIRECOMPLETEPAYMENTMETHOD: 'Antes de suscribirse necesita cumplimentar el método de pago',
        SUBSCRIBEPLAN: 'Contratar un plan',
        CREDITCARD: 'Tarjeta de crédito',
        UNIQUECANTDELETE: 'Este es el último registro. Es necesario al menos un registro y por ese motivo no es posible borrar',
        SELECTEDPATIENT: 'Paciente seleccionado',
        ALL: 'Todos',
        SEND: 'Enviar',
        INVOICEPENDING: 'Pendiente de facturar',
        BILLING: 'Facturación',
        REMOVECLINIC: 'BORRAR TODOS LOS DATOS DE LA CLÍNICA',
        CONFIRMDELETE: 'He leido y estoy de acuerdo con borrar todos los datos de mi clínica.',
        LASTCHANCE: 'Esta es la última oportunidad para evitar que toda su información se borre ¿Seguro que desea continuar?',
        DELETINGDATA: 'BORRANDO LOS DATOS',
        DELETEDREDIRECT: 'CLÍNICA ELIMINADA.. REDIRECCIONANDO A PANTALLA DE INICIO',
        ANTECEDENTTOOLTIP: 'Pulse modificar para empezar a escribir',
        AGEVALIDATOR: 'La fecha de nacimiento no puede ser posterior a la actual',
        VALIDATECHARGE: 'Su banco requiere que valide su suscripción',
        VALIDATE: 'Validar',
        NOTVALIDUSER: 'Usuario no válido. Contacte con soporte técnico en inceme.com',
        PAID: 'Pagado',
        CIF: 'CIF',
        SETCOOKIES: 'Configurar cookies',
        CHANGESNOSAVED: 'Hay cambios sin guardar. Si continua se perderán ¿Desea continuar?',
        DELETESTROKE: 'Borrar linea',
        UNDO: 'Deshacer',
        EDITIMAGE: 'Edición de imagen',
        THICKNESS: 'Grosor',
        NEWPHOTO: 'Nueva Foto',
        INACTIVE: 'Inactivo',
        NUMSESSIONS: 'Num sesiones',
        EXPIREDATE: 'Caducidad',
        USEDSESSIONS: 'Sesiones usadas',
        SELECTAPATIENT: 'Debe seleccionar un paciente',
        AREYOUSUREDELETE: 'Se dispone a borrar un elemento. Esto no podrá deshacerse ¿seguro que desea continuar?',
        BONOACTIVE: 'Bono activo',
        BONOEXPIRED: 'Bono caducado',
        BONOOWNER: 'Titular bono',
        EMAILNOTVALID: 'No es una dirección de correo válida',
        RESOLUTIONNOTVALID: 'La resolucíon es muy pequeña. Puede que no veas bien todas las opciones. Se aconseja mínimo 960px',
        FIRSTAPPOINTMENT: 'Primera visita',
        SECONDAPPOINTMENT: 'Segunda visita',
        THIRDAPPOINTMENT: 'Tercera visita',
        PRIVACYPOLICYACCEPT: 'He leido y acepto la política de privacidad',
        INFOSIGNUP: 'Para crear tu <b>cuenta gratuita en el programa de gestión de clínicas</b> inceme necesitamos cierta información.',
        CONTACTMEBYEMAILON: 'Si tienes cualquier duda recuerda que puedes contactar con nosotros en ',
        ACCESSDATA: 'Datos de acceso',
        INFOACCESSDATA: 'Estos datos te permitirán <strong> acceder al programa </strong> Gestión de clínicas',
        INCEMETITLE: 'Inceme Gestión de clínicas y centros médicos',
        COMPANYDATA: 'Datos de la clínica',
        CREATEFREEACCOUNT: 'Crear una cuenta gratuita',
        COMPANYDATAINFO: 'Datos de tu empresa para cuando crees <strong>citas y facturas a tus pacientes</strong>',
        LEGALNOTICE: 'Aviso legal',
        YOUSHOULDACCEPTPOLICY: 'Debe aceptar la política de privacidad',
        PATIENTNAME: 'Nombre del paciente',
        INFOHOWCREATENEWBONO: 'Pulse "nuevo" para crear un bono o edite un bono existente',
        CREATINGNEWBONO: 'Creando nuevo bono',
        EDITINGBONO: 'Editando bono',
        UNIQUENUMBERERROR: 'El código ya está en uso.',
        FILTERS: 'Filtros',
        AGREEMODELS: 'Modelos de consentimiento',
        DELETEDOK: 'Borrado correctamente',
        DELETEDKO: 'Error al borrar',
        DOCUMENTSENDMARKOK: 'Documento marcado como enviado',
        DOCUMENTSENDMARKKO: 'No se ha podido marcar el documento como enviado',
        GENERATINGLINK: 'Generando enlace',
        PATIENTAGREEMENTSENDMESSAGE: 'Le enviamos el siguiente documento para su firma. Clique en el enlace y siga las instrucciones',
        EMAILCONFIGINCOMPLET: 'Datos no válidos para enviar email',
        MOBILECONFIGINCOMPLET: 'Datos no válidos para enviar al teléfono',
        FILETOSIGN: 'Fichero para firmar',
        SENDOK: 'Envio correcto',
        SENDKO: 'Hubo algún error con el envio',
        PACIENTEBENEFICIARIOBONO: 'Beneficiarios',
        DELETEDPATIENTBENEFICIARY: 'Paciente Beneficiario borrado',
        ADDPATIENTBENEFICIARY: 'Paciente Beneficiario añadido',
        AGREEMODELSFIELDS: {
            TITLE: 'Título',
            COMMENTS: 'Notas',
            CONTENT: 'Contenido'
        },
        PATIENTAGREEMODEL: 'Acuerdos y consentimientos',
        SENDTOSIGN: 'Enviar para firma',
        NOTIFICATIONTITLEDIALOG: 'Envio por {{sendBy}} a {{to}}',
        SIGNDATE: 'Fecha de firma',
        ADDCLAUSE: 'Añadir clausula',
        ADDCLAUSES: 'Añade clausulas al contrato',
        ADDCLAUSETOTEXT: 'Añadir al texto',
        COMUNICACIONES: 'Comunicaciones',
        DATESERROR: 'Las fechas no son correctas',
        SELECTEDPERSONALCLINICA: 'Personal clínico seleccionado',
        PERMISSIONS: 'Permisos',
        ROL_OPTION: 'Permisos del rol',
        CONFIG: 'Configuración',
        EMAILREMAINDERMSG: 'Texto para recordatorio de citas',
        REMAINDER24H: 'Recordar al menos 24h antes por email',
        REMAINDER24HADVISE: 'Solo se puede activar el recordatorio automático si quedan más de 2 horas para la cita',
        SENDING: 'Enviando',
        PATIENTINFO: {
            LIST: {
                INTRO: 'Parece que aún no tienes ningún paciente, dejame explicarte lo que puedes hacer desde aquí.<br/>' +
                    'Podrás crear, buscar, modificar y eliminar los pacientes de tu clínica. ' +
                    'Si deseas continuar con la explicación pulsa <strong>siguiente</strong>, si no cierra esta ventana y podrás volver a ver estas explicaciones ' +
                    'cuando tu quieras pulsando en el botón "tour virtual" que tienes en la parte superior de la ventana.',
                NEWPATIENT: 'Al pulsar este botón accederas a un formulario para crear un nuevo paciente.',
                FILTER: 'Usa estos campos para poder buscar los pacientes, puedes combinarnos para conseguir ' +
                    'una busqueda que se ajuste a lo que necesitas.',
                GRID: 'Aquí ves el listado de tus pacientes, en cada fila tendrás un botón para modificar el paciente o eliminarlo'
            }
        },
        CALENDARSINFO: {
            INTRO: 'Parece que no has visto nuestro tutorial sobre este apartado. ' +
                'Si deseas verlo pulsa en el botón SIGUIENTE. Si prefieres hacerlo en otro momento, cierra esta ventana y cuando lo deses puedes pulsar en "Tour Virtual" <br/> ' +
                'Desde las agendas vas a poder gestionar las citas de todos los profesionales de tu clínicas así como ' +
                'definir que tratamientos y costes se van a aplicar a tu paciente. Pulsa <strong>siguiente para continuar</strong>',
            NEW: 'Al pulsar este botón accederás al formulario en el que crear una nueva cita. Podras:' +
                '<ul>' +
                '<li>Definir el paciente</li>' +
                '<li>Definir la hora de inicio y fin</li>' +
                '<li>Especificar el profesional y especialidad que va a tratar al paciente</li>' +
                '<li>Definir los tratamientos y el coste de los mismos</li>' +
                '<li>Usar un bono de sesiones en caso que el paciente disponga de alguno</li>' +
                '<li>Definir el estado (está en la clínica, ha sido atendido, ha faltado)</li>' +
                '<li>Facturar la cita</li>' +
                '</ul>',
            REMAINDER: 'Cuando seleccionas una cita en el calendario, podrás pulsar este botón para enviar un ' +
                'recordatorio por whatsapp o por email a tu paciente. Recuerda que para enviar recordatorios por whatsapp ' +
                'debes tenerlo abierto en el navegador (si conectas desde ordenador) o tener instalada la aplicación.<br/>' +
                'En caso de querer enviar el recordatorio por email, debes configurar previamente los datos del servidor de envio ' +
                'desde el apartado de clínica.',
            FILTER: 'Desde aquí podrás filtrar las citas en base a varios criterios. Los criterios disponibles van a depender ' +
                'de la licencia que tengas. Los posibles filtros son:' +
                '<ul>' +
                '<li>Establecimientos</li>' +
                '<li>Personal clínico</li>' +
                '<li>Salas</li>' +
                '<li>Mutuas</li>' +
                '</ul>',
            SELECTDATE: 'Selecciona que fecha (día o semana) deseas ver de tu agenda.',
            SCHEDULE: 'Puedes seleccionar ver la agenda en vista <strong>día</strong> o en vista <strong>semana</strong>.' +
                'Puedes insteactuar con las diferentes citas clicando sobre ellas y seleccionando una de las opciones que te ofrece.' +
                '<br/>Además tendrás información en forma de iconos o colores sobre su estado, si está facturada, si es primera visita...' +
                'y mucha más cosas.'
        },
        PERSONALCLINICALINFO: {
            INTRO: 'Parece que no has visto nuestro tutorial sobre este apartado. ' +
                'Si deseas verlo pulsa en el botón SIGUIENTE. Si prefieres hacerlo en otro momento, cierra esta ventana y cuando lo deses puedes pulsar en "Tour Virtual" ',
            NEW: 'Pulsando este botón se abrirá el formulario que te permite crear un nuevo registro de personal clínico. ' +
                'Recuerda que según la licencia que tengas, puede que no puedas tener más de una persona.',
            LIST: 'Se lista el personal clínico  que tienes dado de alta en tu clínica. Desde este listado podrás acceder a cada ' +
                'registro para modificarlo e incluso eliminarlo.'
        },
        APPOINTMENTADDINFO: {
            INTRO: 'Parece que no has visto nuestro tutorial sobre este apartado. ' +
                'Si deseas verlo pulsa en el botón SIGUIENTE. <br/>Si prefieres hacerlo en otro momento, cierra esta ventana y cuando lo desees puedes pulsar en "Tour Virtual" ',
            PATIENT: 'Las citas van vinculadas a un paciente. Siempre debes seleccionar un paciente al crear una cita.',
            APPOINTMENTTYPE: 'El sistema, de forma automática, determina si se trata de la primera, segunda, tercera o sucesivas citas. ' +
                'No obstante puedes forzar el valor que consideres',
            PERSONALCLINIC: 'En este campo defines el personal clínico que va a atender al paciente y por lo tanto en que agenda debe mostrarse esta cita',
            ESPECIALITY: 'Este campo se auto rellena según la especialidad del personal clínico seleccionado',
            MUTUA: 'Si el paciente tiene asignada alguna mutua, en este campo podrás indicarla en caso que la cita deba vincularse a dicha mutua.',
            ESTABLISHMENT: 'Cuando tu clínica disponga de varios establecimientos, desde aquí puedes indicar en cual se va a pasar la consulta. ' +
                'Esto te va a permitir tener agendas totalmente diferenciadas por establecimiento.',
            ROOM: 'Es posible que la consulta deba pasarse en una sala concreta. Con este campo puedes seleccionar ' +
                'entre las salas que hayas dado de alta en tu clínica.',
            STATES: 'Mediante los estados puede ver si un paciente está ya en la clínica, si está siendo antendido, ' +
                'si ha finalizado su consulta o incluso si no ha acudido a la cita.',
            INIT: 'Indica la fecha y hora de inicio estimada de la cita',
            END: 'Indica la hora de fin estimada de la cita',
            BONO: 'Un paciente puede haber contratado un bono con sesiones. ' +
                'Desde este desplegable puede seleccionar el bono al que deseas descontar la sesión correspondiente a esta cita.',
            NOTIFICATIONS: 'Las citas se pueden notificar por email o whatsapp. Aquí verás las veces que se ha notificado al ' +
                'paciente la presente cita.',
            ASSISTANCE: 'Factura de forma sencilla las citas a través del asistente de facturación.',
            SAVE: 'Guarda los cambios realizados en la cita',
        },
        VIRTUALTOUR: 'Tour virtual',
        DAY: {M: 'Lunes', T: 'Martes', W: 'Miercoles', TH: 'Jueves', F: 'Viernes', S: 'Sábado', SU: 'Domingo'},
        SELECTEDPERSONALCLINICALNOWORK: 'El personal clínico seleccionado no pasa consulta los: ',
        INFO: ' Información',
        NODATA: 'No hay datos para mostrar',
        FILEPATIENT: 'Ficheros',
        FILE: 'Fichero',
        DOCUMENTS: 'Documentos',
        SIGNUPFORM: {
            NAME: 'Indique su nombre',
            EMAIL: 'Indique su email',
            PASSWORD: 'Indique su contraseña',
            REPEATPASSWORD: 'Repita su contraseña',
            COMPANY: 'Nombre de la clínica',
            ADDRESS: 'Dirección',
            POSTCODE: 'Código Postal',
            COUNTRY: 'Pais',
            PROVINCE: 'Provincia',
            LOCALITY: 'Localidad',
            SIGNUPCONTACTPERSON: 'Persona de contacto'
        },
        PRESCRIPTION: {
            TITLE: 'Recetas',
            SINGLETITLE: 'Receta',
            DRUGNAME: 'Fármaco',
            UPDATED: 'Receta actualizada',
            ERRORUPDATING: 'Error al actualizar la receta',
            DELETED: 'Receta borrada',
            CONFIRMDELETE: 'Esta acción no podrá deshacerse ¿desea continuar y borrar esta receta?',
            INSTRUCTIONS: 'Instrucciones',
            CONTAINER: 'Envase',
            UNITS: 'Unidades',
            POSOLOGY: 'Posología',
            PATTERN: 'Pauta',
            ADMINISTRATION_WAY: 'Vía de administración',
            ADITIONAL_INFO: 'Información adicional',
            PACTIVOS: 'P. Activos',
            DRUGSEARCH: 'Escriba el farmaco que busca',
            INPUTDRAGNAME: 'Seleccione un farmaco o escriba el texto que desee'
        },
        ALARMAS: {
            BUSCARALARMA: 'Buscar Alarma',
            TITLE: 'Alarmas médicas (alergias y otras alertas importantes)'
        },
        MENU: {
            NEW: 'Nuevo',
            ACTIONS: 'Comportamiento',
            CREATE_POST: 'Crear nueva publicación',
            REPORTS: 'Informes',
            APPS: 'Aplicaciones',
            DASHBOARD: 'Tablero',
            USER: 'Usuario',
        },
        FORMS: {
            CAMPO_REQUERIDO: 'Campo requerido',
            MINLENGHT: 'El texto debe ser mayor',
            VALOR_INCORRECTO: 'Valor incorrecto',
            COD_OR_NAME_PATIENT: 'Código o nombre (Paciente)',
            MUTUA: 'Mutua',
            FECHA_INICIO: 'Fecha inicio',
            FECHA_FIN: 'Fecha fin',
            FECHA_FACTURA: 'Fecha factura',
            SERIE: 'Serie',
            METODO_PAGO: 'Método de pago',
            CODIGO_PROPIO: 'C.Propio',
            CODIGO: 'Código',
            NOMBRE: 'Nombre',
            NOMBRE_COMPLETO: 'Nombre completo',
            APELLIDO1: 'Apellido1',
            APELLIDO2: 'Apellido2',
            FECHA_ALTA: 'Fecha de alta',
            FECHA_BAJA: 'Fecha de baja',
            FECHA_NACIMIENTO: 'Fecha de nacimiento',
            EDAD: 'Edad',
            SEXO: 'Sexo',
            DNI: 'DNI',
            PASAPORTE: 'Pasaporte',
            NIE: 'NIE',
            CORREO: 'Correo',
            TELEFONOS: 'Teléfonos',
            MOVILES: 'Móviles',
            CODIGO_POSTAL: 'Código postal',
            DIRECCION: 'Dirección',
            PAIS: 'País',
            PROVINCIA: 'Provincia',
            LOCALIDAD: 'Localidad',
            TUTOR_LEGAL: 'Tutor Legal',
            NOTAS: 'Notas'

        },
        BTN: {ADD: 'Añadir'},
        AUTH: {
            GENERAL: {
                OR: 'O',
                SUBMIT_BUTTON: 'Enviar',
                NO_ACCOUNT: 'No tienes una cuenta?',
                SIGNUP_BUTTON: 'Regístrate',
                FORGOT_BUTTON: 'Se te olvidó tu contraseña',
                BACK_BUTTON: 'Espalda',
                PRIVACY: 'Intimidad',
                LEGAL: 'Legal',
                CONTACT: 'Contacto',
                PLACEHOLDER: {
                    USERNAME: 'Email',
                    PASSWORD: 'Contraseña',
                    REPEATPASSWORD: 'Repita la constraseña'
                },
            },
            LOGIN: {
                TITLE: 'Acceso a Inceme',
                BUTTON: 'Acceder',
                UNKONW_ERROR: 'Error inesperado. Intente nuevamente',
            },
            FORGOT: {
                TITLE: 'Contraseña olvidada?',
                DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.',
            },
            INPUT: {
                USERNAME: 'Usuario',
                EMAIL: 'Correo',
                FULLNAME: 'Nombre completo',
                PASSWORD: 'Contraseña',
                CONFIRM_PASSWORD: 'Confirmar contraseña',
            },
            VALIDATION: {
                INVALID: '{{name}} no es valido',
                REQUIRED: '{{name}} es requerido',
                MIN_LENGTH: '{{name}} minima longitud {{min}}',
                AGREEMENT_REQUIRED: 'Aceptación de terminos y condiciones es obligatoria',
                NOT_FOUND: 'El valor para {{name}} no se ha especificado',
                INVALID_LOGIN: 'Credenciales inválidas',
                REQUIRED_FIELD: 'El usuario y la contraseña son obligatorios.',
            },
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields',
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted',
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted',
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated',
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created',
                },
            },
        },
        ERROR_SMS: {
            UNKONW_ERROR: 'Error inesperado. Intente nuevamente',
        },
        TABLE: {
            LOADING: {
                DELETE: 'Eliminando',
                LOAD_DATA: 'Cargando',
                EDIT: 'Actualizando',
                ADD: 'Creando {item}',
            },
            SEARCH: 'Buscar',
            COLUMNS: 'Colunmas',
            EXPORT: 'Exportar',
            TOOLTIP: {
                BTN_EDIT: 'Editar',
                BTN_DELETE: 'Eliminar',
                BTN_SELECTBENEFICIARY: 'Seleccionar beneficiarios'
            },
            STATUS: 'Estado',
            CREATED_AT: 'Fecha de creación',
            UPDATED_AT: 'Fecha de modificación',
            REQUIRED_FIELD: 'Campo requerido',
            EMAIL_ERROR: 'El correo no tiene formato correcto',
            ROLE: {
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el role ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            SERIE: {
                COLUMNS: {
                    SERIE_NAME: 'Serie',
                    CONTADOR: 'Contador',
                    DEFAULT_SERIE: 'Serie por defecto',
                    CREATED: 'Creado',
                    UPDATED: 'Actualizado'
                },
                TITLE: 'Listado de series para facturas',
                TITLE_ADD: 'Nuevo serie',
                TITLE_EDIT: 'Editar serie',
                TITLE_DELETE: 'Borrar serie',
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar la serie ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            IVA: {
                COLUMNS: {
                    IVA_NAME: 'Iva',
                    VALUE: 'Valor',
                    CREATED: 'Creado',
                },
                TITLE: 'Tipos de IVA',
                TITLE_ADD: 'Nuevo IVA',
                TITLE_EDIT: 'Editar IVA',
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el iva "{{iva}}".',
                    EDIT: 'Se editó satisfactoriamente el iva ',
                    ADD: 'Se creó satisfactoriamente el iva "{{iva}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el IVA ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            DIAGNOSTIC: {
                COLUMNS: {
                    DIAGNOSTIC: 'Diagnóstico',
                    OBSERVATIONS: 'Observaciones',
                    LEVEL: 'Level',
                    SUBLEVEL: 'Sub Level',
                    CIE: 'Cie',
                    CIE10: 'Cie10',
                    FAVORITE: 'Favorito',
                    CREATED: 'Creado',
                    UPDATED: 'Actualizado',
                },
                TITLE: 'Diagnósticos',
                TITLE_ADD: 'Nuevo Diagnóstico',
                TITLE_EDIT: 'Editar Diagnóstico',
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el Diagnóstico.',
                    EDIT: 'Se editó satisfactoriamente el Diagnóstico. ',
                    ADD: 'Se creó satisfactoriamente el Diagnóstico.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el Diagnóstico ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            USER: {
                BTN_ADD: 'Nuevo usuario',
                ITEM: 'usuario',
                COLUMNS: {
                    FULL_NAME: 'Nombre completo',
                    USERNAME: 'Usuario',
                    EMAIL: 'Correo',
                    ROL: 'Rol',
                    DOC: 'Médico',
                    CREATED: 'Creado',
                },
                CANCELANDRETURN: 'Cancelar y regresar al listado',
                TITLE: 'Listado de usuarios',
                TITLE_ADD: 'Nuevo usuario',
                TITLE_EDIT: 'Editar usuario',
                DATA: 'Datos de usuarios',
                ERROR_SMS: {
                    DELETE_YOURSELF: 'Error al eliminar el usuario "{{user}}". El usuario autenticado no puede ser eliminado.',
                    DELETE_GENERIC: 'Error al eliminar el usuario "{{user}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar el usuario "{{user}}". Por favor intente nuevamente.',
                    ADD: 'Error al crear el usuario {{user}}.',
                    ADD_GENERIC: 'Error al crear el usuario "{{user}}". Por favor intente nuevamente.',
                    ADD_PERSONAL_CLINIC: 'Error al crear el usuario "{{user}}". El médico seleccionado ya ha sido utilizado.',
                    EDIT: 'Error al editar el usuario "{{user}}".',
                    EDIT_PERSONAL_CLINIC: 'Error al editar el usuario "{{user}}". El médico seleccionado ya ha sido utilizado.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el usuario "{{user}}".',
                    EDIT: 'Se editó satisfactoriamente el usuario ',
                    ADD: 'Se creó satisfactoriamente el usuario "{{user}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el usuario ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            PERSONALCLINICAL: {
                BTN_ADD: 'Nuevo personal clínico',
                ITEM: 'personal clínico',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    MOBILES: 'Móviles',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                    SPECIALTY: 'Especialidad',
                    IDENTIFICATIONTYPE: 'Tipo identificación',
                    IDENTIFICATION: 'Identificación',
                    AFFILIATECODE: 'Nº Colegiado'
                },
                TITLE: 'Listado del personal clínico',
                TITLE_ADD: 'Nuevo personal clínico',
                TITLE_EDIT: 'Editar personal clínico',
                DATA: 'Datos del personal clínico',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar el personal clínico "{{personal_clinico}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar el personal clínico "{{personal_clinico}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar el personal clínico "{{personal_clinico}}". El identificador ya se encuentra registrado en el sistema.',
                    ADD: 'Error al crear el personal clínico "{{personal_clinico}}". El identificador ya se encuentra registrado en el sistema.',
                    ADD_GENERIC: 'Error al crear el personal clínico "{{personal_clinico}}". Por favor intente nuevamente.',
                    AFFILIATEEXIST: 'Error, el número de colegiado ya está dado de alta'
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el personal clínico "{{personal_clinico}}".',
                    EDIT: 'Se editó satisfactoriamente el personal clínico "{{personal_clinico}}".',
                    ADD: 'Se creó satisfactoriamente el personal clínico "{{personal_clinico}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el personal clínico ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            PATIENT: {
                BTN_ADD: 'Nuevo paciente',
                ITEM: 'personal clínico',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de pacientes',
                TITLE_ADD: 'Nuevo paciente',
                TITLE_EDIT: 'Editar paciente',
                DATA: 'Datos del paciente',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar el paciente "{{patient}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar el paciente "{{patient}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar el paciente "{{patient}}". El identificador ya se encuentra registrado en el sistema.',
                    ADD: 'Error al crear el paciente "{{patient}}". El identificador ya se encuentra registrado en el sistema.',
                    ADD_GENERIC: 'Error al crear el paciente "{{patient}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el paciente "{{patient}}".',
                    EDIT: 'Se editó satisfactoriamente el paciente "{{patient}}".',
                    ADD: 'Se creó satisfactoriamente el paciente "{{patient}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el paciente ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
                TABS: {
                    GENERAL_DATA: 'Datos generales',
                    MUTUAL_ENTERPRICE: 'Mutuas y empresas',
                    DONE: 'Finalizar',
                },
            },
            CLINIC: {
                BTN_ADD: 'Nueva clínica',
                ITEM: 'clínica',
                COLUMNS: {
                    NAME: 'Nombre',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    MOBILES: 'Moviles',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de clínicas',
                DATA: 'Datos de la clínica',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la clínica {{clinic}}. Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la clínica {{clinic}}. Por favor intente nuevamente.',
                    ADD: 'Error al crear la clínica {{clinic}}. La clínica ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear la clínica {{clinic}}. Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la clínica "{{clinic}}".',
                    EDIT: 'Se editó satisfactoriamente la clínica ',
                    ADD: 'Se creó satisfactoriamente la clínica {{clinic}}.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: 'Se dipone a eliminar la clínica y todos sus datos del sistema. Esta acción no puede deshacerse y conlleva el borrado de toda la información así como la baja de la suscripción que tenga activa. ¿Desea continuar eliminando la clínica y sus datos? ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            MUTUAL: {
                BTN_ADD: 'Nueva mutua',
                TITLE_ADD: 'Nueva mutua',
                TITLE_EDIT: 'Editar mutua',
                ITEM: 'mutua',
                COLUMNS: {
                    NAME: 'Nombre',
                    CIF: 'CIF',
                    CONTRACTNUMBER: 'Número de contrato',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    COD_SPECIALTY: 'Especialidad',
                    PHONES: 'Teléfonos',
                    MOBILES: 'Moviles',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    NOTES: 'Notas',
                    CONTACTPERSON: 'Persona de contacto',
                    URLWEB: 'Url Web',
                    FILE: 'Archivo',
                    LOGO: 'Logotipo',
                    TARIFF: 'Tarifa',
                },
                TITLE: 'Listado de mutuas',
                DATA: 'Datos de la mutua',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la mutua {{mutual}}. Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la mutua {{mutual}}. Por favor intente nuevamente.',
                    ADD: 'Error al crear la mutua {{mutual}}. La mutua ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear la mutua {{mutual}}. Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la mutua "{{mutual}}".',
                    EDIT: 'Se editó satisfactoriamente la mutua {{mutual}}.',
                    ADD: 'Se creó satisfactoriamente la mutua {{mutual}}.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar la mutua ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            BANK: {
                BTN_ADD: 'Nuevo banco',
                ITEM: 'banco',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de bancos',
                TITLE_ADD: 'Nuevo banco',
                TITLE_EDIT: 'Editar banco',
                DATA: 'Datos del banco',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar el banco "{{bank}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar el banco "{{bank}}". Por favor intente nuevamente.',
                    ADD: 'Error al crear el banco "{{bank}}". El banco ya se encuentra registrado en el sistema.',
                    ADD_GENERIC: 'Error al crear el banco "{{bank}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el banco "{{bank}}".',
                    EDIT: 'Se editó satisfactoriamente el banco "{{bank}}".',
                    ADD: 'Se creó satisfactoriamente el banco "{{bank}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS:
                            '¿Desea eliminar el banco ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            INVOICE: {
                BTN_ADD: 'Nueva factura',
                ITEM: 'Factura',
                COLUMNS: {
                    NUMBER: 'Numero de factura',
                    SERIE: 'Serie',
                    FORMTOPAYMENT: 'Formas de pago',
                    EMITEDDATE: 'Oficina',
                    CODE: 'Dirección',
                    PATIENT: 'Código postal',
                    TYPE: 'País',
                    MUTUAL: 'Provincia',
                    COMPANY: 'Municipio',
                    PAID: 'Teléfonos',
                    WAYTOPAY: 'Fax',
                    CARDDATE: 'Correo',
                    SENDDATE: 'Url Web',
                    DTO: 'Dto',
                    TOTAL: 'Total',
                },
                TITLE: 'Listado de facturas',
                TITLE_ADD: 'Nueva factura',
                TITLE_EDIT: 'Editar factura',
                DATA: 'Datos de la factura',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la factura "{{invoice}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la factura "{{invoice}}". Por favor intente nuevamente.',
                    ADD: 'Error al crear la factura "{{invoice}}". El banco ya se encuentra registrado en el sistema.',
                    ADD_GENERIC: 'Error al crear la factura "{{invoice}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la factura "{{invoice}}".',
                    EDIT: 'Se editó satisfactoriamente la factura "{{invoice}}".',
                    ADD: 'Se creó satisfactoriamente la factura "{{invoice}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS:
                            '¿Desea eliminar la factura ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            INVOICE_ASSISTANT: {
                TITLE: 'Asistente de facturación',
                BTN_CREAR_FACTURA: 'Crear factura',
                BTN_ADD_FACTURA: 'Añadir a factura',
                TABLA: {
                    COD: 'COD',
                    DESCRIPTION: 'Descripción',
                    UNIDADES: 'Uds',
                    CLINICA: 'Clínica',
                    DOCTOR: 'Doctor',
                    PERCENT_DISCOUNT: 'Dto %',
                    TOTAL: 'Total'
                }
            },
            INVOICE_CONCEPT: {
                BTN_ADD: 'Nuevo concepto',
                ITEM: 'concepto facturable',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                },
                TITLE: 'Conceptos facturables',
                TITLE_ADD: 'Nuevo concepto facturable',
                TITLE_EDIT: 'Editar concepto facturable',
                DATA: 'Datos del concepto',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar el concepto "{{concept}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar el concepto "{{concept}}". Por favor intente nuevamente.',
                    ADD: 'Error al crear el concepto "{{concept}}". El código ya se encuentra registrado en el sistema.',
                    ADD_GENERIC: 'Error al crear el concepto "{{concept}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar el concepto "{{concept}}". El código ya se encuentra registrado en el sistema',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el concepto "{{concept}}".',
                    EDIT: 'Se editó satisfactoriamente el concepto "{{concept}}".',
                    ADD: 'Se creó satisfactoriamente el concepto "{{concept}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el concepto ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            MUTUAL_ENTERPRICE: {
                TITLE_MUTUA: 'Listado de mutuas',
                TITLE_ENTERPRICE: 'Listado de empresas',
                MUTUA_ACTIVE: 'Mutua o Empresa activa:',
            },
            ESTABLISHMENT: {
                BTN_ADD: 'Nuevo establecimiento',
                ITEM: 'establecimiento',
                ITEM_PLURAL: 'Establecimientos',
                SELECT: 'Establecimiento',
                COLUMNS: {
                    NAME: 'Nombre',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    CLINIC: 'Clinica',
                    PHONES: 'Teléfonos',
                    MOBILES: 'Moviles',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de establecimientos',
                DATA: 'Datos del establecimiento',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar el establecimiento {{establishment}}. Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar el establecimiento {{establishment}}. Por favor intente nuevamente.',
                    ADD: 'Error al crear el establecimiento {{establishment}}. El establecimiento ya se encuentra registrado en el sistema.',
                    ADD_GENERIC: 'Error al crear el establecimiento {{establishment}}. Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el establecimiento "{{establishment}}".',
                    EDIT: 'Se editó satisfactoriamente el establecimiento {{establishment}}.',
                    ADD: 'Se creó satisfactoriamente el establecimiento "{{establishment}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el establecimiento ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            TARIFF: {
                BTN_ADD: 'Nueva tarifa',
                ITEM: 'tarifa',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de tarifas',
                TITLE_ADD: 'Nueva tarifa',
                TITLE_EDIT: 'Editar tarifa',
                DATA: 'Datos de la tarifa',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la tarifa "{{tariff}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la tarifa "{{tariff}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar la tarifa "{{tariff}}". El código ya se encuentra registrada en el sistema.',
                    ADD: 'Error al crear la tarifa "{{tariff}}". El código ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear la tarifa "{{tariff}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la tarifa "{{tariff}}".',
                    EDIT: 'Se editó satisfactoriamente la tarifa "{{tariff}}".',
                    ADD: 'Se creó satisfactoriamente la tarifa "{{tariff}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar la tarifa ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            CATEGORY_FAMILY: {
                BTN_ADD: 'Nueva familia',
                ITEM: 'familia',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de familias',
                TITLE_ADD: 'Nueva familia',
                TITLE_EDIT: 'Editar familia',
                DATA: 'Datos de la familia',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la familia "{{family}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la familia "{{family}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar la familia "{{family}}". El código ya se encuentra registrada en el sistema.',
                    ADD: 'Error al crear la familia "{{family}}". El código ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear la familia "{{family}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la familia "{{family}}".',
                    EDIT: 'Se editó satisfactoriamente la familia "{{family}}".',
                    ADD: 'Se creó satisfactoriamente la familia "{{family}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: 'Si borra la familia también se borrarán los conceptos asociados. ¿Desea eliminar la familia ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            COMPANY: {
                BTN_ADD: 'Nueva empresa',
                ITEM: 'empresa',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                    OWNER: 'Titular'
                },
                TITLE: 'Listado de empresas',
                TITLE_ADD: 'Nueva empresa',
                TITLE_EDIT: 'Editar empresa',
                DATA: 'Datos de la empresa',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la empresa "{{company}}". Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la empresa "{{company}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar la empresa "{{company}}". El código ya se encuentra registrada en el sistema.',
                    ADD: 'Error al crear la empresa "{{company}}". El código ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear la empresa "{{company}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la empresa "{{company}}".',
                    EDIT: 'Se editó satisfactoriamente la empresa "{{company}}".',
                    ADD: 'Se creó satisfactoriamente la empresa "{{company}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar la empresa ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
            },
            CODIFIER: {
                BTN_ADD: 'Nuevo codificador',
                ITEM: 'codificador',
                COLUMNS: {
                    NAME: 'Nombre',
                    ENTITY: 'Entidad',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    URLWEB: 'Url Web',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de codificadores',
                TITLE_ADD: 'Nuevo codificador',
                TITLE_EDIT: 'Editar codificador',
                DATA: 'Datos del codificador',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar el codificador "{{codifier}}". Por favor intente nuevamente.',
                    EDIT: 'Error al editar el codificador "{{codifier}}". El codificador ya se encuentra registrada en el sistema.',
                    ADD: 'Error al crear el codificador "{{codifier}}". El codificador ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear el codificador "{{codifier}}". Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente el codificador "{{codifier}}".',
                    EDIT: 'Se editó satisfactoriamente el codificador "{{codifier}}".',
                    ADD: 'Se creó satisfactoriamente el codificador "{{codifier}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar el codificador ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                    ADD: {
                        ADD_BTN_INSERT: 'Insertar',
                        ADD_BTN_CANCEL: 'Cancelar',
                    },
                    EDIT: {
                        BTN_EDIT: 'Editar',
                    },
                },
            },
            APPOINTMENT: {
                SINGULARTITLE: 'Citas',
                BTN_ADD: 'Nueva cita',
                TITLE_ADD: 'Nueva cita',
                TITLE_EDIT: 'Editar cita',
                TITLE_DELETE: 'Eliminar',
                TITLE_DUPLICATE: 'Duplicar',
                ITEM: 'cita',
                REMAINDERS: 'Recordatorios',
                REMAINDERSDESCRIPTION: 'Recordatorios enviados acerca de esta cita',
                SENDREMAINDER: 'Recordar',
                REMAINDERTITLE: 'Recordatorio de cita médica',
                REMAINDERTXT: 'Le escribimos para recordarle que tiene una cita en la clínica {{clinicName}} ' +
                    'el {{dateAndTime}}. Si no puede acudir, por favor avísenos.',
                JUSTIFYREPORTTITLE: 'justificante-cita',
                JUSTIFY: 'Justificante',
                COLUMNS: {
                    NAME: 'Nombre',
                    ADDRESS: 'Dirección',
                    POSTCODE: 'Código postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipio',
                    PHONES: 'Teléfonos',
                    MOBILES: 'Moviles',
                    FAX: 'Fax',
                    EMAIL: 'Correo',
                    NOTES: 'Notas',
                },
                TITLE: 'Listado de citas',
                DATA: 'Datos de la cita',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error al eliminar la cita. Por favor intente nuevamente.',
                    EDIT_GENERIC: 'Error al editar la cita. Por favor intente nuevamente.',
                    ADD: 'Error al crear la cita. La clínica ya se encuentra registrada en el sistema.',
                    ADD_GENERIC: 'Error al crear la cita. Por favor intente nuevamente.',
                },
                SUCCESS_SMS: {
                    DELETE: 'Se eliminó satisfactoriamente la cita.',
                    EDIT: 'Se editó satisfactoriamente la cita.',
                    ADD: 'Se creó satisfactoriamente la cita.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmación',
                        CONFIRMATION_SMS: '¿Desea eliminar la cita?',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
                RECURRENTAPPOINTMENT: 'Citas recurrentes',
                TITLERECURRENTAPPOINTMENT: 'Crear citas recurrentes',
                CONFIGRECURRENTAPPOINTMENT: 'Configurar cita recurrente',
                CREATERECURRENTAPPOINTMENT: 'Generar citas',
                ALMOSTONEDAY: 'Debe marcar al menos 1 día',
                EACHDAY: 'Todos los',
                INIGTEND: 'La fecha de inicio no puede ser mayor o igual que la de fin.',
                CREATEAPPOINTMENTFROM: 'Desde'
            },
            PAGINATE: {
                ITEMS_PER_PAGE_LABEL: 'Elementos por páginas:',
                NEXT_PAGE_LABEL: 'Siguiente',
                PREVIOUS_PAGE_LABEL: 'Anterior',
                FIRST_PAGE_LABEL: 'Primera',
                LAST_PAGE_LABEL: 'Última',
                RANGE_PAGE_LABEL_1: '0 de {{length}}',
                RANGE_PAGE_LABEL_2: '{{startIndex}} - {{endIndex}} de {{length}}',
            },
            EMPTY_DATA: 'No existen datos para mostrar.',
        },
        MEDICALHISTORIAL: {
            TITLE: 'Historial Médico',
            ANTECEDENTS: 'Antecedentes',
            ANTECEDENTSUPDATED: 'Antecedentes guardados',
            ANTECEDENTSERRORUPDATING: 'Error guardando los antecedentes',
            ALERTS: 'Alergias y otras alertas',
            ALARMSUPDATED: 'Alarmas actualizadas correctamente',
            CONSULTA: 'Consulta',
            CONSULTAS: 'Consultas',
            HISTORIALUPDATED: 'Historial guardado correctamente',
            HISTORIALERRORUPDATING: 'Error al guardar el historial',
            MEDICALDIAGNOSTICADDED: 'Diagnóstico añadido a la historia',
            MEDICALDIAGNOSTICERRORADDING: 'Error añadiendo el Diagnóstico a la historia',
            MEDICALDIAGNOSTICDELETED: 'Eliminado Diagnóstico de la historia',
            MEDICALDIAGNOSTICDELETEDERROR: 'Error eliminando Diagnóstico de la historia',
            MEDICALBIOMETRICITEMADDED: 'Diagnóstico añadido a la historia',
            MEDICALBIOMETRICITEMERRORADDING: 'Error añadiendo el Diagnóstico a la historia',
            MEDICALBIOMETRICITEMDELETED: 'Eliminado Diagnóstico de la historia',
            MEDICALBIOMETRICITEMDELETEDERROR: 'Error eliminando Diagnóstico de la historia',
            DELETE: '¿Seguro que desea borrar esta entrada de la historia?',
            REASON: 'Motivo'
        },
        BIOMETRICITEM: {
            TITLE: 'Parámetros para consultas',
            CONCEPT: 'Conceptos Biometría',
            UPDATED: 'Datos y parámetros para las consultas actualizados',
            DELETE: 'Va a borrar el siguiente concepto. ¿Desea continuar?',
            DELETEDIALOGTITLE: 'Borrar concepto',
            DELETED: 'Concepto eliminado',
            NEW: 'Nuevo parámetro para consultas',
            HISTORIAL: 'Historial biometría'
        },
        CALENDARIO: {
            VISTA_CITAS: 'Vista de citas',
            FILTRAR: 'Filtrar citas',
            ESTADO: 'Estado',
            POR_MEDICO: 'Por médico',
            POR_SALA: 'Por sala',
            TODAS: 'Todas',
            ATENDIDA: 'Atendidas',
            CONFIRMADA: 'En clínica',
            FALTA: 'Faltas',
            COBRADA: 'Cobradas',
            COBRADA_FALTA: 'Cobradas-Falta',
            PERSONALCLINICAL: 'Personal clínico',
            INROOM: 'En sala',
            INVOICED: 'Facturada',
            MUTUAS: 'Mutuas',
            ROOMS: 'Salas',
            LEYENDA: 'Leyenda',
            HOY: 'Hoy',
            VISTA_DIA: 'Vista día',
            VISTA_SEMANA: 'Vista Semana',
            VISTA_MULTI: 'Vista por personal clinico',
            DIAS_SEMANA: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
        },
        ROOM: {
            TITLE: 'Listado de salas',
            NAME: 'Nombre',
            DESCRIPTION: 'Descripción',
            UPDATED: 'Sala actualizada',
            DELETE: 'Va a borrar la sala. ¿Desea continuar?',
            DELETEDIALOGTITLE: 'Borrar sala',
            DELETED: 'Sala eliminada',
            ADD: 'Añadir sala',
            ROOM_NAME: 'Nombre de la sala',
            ROOM_DESCRIPTION: 'Descripción de la sala',
            SAVE: 'Guardar',
            CANCEL: 'Cancelar'
        },
    },
};
