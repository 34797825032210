import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {TableColumn} from '../../../../core/interfaces/tablecolumn';
import {Observable} from 'rxjs';
import {ConditionDisableActionGoltable} from '../../../../core/interfaces/condition-disable-action-goltable';

/** ELEMENT_DATA: usado para mockup **/
const ELEMENT_DATA: Array<any> = [
    {id: '11111', position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {id: '11111', position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {id: '11111', position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {id: '11111', position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {id: '11111', position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    {id: '11111', position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    {id: '11111', position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    {id: '11111', position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    {id: '11111', position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    {id: '11111', position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    {id: '11111', position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
    {id: '11111', position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
    {id: '11111', position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
    {id: '11111', position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
    {id: '11111', position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
    {id: '11111', position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
    {id: '11111', position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
    {id: '11111', position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
    {id: '11111', position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
    {id: '11111', position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];
/** COLUMNS: usado para mockup **/
const COLUMNS: Array<TableColumn> = [
    {name: 'position', label: 'Posición', sortable: false, width: '10%'},
    {name: 'name', label: 'Nombre', sortable: true, width: '50%'},
    {name: 'weight', label: 'Peso', sortable: false,}
];

@Component({
    selector: 'm-goltable',
    templateUrl: './goltable.component.html',
    styleUrls: ['./goltable.component.scss']
})
export class GoltableComponent implements OnInit, OnChanges {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    /**
     * Datos que se usan para mostrar en la tabla
     */
    @Input() arrayElements: Observable<any[]>; // Datos
    /**
     * Columnas que se van a mostrar en la tabla / tarjeta.
     * Es un array de tipo TableColumn
     */
    @Input() displayedColumns: TableColumn[]; // columnas que queremos mostrar
    /**
     * El componente puede verse en modo tabla o en modo tarjeta.El modo tarjeta está pensado para cuando se muestre
     * en dispositivos móviles.
     */
    @Input() mode: 'table' | 'card' = 'card'; // muestra los datos en formato tabla o en formato tarjeta
    /**
     * Número máximo de elementos por página
     */
    @Input() pageSize: number; // Num de elementos por página
    /**
     * Página actual
     */
    @Input() pageIndex: number; // Página actual
    /**
     * Numero total de filas
     */
    @Input() length: number; // Numero total de filas
    /**
     * Array de texto con los  botones que queremos que se muestren.
     * Por defecto se muestran todos. Los valores posibles son edit, del y print.
     * Por ejemplo, si queremos mostrar solo edit y print debemos pasar ['edit','print']
     */
    @Input() buttons: Array<string> = ['edit', 'del', 'print']; // permite configurar los botones que se muestran


    @Input() disableConditionEdit: ConditionDisableActionGoltable = undefined;
    @Input() disableConditionDel: ConditionDisableActionGoltable = undefined;
    @Input() disableConditionPrint: ConditionDisableActionGoltable = undefined;

    @Input() hidePaginatorBar: boolean = false;

    /**
     * (solo para modo tabla) Oculta o muestra en la tabla  como primera columna un check, el cual confirme vayamos
     * seleccionando irá rellenando un array con los elementos seleccionados
     */
    /*@Input() showCheckColumn: boolean = false;*/

    /**
     * (esto solo es aplicable en modo tabla de momento) Si el componente se va a usar para seleccionar una de las lineas,
     * le tenemos que pasar select a true. Esto oculta los botones definidos en buttons y muestra un botón para seleccionar.
     * Cuando clicamos en ese botón se invoca el output selected devolviendo el elemento seleccionado
     */
    @Input() select: boolean = false;
    /**
     * evento al pulsar el botón edit
     */
    @Output() edit = new EventEmitter<any>(); // evento al pulsar el botón de editar
    /**
     * evento al pulsar el botón del
     */
    @Output() del = new EventEmitter<any>(); // evento al pulsar botón de borrar
    /**
     * evento al pulsar el boton print
     */
    @Output() print = new EventEmitter<any>(); // evento al pulsar botón de imprimir
    /**
     * evento cada vez que hay un cambio en el paginados (numero de elementos por página, avanzar o retroceder...)
     */
    @Output() changePaginator = new EventEmitter<any>(); // evento que se lanza al cambiar de página o cambiar el número de elementos visibles
    /**
     * evento al ordenar por alguna columna
     */
    @Output() sort = new EventEmitter<any>(); // evento que se lanza cuando ordenamos alguna columna
    /**
     * evento al seleccinoar un elmento (solo en modo tabla y cuando el input select es true)
     */
    @Output() selected = new EventEmitter<any>();
    /**
     * Evento que devuelve los datos de la fila marcada y el nombre de la columna.
     * Se dispara cada vez que cambia el checkbox de una fila
     */
    @Output() checkColumn = new EventEmitter<{ data, nameColumn }>();

    /**
     * Array con el que genero dinamicamente las columnas que se deben mostrar
     */
    _displayedColumns: Array<string> = [];
    _buttons: Array<string> = ['del', 'edit'];
    _disableConditionEdit: ConditionDisableActionGoltable = {column: '', value: ''};
    _disableConditionDel: ConditionDisableActionGoltable = {column: '', value: ''};
    _disableConditionPrint: ConditionDisableActionGoltable = {column: '', value: ''};

    constructor(private cdr: ChangeDetectorRef) {

    }

    checkDisableAction(row, condition: ConditionDisableActionGoltable) {
        let result: boolean = false;
        if (condition.column && condition.column.length > 0) {
            result = row[condition.column] === condition.value;
        }
        return result;
    }

    ngOnInit() {


        /*console.log('ngOnInit datasoruce.data', this.dataSource.data);*/
        /*  this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
  */
    }

    async ngOnChanges(changes: SimpleChanges) {
        /*console.log('onChanges changes',changes);*/
        if (changes.displayedColumns) {
            this._displayedColumns = [];
            this.displayedColumns?.forEach(e => this._displayedColumns.push(e.name));
            this._displayedColumns.push('actions');
        }

        if (changes.buttons) {
            this._buttons = changes.buttons.currentValue;
        }
        if (changes.disableConditionEdit) {
            this._disableConditionEdit = changes.disableConditionEdit.currentValue;
        }
        if (changes.disableConditionDel) {
            this._disableConditionDel = changes.disableConditionDel.currentValue;
        }
        if (changes.disableConditionPrint) {
            this._disableConditionPrint = changes.disableConditionPrint.currentValue;
        }

    }


    _sort(event) {
        this.sort.emit(event);
    }

    _changePaginator(event) {
        this.changePaginator.emit(event);
    }

    _edit(row) {
        this.edit.emit(row);
    }

    _del(row) {
        this.del.emit(row);
    }

    _print(row) {
        this.print.emit(row);
    }

    _selected(row) {
        this.selected.emit(row);
    }

    _checkToggle(ev, nameColumn, row) {
        /*console.log('_checkToggle namecolumn', nameColumn);
        console.log('_checkToggle row', row);*/
        row[nameColumn] = ev.checked;
        /*if (ev.checked === true && row) {
            if (this.singleCheckSelection) {
                this._checkedItems = [];
            }
            this._checkedItems.push(row);

        }*/
        /*if (nameColumn.checked === false && row) {
            const i = this._checkedItems.findIndex(e => e.id === row.id);
            if (i > -1) {
                this._checkedItems.splice(i, 1);
            }
        }*/
        this.checkColumn.emit({data: row, nameColumn: nameColumn});
    }
}
