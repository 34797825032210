<div>
    <h3>{{'EMAILREMAINDERMSG' | translate}}</h3>

    <quill-editor #quillEditor placeholder="Escriba aquí" [(ngModel)]="texto">
        <div quill-editor-toolbar>
            <button class="ql-bold"></button>
            <button class="ql-underline"></button>
            <button class="ql-italic"></button>
            <button class="customButton" (click)="qlCustom1($event)" id="nombreCompleto">Nombre de paciente</button>
            <button class="customButton" (click)="qlCustom1($event)" id="dia">Día</button>
            <button class="customButton" (click)="qlCustom1($event)" id="hora_ini">Hora de inicio</button>
            <button class="customButton" (click)="qlCustom1($event)" id="hora_fin">Hora de fin</button>
        </div>
    </quill-editor>
    <div class="mt-1">
        <button *ngIf="!noSaveButton" mat-button mat-raised-button color="primary" (click)="_save()">{{'GUARDAR' | translate}}</button>
    </div>
</div>
