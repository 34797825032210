<!-- <li class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width" m-dropdown-toggle="click" m-dropdown-persistent="true"> -->
<a href="javascript:;" class="m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon">
	<span class="m-nav__link-badge m-badge m-badge--dot m-badge--dot-small m-badge--danger" [ngClass]="animateBlink"></span>
	<span class="m-nav__link-icon" [ngClass]="animateShake">
		<span class="m-nav__link-icon-wrapper">
			<i class="flaticon-alarm"></i>
		</span>
	</span>
</a>
<div class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
	<div class="m-dropdown__inner">
		<div class="m-dropdown__header m--align-center" style="background: url(./assets/app/media/img/misc/notification_bg.jpg); background-size: cover;">
			<span class="m-dropdown__header-title">
				9 New
			</span>
			<span class="m-dropdown__header-subtitle">
				User Notifications
			</span>
		</div>
		<div class="m-dropdown__body">
			<div class="m-dropdown__content">
				<mat-tab-group>
					<mat-tab label="Alerts">
						<perfect-scrollbar style="height: 250px;">
							<m-list-timeline [type]="'alert'"></m-list-timeline>
						</perfect-scrollbar>
					</mat-tab>
					<mat-tab label="Events">
						<perfect-scrollbar style="height: 250px;">
							<m-list-timeline [type]="'event'"></m-list-timeline>
						</perfect-scrollbar>
					</mat-tab>
					<mat-tab label="Logs">
						<perfect-scrollbar style="height: 250px;">
							<m-list-timeline [type]="'log'"></m-list-timeline>
						</perfect-scrollbar>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
<!-- </li> -->
