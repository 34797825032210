<m-portlet [loading$]="dataSource.loading$">
	<!-- use ng-container[mPortletHeadTitle] for the title -->
	<ng-container mPortletHeadTitle>
		<div class="m-portlet__head-title">
			<h3 class="m-portlet__head-text">
				Recent Products
			</h3>
		</div>
	</ng-container>

	<!-- use ng-container[mPortletBody] for the body content -->
	<ng-container mPortletBody>
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div perfectScrollbar style="max-height: 40vh;" class="mat-table__wrapper mat-table__wrapper--dashboard">
			<!-- Checkbox Column -->
			<!-- Table with selection -->
			<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
			<table mat-table class="lmat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
					<td mat-cell *matCellDef="let product" class="mat-column-vinCode">{{product.id}}</td>
				</ng-container>

				<ng-container matColumnDef="cManufacture">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacture</th>
					<td mat-cell *matCellDef="let product">{{product.cManufacture}}</td>
				</ng-container>

				<ng-container matColumnDef="cModel">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
					<td mat-cell *matCellDef="let product">{{product.cModel}}</td>
				</ng-container>

				<ng-container matColumnDef="cModelYear">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Model Year</th>
					<td mat-cell *matCellDef="let product">{{product.cModelYear}}</td>
				</ng-container>

				<ng-container matColumnDef="cMileage">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Mileage</th>
					<td mat-cell *matCellDef="let product">{{product.cMileage}}</td>
				</ng-container>

				<ng-container matColumnDef="cColor">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Color</th>
					<td mat-cell *matCellDef="let product" [ngStyle]="{ 'color' : product.cColor }">{{product.cColor}}</td>
				</ng-container>

				<ng-container matColumnDef="cPrice">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
					<td mat-cell *matCellDef="let product">${{product.cPrice}}</td>
				</ng-container>

				<ng-container matColumnDef="cStatus">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let product">
						<span class="m-badge m-badge--{{ getItemCssClassByStatus(product.cStatus) }} m-badge--wide">{{ getItemStatusString(product.cStatus) }}</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="cCondition">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Condition</th>
					<td mat-cell *matCellDef="let product">
						<span class="m-badge m-badge--{{ getItemCssClassByCondition(product.cCondition) }} m-badge--dot"></span>&nbsp;
						<span class="m--font-bold m--font-{{ getItemCssClassByCondition(product.cCondition) }}">{{ getItemConditionString(product.cCondition) }}</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>
						Actions
					</th>
					<td mat-cell *matCellDef="let product">
						<button mat-icon-button color="primary" matTooltip="Edit item">
							<mat-icon>create</mat-icon>
						</button>&nbsp;
						<button mat-icon-button color="warn" type="button" matTooltip="Delete item">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>

			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
		</div>
		<!-- start: BOTTOM -->
		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="6" [pageSizeOptions]="[3, 6, 15]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</ng-container>
</m-portlet>