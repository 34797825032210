import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription, BehaviorSubject, timer} from 'rxjs';
import {sessionExpired} from '../../../environments/environment';

@Injectable()
export class IdleTimeoutService {
    private _count: number = 0;
    private _serviceId: string = 'idleTimeoutSvc-' + Math.floor(Math.random() * 10000);
    private _timeoutSeconds: number = sessionExpired;
    private timerSubscription: Subscription;
    private timer: Observable<number>;
    private resetOnTrigger: boolean = false;
    public isInit: boolean;
    public timeoutExpired: Subject<number> = new Subject<number>();

    constructor() {
        this.isInit = false;
    }

    public startTimer() {
        this.isInit = true;
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timer = timer(this._timeoutSeconds * 1000);
        this.timerSubscription = this.timer.subscribe(n => {
            this.timerComplete(n);
        });
    }

    public stopTimer() {
        this.isInit = false;
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    public resetTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timer = timer(this._timeoutSeconds * 1000);
        this.timerSubscription = this.timer.subscribe(n => {
            this.timerComplete(n);
        });
    }

    private timerComplete(n: number) {
        this.timeoutExpired.next(++this._count);

        if (this.resetOnTrigger) {
            this.startTimer();
        }
    }
}
