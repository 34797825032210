import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {TokenStorage} from './token-storage.service';
import {UtilsService} from '../services/utils.service';
import {AccessData} from './access-data';
import {Credential} from './credential';
import {IdleTimeoutService} from './idleTimeout.service';
import {endPoint} from '../../../environments/environment';
import {User} from '../interfaces/user';
import {ConfigClinicStateService} from '../services/config-clinic-state.service';


@Injectable({providedIn: 'root'})
export class AuthenticationService {
    URL_LOGIN = '/users/login';
    public onCredentialUpdated$: Subject<AccessData>;
    public user$: Observable<User>;
    public hasPermission: Subject<{ code: number, message: string }>;
    private headers: any;

    constructor(private http: HttpClient,
                private util: UtilsService,
                private tokenStorage: TokenStorage,
                private sessionTimeOut: IdleTimeoutService,
                private config: ConfigClinicStateService) {
        this.onCredentialUpdated$ = new Subject();
        this.hasPermission = new Subject<{ code: number, message: string }>();

    }

    /**
     * Check, if user already authorized.
     * @description Should return Observable with true or false values
     * @returns {Observable<boolean>}
     * @memberOf AuthService
     */
    public isAuthorized(): Observable<boolean> {
        return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
    }

    public getLoggedUser(): Observable<User> {
        return this.tokenStorage.getAccessUser();
    }

    /**
     * Obtiene el usuario desde la base de datos y actualiza los datos que hay en el local storage.
     * Esto se hace porque a veces necesitamos obtener la información actualizada. Ya que la obtenemos , actualizamos el local storage.
     */
    public getLoggedUserFromDb(): Promise<any> {
        return new Promise(ok => {
            this.headers = new HttpHeaders({
                Authorization: localStorage.getItem('accessToken'),
                'Accept-Language': 'es',
            });
            let accessToken: string = '';
            this.getAccessToken().toPromise().then(token => {
                accessToken = token;
                return this.getLoggedUser().toPromise();
            }).then(data => {
                return data;
            }).then(d => {
                return this.http.get(endPoint + '/users/' + d.id, {headers: this.headers}).toPromise();
            }).then((user: User) => {
                console.log('getLoggedUserFromDb');
                user['token'] = accessToken;
                localStorage.setItem('user', JSON.stringify(user));
                this.config.getConfig(user.id_clinic);
                ok(user);
            });

        });
    }

    /**
     * Get access token
     * @description Should return access token in Observable from e.g. localStorage
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable<string> {
        return this.tokenStorage.getAccessToken();
    }

    /**
     * Verify that outgoing request is refresh-token,
     * so interceptor won't intercept this request
     * @param {string} url
     * @returns {boolean}
     */
    public verifyTokenRequest(url: string): boolean {
        return false;
    }

    /**
     * Submit login request
     * @param {Credential} credential
     * @returns {Observable<any>}
     */
    public login(credential: Credential): Observable<any> {
        return this.http.post<AccessData>(endPoint + this.URL_LOGIN, credential).pipe(
            map((result: any) => {
                this.sessionTimeOut.startTimer();
                if (result instanceof Array) {
                    return result.pop();
                }
                console.log('authentication.service login()', result);
                return result;
            }),

            tap(this.saveAccessData.bind(this)),
            tap(this.getLoggedUser.bind(this)),
        );
    }

    /**
     * Logout
     */
    public logout(refresh?: boolean, stopTime: boolean = true): void {
        console.log("-> logout");
        this.tokenStorage.clear();
        if (stopTime) {
            this.sessionTimeOut.stopTimer();
        }
        if (refresh) {
            location.reload(true);
        }
    }

    /**
     * Save access data in the storage
     * @private
     * @param {AccessData} data
     */
    private saveAccessData(accessData: AccessData) {
        console.log('saveAccessData', accessData);
        if (typeof accessData !== 'undefined') {
            this.tokenStorage.setAccessToken(accessData);
            this.onCredentialUpdated$.next(accessData);
        }
    }

    /**
     * Function, that should perform refresh token verifyTokenRequest
     * @description Should be successfully completed so interceptor
     * can execute pending requests or retry original one
     * @returns {Observable<any>}
     */
    public refreshToken(): Observable<any> {
        return this.tokenStorage.getRefreshToken().pipe();
    }

    /**
     * Function, checks response of failed request to determine,
     * whether token be refreshed or not.
     * @description Essentialy checks status
     * @param {Response} response
     * @returns {boolean}
     */
    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        return response.status === 401;
    }


}
