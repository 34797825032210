import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListTimelineComponent} from './list-timeline.component';
import {TimelineItemComponent} from './timeline-item/timeline-item.component';
import {CoreModule} from '../../../../../core/core.module';
import {MatIconModule} from '@angular/material/icon';
import {MaterialModule} from '../../../../pages/material.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    imports: [CommonModule, CoreModule, MatIconModule, MaterialModule, TranslateModule],
    declarations: [ListTimelineComponent, TimelineItemComponent],
    exports: [ListTimelineComponent, TimelineItemComponent]
})
export class ListTimelineModule {
}
