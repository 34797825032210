import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {QuickSidebarComponent} from './layout/quick-sidebar/quick-sidebar.component';
import {ScrollTopComponent} from './layout/scroll-top/scroll-top.component';
import {TooltipsComponent} from './layout/tooltips/tooltips.component';
import {ListSettingsComponent} from './layout/quick-sidebar/list-settings/list-settings.component';
import {MessengerModule} from './layout/quick-sidebar/messenger/messenger.module';
import {CoreModule} from '../../core/core.module';
import {ListTimelineModule} from './layout/quick-sidebar/list-timeline/list-timeline.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NoticeComponent} from './content/general/notice/notice.component';
import {PortletModule} from './content/general/portlet/portlet.module';
import {SpinnerButtonModule} from './content/general/spinner-button/spinner-button.module';
import {BlogComponent} from './content/widgets/general/blog/blog.component';
import {FinanceStatsComponent} from './content/widgets/general/finance-stats/finance-stats.component';
import {PackagesComponent} from './content/widgets/general/packages/packages.component';
import {TasksComponent} from './content/widgets/general/tasks/tasks.component';
import {SupportTicketsComponent} from './content/widgets/general/support-tickets/support-tickets.component';
import {RecentActivitiesComponent} from './content/widgets/general/recent-activities/recent-activities.component';
import {RecentNotificationsComponent} from './content/widgets/general/recent-notifications/recent-notifications.component';
import {AuditLogComponent} from './content/widgets/general/audit-log/audit-log.component';
import {BestSellerComponent} from './content/widgets/general/best-seller/best-seller.component';
import {AuthorProfitComponent} from './content/widgets/general/author-profit/author-profit.component';
import {DataTableComponent} from './content/widgets/general/data-table/data-table.component';
import {WidgetChartsModule} from './content/widgets/charts/widget-charts.module';
import {StatComponent} from './content/widgets/general/stat/stat.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LayoutModule} from '@angular/cdk/layout';
import {jqxLoaderModule} from 'jqwidgets-ng/jqxloader';


import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AuthenticationService} from '../../core/auth/authentication.service';
import {EstablishmentService} from '../pages/components/establishment/establishment.service';
import {InfoComponent} from './goltratec/info/info.component';
import {CountrycityComponent} from './goltratec/countrycity/countrycity.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {GoltableComponent} from './goltratec/goltable/goltable.component';
import {DialogComponent} from './goltratec/dialog/dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatDatepickerModule} from '@angular/material/datepicker';
import {VirtualAssistantComponent} from './goltratec/virtual-assistant/virtual-assistant.component';

import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {FormlyFieldFile} from './goltratec/formly-components/file/file-type.component';
import {FileValueAccessor} from './goltratec/formly-components/file/file-value-accessor';
import { NotincludedComponent } from './goltratec/notincluded/notincluded.component';
import { GolfilterComponent } from './goltratec/golfilter/golfilter.component';
import {TimelineItemComponent} from './layout/quick-sidebar/list-timeline/timeline-item/timeline-item.component';


@NgModule({
    declarations: [
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        ListSettingsComponent,
        NoticeComponent,
        BlogComponent,
        FinanceStatsComponent,
        PackagesComponent,
        TasksComponent,
        SupportTicketsComponent,
        RecentActivitiesComponent,
        RecentNotificationsComponent,
        AuditLogComponent,
        BestSellerComponent,
        AuthorProfitComponent,
        DataTableComponent,
        StatComponent,
        InfoComponent,
        CountrycityComponent,
        GoltableComponent,
        DialogComponent,
        VirtualAssistantComponent, FileValueAccessor, FormlyFieldFile, NotincludedComponent, GolfilterComponent
    ],
    providers: [AuthenticationService, EstablishmentService],
    exports: [
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        ListSettingsComponent,
        NoticeComponent,
        BlogComponent,
        FinanceStatsComponent,
        PackagesComponent,
        TasksComponent,
        SupportTicketsComponent,
        RecentActivitiesComponent,
        RecentNotificationsComponent,
        AuditLogComponent,
        BestSellerComponent,
        AuthorProfitComponent,
        DataTableComponent,
        StatComponent,
        PortletModule,
        SpinnerButtonModule,
        InfoComponent,
        CountrycityComponent,
        GoltableComponent, DialogComponent, VirtualAssistantComponent, FormlyModule, NotincludedComponent, GolfilterComponent,
        TimelineItemComponent, MatDatepickerModule
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        PerfectScrollbarModule,
        MessengerModule,
        ListTimelineModule,
        CoreModule,
        PortletModule,
        SpinnerButtonModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule, MatDialogModule,
        MatGridListModule, MatCardModule, MatAutocompleteModule,
        WidgetChartsModule,
        ReactiveFormsModule, MatInputModule, TranslateModule.forChild(), LayoutModule, jqxLoaderModule,
        FormlyModule.forRoot({
            validationMessages: [{
                name: 'required', message: 'Este campo es requerido',

            }, {name: 'minlength', message: 'Mínimo de caracteres no alcanzado'}],
            extras: {lazyRender: true},
            types: [
                {name: 'file', component: FormlyFieldFile, wrappers: ['form-field']},
            ],
        }),
        FormlyMaterialModule, FormsModule
    ]
})
export class PartialsModule {
}
