import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as objectPath from 'object-path';
import { BehaviorSubject } from 'rxjs';

import { defaultLang, endPoint } from '../../../../environments/environment';
import { AuthenticationService } from '../../auth/authentication.service';
import { TokenStorage } from '../../auth/token-storage.service';
import { ClassInitService } from '../class-init.service';
import { LayoutConfigService } from '../layout-config.service';
import { MenuConfigService } from '../menu-config.service';

@Injectable()
export class MenuAsideService {
    URL_OPTIONS = '/permissions/modules';
    classes: string;
    menuList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    moduleMenu = null;

    isDropdown: number = 0;
    dropdownTimeout: number;
    isScrollable: number = 0;

    constructor(
        private menuConfigService: MenuConfigService,
        private classInitService: ClassInitService,
        private layoutConfigService: LayoutConfigService,
        private http: HttpClient,
        private tokenStorage: TokenStorage,
        private authServ: AuthenticationService,
    ) {
        // get menu list
        // this.menuConfigService.onMenuUpdated$.subscribe(model => {
        // 	debugger;
        // 	setTimeout(() => {
        // 		debugger;
        //  		this.menuList$.next(objectPath.get(model.config, 'aside.items'));
        // 	debugger;
        // 	console.log('entre');
        // });
        // });
        let accesToken: string = null;
        this.tokenStorage.getAccessToken().subscribe(token => {
            accesToken = token;
        });

        let userLang = navigator.language;
        const header = new HttpHeaders({
            Authorization: accesToken,
            'Accept-Language': (userLang && typeof userLang == 'string' && userLang.substr(0, 2).length == 2) ? userLang.substr(0, 2) : defaultLang,
        });

        this.http
            .get<any[]>(endPoint + this.URL_OPTIONS, { headers: header })
            .subscribe(
                response => {
                    console.log('menulist',response);
                    this.menuList$.next(response);
                },
                error => {
                    if (
                        (error.status === 403 &&
                            error.statusText === 'Forbidden') ||
                        (error.status === 401 &&
                            error.statusText === 'Unauthorized')
                    ) {
                        authServ.logout(true);
                    }
                    this.menuList$.next([]);
                },
            );

        this.layoutConfigService.onLayoutConfigUpdated$.subscribe(config => {
            if (objectPath.get(config, 'config.aside.left.fixed')) {
                this.isScrollable = 1;
                this.isDropdown = 0;
            }
            if (
                !objectPath.get(config, 'config.aside.left.fixed') &&
                !objectPath.get(
                    config,
                    'config.menu.aside.desktop_and_mobile.submenu.accordion',
                )
            ) {
                this.isScrollable = 0;
                this.isDropdown = 1;
                this.dropdownTimeout = objectPath.get(
                    config,
                    'config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout',
                );
            }
        });
    }
}
