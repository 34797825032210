import { MatPaginatorIntl } from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

export class PaginatorI18n {

	constructor(private readonly translate: TranslateService) {
	}

	getPaginatorIntl(): MatPaginatorIntl {
		const paginatorIntl = new MatPaginatorIntl();
		paginatorIntl.itemsPerPageLabel = this.translate.instant('TABLE.PAGINATE.ITEMS_PER_PAGE_LABEL');
		paginatorIntl.nextPageLabel = this.translate.instant('TABLE.PAGINATE.NEXT_PAGE_LABEL');
		paginatorIntl.previousPageLabel = this.translate.instant('TABLE.PAGINATE.PREVIOUS_PAGE_LABEL');
		paginatorIntl.firstPageLabel = this.translate.instant('TABLE.PAGINATE.FIRST_PAGE_LABEL');
		paginatorIntl.lastPageLabel = this.translate.instant('TABLE.PAGINATE.LAST_PAGE_LABEL');
		paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
		return paginatorIntl;
	}

	private getRangeLabel(pageValue: number, pageSizeValue: number, lengthValue: number): string {
		if (lengthValue === 0 || pageSizeValue === 0) {
			return this.translate.instant('TABLE.PAGINATE.RANGE_PAGE_LABEL_1', {length: lengthValue});
		}
		lengthValue = Math.max(lengthValue, 0);
		const startIndexValue = pageValue * pageSizeValue;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndexValue = startIndexValue < lengthValue ? Math.min(startIndexValue + pageSizeValue, lengthValue) : startIndexValue + pageSizeValue;
		return this.translate.instant('TABLE.PAGINATE.RANGE_PAGE_LABEL_2', {
			startIndex: startIndexValue + 1,
			endIndex: endIndexValue,
			length: lengthValue
		});
	}
}
