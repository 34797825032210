import {  Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizewindowService {
  // @HostListener('window:resize', ['$event'])
  // onresize(event) {
  //   console.log('onResize');
  //   this.actualizaWidth(event.target.innerWidth);
  // }
  private subject = new Subject<number>();

  constructor() {
    window.addEventListener('resize', (e:any) => {
      // console.log('event',e.target.innerWidth);
      this.subject.next(e.target.innerWidth);
    });
   }

  get $resize():Observable<number>{
    return this.subject.asObservable();
  }

  get width():number{
    return window.innerWidth;
  }
}
