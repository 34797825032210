<mat-card>
    <mat-card-title>
        Funcionalidad no disponible.
    </mat-card-title>
    <mat-card-content>
        <div class="text-center">
            <img class="align-" src="/assets/inceme/img/avatar-notincluded.png">
            <p>Esta funcionalidad no está incluida en tu suscripción. Si lo deseas puedes consultar las suscripciones
                disponibles.</p>
            <a mat-button mat-raised-button color="primary" routerLink="/subscription">Ver suscripciones</a>
        </div>
    </mat-card-content>
</mat-card>



