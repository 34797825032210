import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Clinic} from '../../../../core/interfaces/clinic';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {endPoint} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {ConfigClinicStateService} from '../../../../core/services/config-clinic-state.service';

@Injectable({
    providedIn: 'root'
})
export class ClinicService {
    private headers: HttpHeaders;
    private URL_CLINIC = '/clinics';

    constructor(private http: HttpClient, private config: ConfigClinicStateService) {
        this.headers = new HttpHeaders({'Authorization': localStorage.getItem('accessToken')});
    }


    form: FormGroup = new FormGroup({
        id: new FormControl(null),
        name: new FormControl('', Validators.required),
        address: new FormControl(null),
        postcode: new FormControl(null),
        nit: new FormControl(null),
        url_web: new FormControl(null),
        cod_country: new FormControl(null),
        cod_province: new FormControl(null),
        cod_locality: new FormControl(null),
        phones: new FormControl(null),
        mobiles: new FormControl(null),
        fax: new FormControl(null),
        email: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')),
        notes: new FormControl(null),
        config_duration_appointment: new FormControl(null, {validators: [Validators.pattern('^[0-9]*')]}),
        config_start_appointment_hour: new FormControl(null, {validators: [Validators.pattern('^[0-9]*')]}),
        config_end_appointment_hour: new FormControl(null, {validators: [Validators.pattern('^[0-9]*')]}),
        config_default_patient: new FormControl(null),
        config_email_message_remainder: new FormControl(null)
        // status: new FormControl(null),
        // created_at: new FormControl(null),
        // updated_at: new FormControl(null),
        // logo: new FormControl(null)
    });

    getClinic(): Observable<Clinic> {
        return this.getClinics().pipe(map(response => {
            response.active_subscription = JSON.parse(response.active_subscription);
            const config = JSON.parse(response.config);
            if (config) {
                response.config_duration_appointment = config.duration_appointment;
                response.config_start_appointment_hour = config.start_appointment_hour;
                response.config_end_appointment_hour = config.end_appointment_hour;
                response.config_default_patient = config.default_patient;
                response.config_email_message_remainder = config.email_message_remainder;
            }
            return response;
        }));
    }

    getClinics(): Observable<Clinic> {
        return this.http.get<Clinic>(endPoint + this.URL_CLINIC, {headers: this.headers});
    }

    requiredFileType(type: string) {
        return (control: FormControl) => {
            const file = control.value;
            if (file) {
                const extension = file.name.split('.')[1].toLowerCase();
                if (type.toLowerCase() !== extension.toLowerCase()) {
                    return {
                        requiredFileType: true
                    };
                }
                return null;
            }
            return null;
        };
    }

    deleteClinic(id): Observable<any> {
        return this.http.get(endPoint + this.URL_CLINIC + '/remove-clinic/' + id, {headers: this.headers});
    }

    // insertClinic(clinic) {
    // 	return this.http.post(endPoint + this.URL_CLINIC, clinic, {headers: this.headers});
    // }

    updateClinic(id, data) {
        // console.log('clinic.service updateClinic',data);
        const fData = this.parseConfigData(data);
        return this.http.put(endPoint + this.URL_CLINIC + '/' + id, fData, {headers: this.headers}).pipe(map(async res => {
            await this.config.getConfig(data.id);
            return res;
        }));
    }

    private parseConfigData(data) {
        const config = {};
        Object.entries(data).forEach(obj => {
            if (obj[0].includes('config_')) {
                const name = obj[0].split('config_')[1];
                config[name] = obj[1];
            }
        });
        data.config = JSON.stringify(config);
        return data;
    }

    updateLogo(id, data) {
        return this.http.post(endPoint + this.URL_CLINIC + '/update-logo/' + id, data, {
            headers: this.headers
        });
    }

    activeSubscription(): Promise<any> {
        return this.getClinic().pipe(map(r => r.active_subscription)).toPromise();
    }

    checkPermissionSubscription(titlePermission: string): Promise<any> {
        return this.http.get(endPoint + this.URL_CLINIC + '/check-permission/' + titlePermission, {headers: this.headers}).toPromise();
    }
}
