import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomEmailRemainderComponent} from './content/pages/components/custom-email-remainder/custom-email-remainder.component';

const routes: Routes = [
    {
        path: '',
        /*loadChildren: './content/pages/pages.module#PagesModule'*/
        loadChildren: async () => await import('app/content/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'test_hmr', component: CustomEmailRemainderComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
