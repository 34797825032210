import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {endPoint, defaultLang} from '../../../../../environments/environment';
import {Codifier} from '../../../../core/interfaces/codifier';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CodifierService {
    private readonly headers: HttpHeaders;
    private URL_SPECIALTIES = '/codifiers/specialties';
    private URL_COUNTRIES = '/codifiers/countries';
    private URL_PROVINCES = '/codifiers/provinces?country=';
    private URL_LOCALTIES = '/codifiers/localities?province=';
    private URL_PERSONAL_TYPES = '/codifiers/personal-types';
    private URL_IDENTIFICATIONS = '/codifiers/identifications';
    private URL_PAYMENTS = '/codifiers/payments';
    private URL_PERMISSIONS = '/codifiers/permissions';
    private URL_FAMILY_CONCEPTS = '/codifiers/family-concepts';
    private URL_APPOINTMENT_TYPES = '/codifiers/appointment-types';
    private URL_FAMILY_CATEGORIES = '/codifiers/family-categories';
    private URL_GENRE = '/codifiers/genres';
    private URL_MUTUAL_TYPE = '/codifiers/mutual-types';
    private URL_TYPES = '/codifiers/types';
    private URL_CODIFIER_TYPE = '/codifiers/type/';
    private URL_DELETE_CODIFIER_CODE = '/codifiers/delete-encoder';
    private URL_ADD_CODIFIER = '/codifiers/add';
    private URL_EDIT_CODIFIER = '/codifiers/update-encoder';
    private URL_ALARMS_CODIFIER = '/codifiers/alarmlist';
    private URL_INVOICE_TYPES = '/codifiers/invoice-types';

    countries$: Observable<Codifier[]>;

    constructor(private http: HttpClient) {
        let userLang = navigator.language;
        this.headers = new HttpHeaders({
            Authorization: localStorage.getItem('accessToken'),
            'Accept-Language': (userLang && typeof userLang == 'string' && userLang.substr(0, 2).length == 2) ? userLang.substr(0, 2) : defaultLang,
        });

        this.countries$ = this._getCountries();
    }

    getToken(): HttpHeaders {
        return new HttpHeaders({
            Authorization: localStorage.getItem('accessToken'),
            'Accept-Language': 'es',
        });
    }

    _getCountries(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_COUNTRIES).pipe(shareReplay(1));
    }

    getCountries(): Observable<Codifier[]> {
        return this.countries$;
    }

    getProvinces(country): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(
            endPoint + this.URL_PROVINCES + country,
            // {headers: this.headers},
        );
    }

    getLocalties(province): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(
            endPoint + this.URL_LOCALTIES + province,
            // {headers: this.headers},
        );
    }

    getSpecialties(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_SPECIALTIES, {
            headers: this.getToken(),
        });
    }

    getPersonalTypes(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_PERSONAL_TYPES, {
            headers: this.getToken(),
        });
    }

    getIdentifications(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_IDENTIFICATIONS, {
            headers: this.getToken(),
        });
    }

    getPayments(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_PAYMENTS, {
            headers: this.getToken(),
        });
    }

    getPermissions(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_PERMISSIONS, {
            headers: this.getToken(),
        });
    }

    getFamilyConcepts(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_FAMILY_CONCEPTS, {
            headers: this.getToken(),
        });
    }

    getAppointmetTypes(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(
            endPoint + this.URL_APPOINTMENT_TYPES,
            {
                headers: this.getToken(),
            },
        );
    }

    getFamilyCategories(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(
            endPoint + this.URL_FAMILY_CATEGORIES,
            {
                headers: this.getToken(),
            },
        );
    }

    getGenres(): Observable<Codifier[]> {
        console.log('codifierService getGenres', this.getToken());
        return this.http.get<Codifier[]>(endPoint + this.URL_GENRE, {
            headers: this.getToken(),
        });
    }

    getMutualTypes(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_MUTUAL_TYPE, {
            headers: this.getToken(),
        });
    }

    getTypesCodifiers(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_TYPES, {
            headers: this.getToken(),
        });
    }

    getCodifierByType(typeCode: string): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(
            endPoint + this.URL_CODIFIER_TYPE + typeCode,
            {
                headers: this.getToken(),
            },
        );
    }

    deleteCodifierByCode(type: string, code: string, parent: string = '-1'): Observable<any> {
        return this.http.delete(`${endPoint}${this.URL_DELETE_CODIFIER_CODE}/${type}/${code}/${parent}`, {
            headers: this.getToken(),
        });
    }

    addCodifier(type: string, name: string, description: string): Observable<Codifier> {
        return this.http.post<Codifier>(
            endPoint + this.URL_ADD_CODIFIER,
            {type: type, code: null, name: name, description: description},
            {headers: this.getToken()},
        );
    }

    editCodifier(type: string, code: string, name: string, description: string, parent: string = '-1'): Observable<Codifier> {
        return this.http.put<Codifier>(
            `${endPoint}${this.URL_EDIT_CODIFIER}/${type}/${code}/${parent}`,
            {name, description},
            {headers: this.getToken()},
        );
    }

    getAlarmList(): Observable<Array<Codifier>> {
        return this.http.get<Codifier[]>(endPoint + this.URL_ALARMS_CODIFIER, {
            headers: this.getToken()
        });

    }

    getInvoiceTypes(): Observable<Codifier[]> {
        return this.http.get<Codifier[]>(endPoint + this.URL_INVOICE_TYPES, {
            headers: this.getToken()
        });
    }
}
