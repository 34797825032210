/** DataAdapter para matdate. En principio se usa solo para setear le primer dia de la semana a lunes en lugar de domingo que es como
 * viene por defecto
 */
import {MatSnackBarModule} from '@angular/material/snack-bar';

export class CustomDateAdapter extends NativeDateAdapter {

    getFirstDayOfWeek(): number {
        // We can't tell using native JS Date what the first day of the week is, we default to Sunday.
        return 1;
    }
}
/** Fin Data adapter **/


import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgModule} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginator, MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {PaginatorI18n} from '../../core/util/PaginatorI18n';
import {RoomService} from './components/room/room.service';

@NgModule({
    imports: [
        MatIconModule,
        MatCardModule,
        MatDividerModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatTableModule,
        NgbDropdownModule,
        NgbTooltipModule,
        MatSelectModule,
        MatDialogModule,
        MatCheckboxModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatTreeModule,
        MatBadgeModule,
        MatGridListModule,
        MatRadioModule,
        MatTooltipModule,
        MatSnackBarModule
    ],
    exports: [
        MatIconModule,
        MatCardModule,
        MatDividerModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatTableModule,
        NgbDropdownModule,
        NgbTooltipModule,
        MatSelectModule,
        MatDialogModule,
        MatCheckboxModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatTreeModule,
        MatBadgeModule,
        MatGridListModule,
        MatRadioModule,
        MatTooltipModule
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) =>
                new PaginatorI18n(translateService).getPaginatorIntl(),
        },
        {provide: MAT_DIALOG_DATA, useValue: {}},
        {provide: MatDialogRef, useValue: {}},
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
        {provide: DateAdapter, useClass: CustomDateAdapter},
        RoomService,
    ]
})
export class MaterialModule {
}

