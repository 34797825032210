import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'm-subscription-button-info',
  templateUrl: './subscription-button-info.component.html',
  styleUrls: ['./subscription-button-info.component.scss']
})
export class SubscriptionButtonInfoComponent implements OnInit {
  @Input() iconOnly: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
