import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {endPoint} from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ConfigClinicStateService {
    private config: any = {};
    private headers;

    private URL_CLINIC = endPoint + '/clinics';

    constructor(private http: HttpClient) {

    }

    getDurationAppointment(): number {
        return this.config.duration_appointment;
    }

    getStartAppointmentHour(): number {
        return this.config.start_appointment_hour;
    }

    getEndAppointmentHour(): number {
        return this.config.end_appointment_hour;
    }

    getDefaultIdPatient(): string {
        return this.config.default_patient;
    }

    getEmailMessageRemainder(): string {
        return this.config.email_message_remainder;
    }

    async getConfig(id_clinic) {
        this.headers = new HttpHeaders({
            Authorization: localStorage.getItem('accessToken'),
            'Accept-Language': 'es',
        });
        const _config = await this.http.get(this.URL_CLINIC + '/get-config/' + id_clinic, {headers: this.headers}).toPromise();
        this.config = {..._config};
        console.log('getConfig-> config', this.config);
    }
}
