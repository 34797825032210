<div class="m-widget14">
	<div class="m-widget14__header">
		<h3 class="m-widget14__title">
			Profit Share
		</h3>
		<span class="m-widget14__desc">
		Profit Share between customers
		</span>
	</div>
	<canvas baseChart
			[datasets]="barChartData"
			[labels]="barChartLabels"
			[options]="barChartOptions"
			[legend]="barChartLegend"
			[chartType]="barChartType"
			(chartHover)="chartHovered($event)"
			(chartClick)="chartClicked($event)"></canvas>
</div>