import {ErrorHandler, Injectable} from '@angular/core';
import LogRocket from 'logrocket';

@Injectable()
export class LogRocketErrorHandler implements ErrorHandler {
    handleError(error: any) {
        // Add the error message to the telemetry timeline.
        // It can occasionally have useful additional context.
        // console.warn(error.message);
        LogRocket.captureException(error.originalError || error);
    }
}
