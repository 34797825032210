<!-- begin::Quick Nav -->
<li class="m-nav-sticky__item" matTooltip="Layout Builder" matTooltipPosition="left">
	<a href="javascript:;" routerLink="/builder">
		<i class="la la-cog"></i>
	</a>
</li>
<li class="m-nav-sticky__item" matTooltip="Purchase" matTooltipPosition="left">
	<a href="https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes" target="_blank">
		<i class="la la-cart-arrow-down"></i>
	</a>
</li>
<li class="m-nav-sticky__item" matTooltip="Documentation" matTooltipPosition="left">
	<a href="https://keenthemes.com/metronic/documentation.html" target="_blank">
		<i class="la la-code-fork"></i>
	</a>
</li>
<li class="m-nav-sticky__item" matTooltip="Support" matTooltipPosition="left">
	<a href="https://keenthemes.com/forums/forum/support/metronic5/" target="_blank">
		<i class="la la-life-ring"></i>
	</a>
</li>
<!-- begin::Quick Nav -->
