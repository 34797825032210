// Spain
export const locale = {
    lang: 'en',
    data: {
        TRANSLATOR: {SELECT: 'Choose your language'},
        HORA: 'Time',
        PACIENTE: 'Patient',
        PACIENTES: 'Patients',
        INVOICES: 'Invoices',
        SALA: 'Room',
        MODIFICAR: 'Modify',
        MODIFYANDEXIT: 'Save and exit',
        CANCELAR: 'Cancel',
        BORRAR: 'Delete',
        ERRORGUARDANDO: 'Error while saving',
        ERROROBTENERDATOS: 'Error while obtaining data',
        BUSCARALARMA: 'Search alarm',
        SEARCH: 'Search',
        PATIENTFULLNAME: 'Name of the patient',
        TITLE: 'Title',
        VALUE: 'Value',
        NOTAS: 'Notes',
        GUARDAR: 'Save',
        NEW: 'New',
        NEWM: 'New',
        MEDICO: 'Doctor',
        FECHA: 'Date',
        TIPSHOWALLFILTER: 'Use * to show everything',
        SELECT: 'Select',
        DRUGS: 'Drugs',
        PRINT: 'Print',
        INSERT: 'Insert',
        LOADING: 'Loading',
        EXPORTANDO: 'Obtaining data for the exportation',
        UDS: 'Units',
        AMOUNT: 'Amount',
        COD: 'Cod',
        DTO: 'Discount Imp',
        DTOPORC: 'Discount %',
        DESCRIPTION: 'Desc',
        TOTAL: 'Total',
        PHONE: 'Phone',
        MOBILE: 'Mobile',
        SIGNUP: 'Dar de alta',
        SIGNINGUP: 'Dando de alta el usuario',
        SIGNUPOK: 'Alta correcta',
        SIGNUPKO: 'Error al dar de alta',
        ERRORPASSWORDNOTMATCH: 'The passwords do not match',
        ERRORMINLENGTH: 'It must have at least {{num}} characters',
        INITDATE: 'Date of beggining',
        INITHOUR: 'Hour of beggining',
        ENDDATE: 'Date of end',
        ENDHOUR: 'Hour of end',
        PRINTCALENDAR: 'Print calendar',
        SELECTPERSONALCLINICAL: 'Select medical personnel',
        FIELDMANDATORY: '{{nameField}} is required',
        LOGOUT: 'Log out',
        SAVEOK: 'Saved correctly',
        SAVEERROR: 'Error while saving',
        SELECTLOGO: 'Select logo',
        CLOSE: 'Close',
        SCHEDULEREPORTVOID: 'The report couldn\'t be generated because there are no appointments on the chosen interval / professional',
        NOAUTH: 'You do not have permission to access this functionality, check with your administrator.',
        APPOINTMENT: 'Appointment',
        IMAGES: 'Images',
        IMAGEGALLERY: 'Image gallery',
        IMAGETOOBIG: 'The image is too big, the maximum size is {{size}}',
        SELECTINGIMAGE: 'Selecting image',
        SAVING: 'Saving',
        CLINIC: 'Clinic',
        RECOVERYPASS: 'Recover password',
        ADDDIAGNOSTIC: 'Add diagnostic',
        ADDSELECTEDTREATMENT: 'Add chosen treatment',
        EXPAND: 'Expand',
        CONTRACT: 'Contract',
        ACCEPT: 'Accept',
        FILTERBYDESCRIPTION: 'Filter by description',
        FILTERBYCODE: 'Filter by code',
        BILLTYPE: 'Type of bill',
        NOLINESINVOICE: 'You don\'t have lines associated to this invoice',
        CREATINGINVOICE: 'Creating invoice',
        IVATYPE: 'Type of IVA',
        ADDIVA: 'Add IVA',
        CANCELANDRESETFORM: 'Cancel and reset form',
        CODEEXISTS: 'The code already exists',
        NCONTRACT: 'Nº  of Contract',
        DOCTORNAME: 'Personal Clinic',
        CONCEPT: 'Concept',
        GOTOUNSUBSCRIBE: 'You are going to cancel {{text}} subscription',
        GOTOREACTIVESUBSCRIBE: 'You are going to reactive {{text}} subscription',
        CANTSUBSCRIBECAUSEINTERVAL: 'Can\'t subscribe to this plan. Contact with INCEME team by info@inceme.com or phone 966787629',
        NOCHANGES: 'No changes have made',
        ACTIVE: 'Active',
        NOACTIVE: 'No active',
        year: 'year',
        month: 'month',
        WILLCANCELAT: 'Will be cancel at ',
        WILLRENEWAT: 'Will be renew at ',
        REMOVESUBSCRIPTION: 'Cancel Subscription',
        REACTIVESUBSCRIPTION: 'Re-active subscription',
        CARDEXPIRATIONDATE: 'Expiration date',
        CHANGECARD: 'Change creditcard',
        ADDCARD: 'Add creditcard',
        NOACTIVESUBSCRIPTION: 'No active subscription',
        FREESUBSCRIPTIONTEXT: 'Free subscription',
        OTHERPLANS: 'Other available subscriptions',
        USERS: 'Users',
        CALENDARS: 'Calendars',
        ESTABLISHMENTS: 'Establishments',
        UNLIMITED: 'unlimited',
        SUBSCRIBETOIT: 'Subscribe',
        YES: 'yes',
        MODIFYSUBSCRIPTION: 'Modify subscription',
        SUBSCRIPTIONDETAILS: 'Subscription details',
        User: 'Normal user',
        Administrator: 'Administrator',
        BACKUP: 'Backup',
        INCLUDEINYOURPLAN: 'Your plan include',
        MOREINFOPLAN: 'My plan (+ info)',
        REQUIRECOMPLETEPAYMENTMETHOD: 'Before subscribing you need to fill in the payment method',
        SUBSCRIBEPLAN: 'Hire a plan',
        CREDITCARD: 'Credit Card',
        UNIQUECANTDELETE: 'This is the last record. At least one record is required and for that reason it is not possible to delete',
        SELECTEDPATIENT: 'Selected patient',
        ALL: 'All',
        SEND: 'Send',
        INVOICEPENDING: 'Pending invoicing',
        BILLING: 'Billing',
        REMOVECLINIC: 'REMOVE ALL CLINIC DATA',
        CONFIRMDELETE: 'I have read and agree to erase all data from my clinic.',
        LASTCHANCE: 'This is the last chance to prevent all your information from being erased. Are you sure you want to continue?',
        DELETINGDATA: 'ERASING THE DATA',
        DELETEDREDIRECT: 'CLINIC DELETED .. REDIRECTING TO THE HOME SCREEN',
        ANTECEDENTTOOLTIP: 'Press modify to start writing',
        AGEVALIDATOR: 'The date of birth cannot be later than the current one',
        VALIDATECHARGE: 'Your bank requires you to validate your subscription',
        VALIDATE: 'Validate',
        NOTVALIDUSER: 'Invalid user. <a href="https://goltratec.com"> Contact Technical Support</a>',
        PAID: 'Paid',
        CIF: 'VAT',
        SETCOOKIES: 'Set cookies',
        CHANGESNOSAVED: 'There are unsaved changes. If you continue, they will be lost. Do you want to continue? ',
        DELETESTROKE: 'Delete stroke',
        UNDO: 'Undo',
        EDITIMAGE: 'Image editing',
        THICKNESS: 'Thickness',
        NEWPHOTO: 'New Photo',
        INACTIVE: 'Inactive',
        NUMSESSIONS: 'Num sessions',
        EXPIREDATE: 'Expiration',
        USEDSESSIONS: 'Used sessions',
        SELECTAPATIENT: 'You have to select a patient',
        AREYOUSUREDELETE: 'You are about to delete an item. This cannot be undone, are you sure you want to continue?',
        BONOACTIVE: 'Active bono',
        BONOEXPIRED: 'Expired bono',
        BONOOWNER: 'Bonus owner',
        EMAILNOTVALID: 'This is not a valid email address',
        RESOLUTIONNOTVALID: 'The resolution is too small. You may not see all the options well. We recommend a minimum of 960px',
        FIRSTAPPOINTMENT: 'First appointment',
        SECONDAPPOINTMENT: 'Second appointment',
        THIRDAPPOINTMENT: 'Third appointment',
        PRIVACYPOLICYACCEPT: 'I have read and accept the privacy policy',
        INFOSIGNUP: 'To create your <b> free account in the clinic management program </b> inceme we need certain information.',
        CONTACTMEBYEMAILON: 'If you have any questions remember that you can contact us at',
        ACCESSDATA: 'Access data',
        INFOACCESSDATA: 'These data will allow you <strong> access to the program </strong> Management of clinics',
        INCEMETITLE: 'Inceme Management of clinics and medical centers',
        COMPANYDATA: 'Clinic data',
        CREATEFREEACCOUNT: 'Create a free account',
        COMPANYDATAINFO: 'Your company data for when you create <strong> appointments and invoices to your patients </strong>',
        LEGALNOTICE: 'Legal notice',
        YOUSHOULDACCEPTPOLICY: 'You must accept the privacy policy',
        PATIENTNAME: 'Patient name',
        INFOHOWCREATENEWBONO: 'Press "new" to create a voucher or edit an existing voucher',
        CREATINGNEWBONO: 'Creating new bonus',
        EDITINGBONO: 'Editing bonus',
        UNIQUENUMBERERROR: 'This code is already in use.',
        FILTERS: 'Filters',
        AGREEMODELS: 'Consent models',
        DELETEDOK: 'Deleted successfully',
        DELETEDKO: 'Error deleting',
        DOCUMENTSENDMARKOK: 'Document marked as sent',
        DOCUMENTSENDMARKKO: 'The document could not be marked as sent',
        GENERATINGLINK: 'Generating link',
        PATIENTAGREEMENTSENDMESSAGE: 'We are sending you the following document for your signature. Click on the link and follow the instructions',
        EMAILCONFIGINCOMPLET: 'Invalid data to send email',
        MOBILECONFIGINCOMPLET: 'Invalid data to send to the phone',
        FILETOSIGN: 'File to sign',
        SENDOK: 'Successful delivery',
        DELETEDPATIENTBENEFICIARY: 'Deleted Beneficiary Patient',
        ADDPATIENTBENEFICIARY: 'Patient Beneficiary added',
        PACIENTEBENEFICIARIOBONO: 'Beneficiaries',
        SENDKO: 'There was an error with the shipment',
        AGREEMODELSFIELDS: {
            TITLE: 'Title',
            COMMENTS: 'Notes',
            CONTENT: 'Content'
        },
        PATIENTAGREEMODEL: 'Agreements and consents',
        SENDTOSIGN: 'Send to sign',
        COMUNICACIONES: 'Communications',
        DATESERROR: 'The dates are not correct',
        SELECTEDPERSONALCLINICA: 'Persona clinici seleted',
        PERMISSIONS: 'Permissions',
        ROL_OPTION: 'Permission rol',
        CONFIG: 'Configuration',
        REMAINDER24H: 'Remind almost 24h before by email',
        SENDING: 'Sending',
        REMAINDER24HADVISE: 'The automatic reminder can only be activated if there are more than 2 hours left for the appointment',
        PATIENTINFO: {
            LIST: {
                INTRO: 'Looks like you don\'t have any patients yet, let me explain what you can do from here. <br/>' +
                    'You will be able to create, search, modify and delete patients from your clinic. ' +
                    'If you want to continue with the explanation click <strong> next </strong>, if you do not close this window and you will be able to see these explanations again ' +
                    'whenever you want by clicking on the "virtual tour" button at the top of the window.',
                NEWPATIENT: 'By pressing this button you will access a form to create a new patient.',
                FILTER: 'Use these fields to be able to search for patients, you can combine us to get' +
                    'a search that fits what you need.',
                GRID: 'Here you see the list of your patients, in each row you will have a button to modify the patient or delete it'
            }
        },
        CALENDARSINFO: {
            INTRO: 'It seems that you have not seen our tutorial on this section. ' +
                'If you want to see it, click on the NEXT button. If you prefer to do it at another time, close this window and when you want you can click on "Virtual Tour" <br/> ' +
                'From the agendas you will be able to manage the appointments of all the professionals in your clinics as well as' +
                'define what treatments and costs are to be applied to your patient. Press <strong> next to continue </strong> ',
            NEW: 'By pressing this button you will access the form in which to create a new appointment. You can: ' +
                '<ul>' +
                '<li> Define the patient </li>' +
                '<li> Define the start and end time </li>' +
                '<li> Specify the professional and specialty that will treat the patient </li>' +
                '<li> Define the treatments and their cost </li>' +
                '<li> Use a session voucher in case the patient has one </li>' +
                '<li> Define the status (he is in the clinic, has been attended, has been absent) </li>' +
                '<li> Invoice the appointment </li>' +
                '</ul>',
            REMAINDER: 'When you select an appointment on the calendar, you can press this button to send a' +
                'reminder by whatsapp or by email to your patient. Remember that to send reminders by whatsapp ' +
                'You must have it open in the browser (if you connect from a computer) or have the application installed. <br/>' +
                'In case you want to send the reminder by email, you must previously configure the data of the sending server' +
                'from the clinic section.',
            FILTER: 'From here you can filter the citations based on various criteria. The available criteria will depend ' +
                'of the license you have. The possible filters are: ' +
                '<ul>' +
                '<li> Establishments </li>' +
                '<li> Clinical staff </li>' +
                '<li> Rooms </li>' +
                '<li> Mutual </li>' +
                '</ul>',
            SELECTDATE: 'Select which date (day or week) you want to see from your agenda.',
            SCHEDULE: 'You can select to see the agenda in <strong> day </strong> view or <strong> week </strong> view.' +
                'You can interact with the different appointments by clicking on them and selecting one of the options it offers you.' +
                '<br/> You will also have information in the form of icons or colors about its status, if it is invoiced, if it is the first visit ...' +
                'and much more.'
        },
        PERSONALCLINICALINFO: {
            INTRO: 'It seems you haven\'t seen our tutorial on this section. ' +
                'If you want to see it, click on the NEXT button. If you prefer to do it at another time, close this window and when you want you can click on "Virtual Tour" ',
            NEW: 'Pressing this button will open the form that allows you to create a new clinical staff record. ' +
                'Remember that depending on the license you have, you may not be able to have more than one person.',
            LIST: 'The clinical staff that you have discharged from your clinic is listed. From this list you can access each ' +
                'registry to modify and even delete it.'
        },
        APPOINTMENTADDINFO: {
            INTRO: 'It seems that you have not seen our tutorial on this section. ' +
                'If you want to see it, click on the NEXT button. <br/> If you prefer to do it at another time, close this window and when you want you can click on "Virtual Tour" ',
            PATIENT: 'Appointments are linked to a patient. You should always select a patient when creating an appointment. ',
            APPOINTMENTTYPE: 'The system automatically determines if it is the first, second, third or successive appointments. ' +
                'However you can force the value you consider',
            PERSONALCLINIC: 'In this field you define the clinical staff who will attend the patient and therefore in which agenda this appointment should be shown',
            ESPECIALITY: 'This field is auto-filled according to the specialty of the selected clinical staff',
            MUTUA: 'If the patient has a mutual assigned, in this field you can indicate it in case the appointment must be linked to said mutual.',
            ESTABLISHMENT: 'When your clinic has several establishments, from here you can indicate where the consultation will be held. ' +
                'This will allow you to have totally differentiated agendas by establishment.',
            ROOM: 'The consultation may need to be held in a specific room. With this field you can select ' +
                'between the wards that you have registered in your clinic.',
            STATES: 'Through the states you can see if a patient is already in the clinic, if he is being treated,' +
                'if you have finished your consultation or even if you have not kept the appointment.',
            INIT: 'Indicates the estimated start date and time of the appointment',
            END: 'Indicates the estimated end time of the appointment',
            BONUS: 'A patient may have contracted a voucher with sessions. ' +
                'From this drop-down you can select the voucher from which you wish to discount the session corresponding to this appointment.',
            NOTIFICATIONS: 'Appointments can be notified by email or whatsapp. Here you will see how many times the ' +
                'patient this appointment.',
            ASSISTANCE: 'Easily invoice appointments through the billing assistant.',
            SAVE: 'Save the changes made to the appointment',
        },
        VIRTUALTOUR: 'Tour virtual',
        DAY: {M: 'Monday', T: 'Tuesday', W: 'Wednesday', TH: 'Thursday', F: 'Friday', S: 'Saturday', SU: 'Sunday'},
        SELECTEDPERSONALCLINICALNOWORK: 'The selected clinical staff does not pass the:',
        INFO: ' Information',
        NOTIFICATIONTITLEDIALOG: 'Sending by {{sendBy}} to {{to}}',
        SIGNDATE: 'Sign date',
        ADDCLAUSE: 'Add clause',
        ADDCLAUSES: 'Add clauses to the contract',
        ADDCLAUSETOTEXT: 'Add to text',
        FILEPATIENT: 'Files',
        FILE: 'Fichero',
        DOCUMENTS:'Documents',
        SIGNUPFORM: {
            NAME: 'Indicate your name',
            EMAIL: 'Indicate your email',
            PASSWORD: 'Indicate your password',
            REPEATPASSWORD: 'Repeat your password',
            COMPANY: 'Name of the clinic',
            ADDRESS: 'Address',
            POSTCODE: 'Postal Code',
            COUNTRY: 'Country',
            PROVINCE: 'Province',
            LOCALITY: 'Town',
            SIGNUPCONTACTPERSON: 'Contact person'
        },
        PRESCRIPTION: {
            TITLE: 'Prescriptions',
            SINGLETITLE: 'Prescription',
            DRUGNAME: 'Drug',
            UPDATED: 'Updated prescription',
            ERRORUPDATING: 'Error while updating the prescription',
            DELETED: 'Deleted prescription',
            CONFIRMDELETE: 'This action can\'t be undone, do you wish to continue and delete this prescription?',
            INSTRUCTIONS: 'Instructions',
            CONTAINER: 'Container',
            UNITS: 'Units',
            POSOLOGY: 'Dosage',
            PATTERN: 'Pattern',
            ADMINISTRATION_WAY: 'Way of administration',
            ADITIONAL_INFO: 'Additional information',
            PACTIVOS: 'Active ingredients',
            DRUGSEARCH: 'Write the drug you are searching',
            INPUTDRAGNAME: 'Select a drug or write the text that you wish'
        },
        ALARMAS: {
            BUSCARALARMA: 'Search alarm',
            TITLE: 'Medical alarms (allergies and other important alerts)'
        },
        MENU: {
            NEW: 'New',
            ACTIONS: 'Actions',
            CREATE_POST: 'Create new post',
            REPORTS: 'Reports',
            APPS: 'Apps',
            DASHBOARD: 'Dashboard',
            USER: 'User',
        },
        FORMS: {
            CAMPO_REQUERIDO: 'Requested field',
            VALOR_INCORRECTO: 'Incorrect value',
            COD_OR_NAME_PATIENT: 'Code or name (Patient)',
            MUTUA: 'Mutual',
            FECHA_INICIO: 'Start date',
            FECHA_FIN: 'Finish date',
            FECHA_FACTURA: 'Invoice date',
            SERIE: 'Series',
            METODO_PAGO: 'Payment method',
            CODIGO_PROPIO: 'Own code',
            CODIGO: 'Code',
            NOMBRE: 'Name',
            NOMBRE_COMPLETO: 'Full name',
            APELLIDO1: 'Surname 1',
            APELLIDO2: 'Surname 2',
            FECHA_ALTA: 'Admission date',
            FECHA_BAJA: 'Discharge date',
            FECHA_NACIMIENTO: 'Birth date',
            EDAD: 'Age',
            SEXO: 'Sex',
            DNI: 'DNI',
            PASAPORTE: 'Passport',
            NIE: 'NIE',
            CORREO: 'Email',
            TELEFONOS: 'Telephones',
            MOVILES: 'Mobile phones',
            CODIGO_POSTAL: 'Postal code',
            DIRECCION: 'Address',
            PAIS: 'Country',
            PROVINCIA: 'Province',
            LOCALIDAD: 'Town',
            TUTOR_LEGAL: 'Legal tutor',
            NOTAS: 'Notes'

        },
        BTN: {ADD: 'Add'},
        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign up',
                FORGOT_BUTTON: 'Forgot your password?',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
                PLACEHOLDER: {
                    USERNAME: 'Email',
                    PASSWORD: 'Password',
                    REPEATPASSWORD: 'Repeat the password'
                },
            },
            LOGIN: {
                TITLE: 'Acces Inceme',
                BUTTON: 'Access',
                UNKONW_ERROR: 'Unexpected error. Try again',
            },
            FORGOT: {
                TITLE: 'Forgot your password?',
                DESC: 'Write your email to restore your password',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.',
            },
            INPUT: {
                USERNAME: 'User',
                EMAIL: 'Email',
                FULLNAME: 'Full name',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm password',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} needs a minimum length of {{min}}',
                AGREEMENT_REQUIRED: 'Accepting the terms and condition is required',
                NOT_FOUND: 'The value for {{name}} has not been specified',
                INVALID_LOGIN: 'Invalid login',
                REQUIRED_FIELD: 'The user and the password are required',
            },
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields',
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted',
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted',
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated',
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created',
                },
            },
        },
        ERROR_SMS: {
            UNKONW_ERROR: 'Unexpected error. Try again',
        },
        TABLE: {
            LOADING: {
                DELETE: 'Deleting',
                LOAD_DATA: 'Loading',
                EDIT: 'Updating',
                ADD: 'Creating {item}',
            },
            SEARCH: 'Search',
            COLUMNS: 'Columns',
            EXPORT: 'Export',
            TOOLTIP: {
                BTN_EDIT: 'Edit',
                BTN_DELETE: 'Delete',
                BTN_SELECTBENEFICIARY: 'Select beneficiaries'
            },
            STATUS: 'Status',
            CREATED_AT: 'Creation date',
            UPDATED_AT: 'Modification date',
            REQUIRED_FIELD: 'Required field',
            EMAIL_ERROR: 'The email doesn\'t have the right format',
            ROLE: {
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the role?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            SERIE: {
                COLUMNS: {
                    SERIE_NAME: 'Serie',
                    CONTADOR: 'Meter',
                    DEFAULT_SERIE: 'Default series',
                    CREATED: 'Created',
                    UPDATED: 'Updated'
                },
                TITLE: 'List of series for invoices',
                TITLE_ADD: 'New serie',
                TITLE_EDIT: 'Edit serie',
                TITLE_DELETE: 'Delete serie',
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the serie?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            IVA: {
                COLUMNS: {
                    IVA_NAME: 'Iva',
                    VALUE: 'Value',
                    CREATED: 'Created',
                },
                TITLE: 'Tipos de IVA',
                TITLE_ADD: 'Nuevo IVA',
                TITLE_EDIT: 'Editar IVA',
                SUCCESS_SMS: {
                    DELETE: 'The iva "{{iva}}" was successfully deleted',
                    EDIT: 'The iva was successfully edited',
                    ADD: 'The iva "{{iva}}" was successfully created',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the IVA?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            DIAGNOSTIC: {
                COLUMNS: {
                    DIAGNOSTIC: 'Diagnosis',
                    OBSERVATIONS: 'Observations',
                    LEVEL: 'Level',
                    SUBLEVEL: 'Sub Level',
                    CIE: 'Cie',
                    CIE10: 'Cie10',
                    FAVORITE: 'Favorite',
                    CREATED: 'Created',
                    UPDATED: 'Updated',
                },
                TITLE: 'Diagnosis',
                TITLE_ADD: 'New diagnosis',
                TITLE_EDIT: 'Editar Diagnosis',
                SUCCESS_SMS: {
                    DELETE: 'The diagnosis was successfully deleted',
                    EDIT: 'The diagnosis was successfully edited',
                    ADD: 'The diagnosis was successfully created',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you wan to delete the diagnosis?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            USER: {
                BTN_ADD: 'New user',
                ITEM: 'user',
                COLUMNS: {
                    FULL_NAME: 'Full name',
                    USERNAME: 'Username',
                    EMAIL: 'Email',
                    ROL: 'Role',
                    DOC: 'Doctor',
                    CREATED: 'Created',
                },
                CANCELANDRETURN: 'Cancel and return to the list',
                TITLE: 'List of users',
                TITLE_ADD: 'New user',
                TITLE_EDIT: 'Edit user',
                DATA: 'User data',
                ERROR_SMS: {
                    DELETE_YOURSELF: 'Error while deleting the user "{{user}}". The authenticated user can\'t be deleted.',
                    DELETE_GENERIC: 'Error while deleting the user "{{user}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the user "{{user}}". Please try again.',
                    ADD: 'Error while creating the user {{user}}.',
                    ADD_GENERIC: 'Error while creating the user "{{user}}". Please try again.',
                    ADD_PERSONAL_CLINIC: 'Error while creating the user "{{user}}". The chosen doctor has already been used.',
                    EDIT: 'Error while editing the user "{{user}}".',
                    EDIT_PERSONAL_CLINIC: 'Error while editing the user "{{user}}". The chosen doctor has already been used.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The user "{{user}}" has been successfully deleted.',
                    EDIT: 'The user was successfully edited',
                    ADD: 'The user "{{user}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the user?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            PERSONALCLINICAL: {
                BTN_ADD: 'New clinic staff',
                ITEM: 'clinic staff',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Postal code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Phones',
                    MOBILES: 'Mobile phones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                    SPECIALTY: 'Speciality',
                    IDENTIFICATIONTYPE: 'Identification type',
                    IDENTIFICATION: 'Identification',
                    AFFILIATECODE: 'Collegiate number'
                },
                TITLE: 'List of the clinic staff',
                TITLE_ADD: 'Nuevo personal clínico',
                TITLE_EDIT: 'Editar personal clínico',
                DATA: 'Datos del personal clínico',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the clinic staff "{{personal_clinico}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the clinic staff "{{personal_clinico}}". Please try again.',
                    EDIT: 'Error while editing the clinic staff "{{personal_clinico}}". The identifier is already registered in the system.',
                    ADD: 'Error while creating the clinic staff "{{personal_clinico}}". The identifier is already registerd in the system.',
                    ADD_GENERIC: 'Error while creating the clinic staff "{{personal_clinico}}". Please try again.',
                    AFFILIATEEXIST: 'Error, the collegiate number is already registered'
                },
                SUCCESS_SMS: {
                    DELETE: 'The clinic staff "{{personal_clinico}}" was successfully deleted.',
                    EDIT: 'The clinic staff "{{personal_clinico}}" was successfully edited.',
                    ADD: 'The clinic staff "{{personal_clinico}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the clinic staff?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            PATIENT: {
                BTN_ADD: 'New patient',
                ITEM: 'clinic staff',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Postal code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'List of patients',
                TITLE_ADD: 'New patient',
                TITLE_EDIT: 'Edit patient',
                DATA: 'Patient data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the patient "{{patient}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the patient "{{patient}}". Please try again.',
                    EDIT: 'Error while editing the patient "{{patient}}". The identifier is already registered in the system.',
                    ADD: 'Error while creating the patient "{{patient}}". The identifier is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the patient "{{patient}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The patient "{{patient}}" was successfully deleted.',
                    EDIT: 'The patient "{{patient}}" was successfully edited.',
                    ADD: 'The patient "{{patient}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the patient?',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancelar',
                    },
                },
                TABS: {
                    GENERAL_DATA: 'General data',
                    MUTUAL_ENTERPRICE: 'Mutuas and companies',
                    DONE: 'Done',
                },
            },
            CLINIC: {
                BTN_ADD: 'New clinic',
                ITEM: 'clinic',
                COLUMNS: {
                    NAME: 'Name',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    MOBILES: 'Mobile phones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    NOTES: 'Notes',
                },
                TITLE: 'List of clinics',
                DATA: 'Clinic data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the clinic {{clinic}}. Please try again.',
                    EDIT_GENERIC: 'Error while editing the clinic {{clinic}}. Please try again.',
                    ADD: 'Error while creating the clinic {{clinic}}. The clinic is already registered on the system.',
                    ADD_GENERIC: 'Error while creating the clinic {{clinic}}. Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The clinic "{{clinic}}" was successfully deleted.',
                    EDIT: 'The clinic has been successfully edited',
                    ADD: 'The clinic {{clinic}} was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Get ready to remove the clinic and all its data from the system. This action cannot be undone and entails the deletion of all the information as well as the cancellation of the subscription that you have active. Do you want to continue deleting the clinic and its data?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            MUTUAL: {
                BTN_ADD: 'New mutua',
                TITLE_ADD: 'New mutua',
                TITLE_EDIT: 'Edit mutua',
                ITEM: 'mutua',
                COLUMNS: {
                    NAME: 'Name',
                    CIF: 'CIF',
                    CONTRACTNUMBER: 'Contract number',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    COD_SPECIALTY: 'Speciality',
                    PHONES: 'Telephones',
                    MOBILES: 'Mobile phones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    NOTES: 'Notes',
                    CONTACTPERSON: 'Contact person',
                    URLWEB: 'Url Web',
                    FILE: 'File',
                    LOGO: 'Logo',
                    TARIFF: 'Price',
                },
                TITLE: 'List of mutuas',
                DATA: 'Mutua data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the mutua {{mutual}}. Please try again.',
                    EDIT_GENERIC: 'Error while editing the mutua {{mutual}}. Please try again.',
                    ADD: 'Error whlie creating the mutua {{mutual}}. The mutua is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the mutua {{mutual}}. Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The mutua "{{mutual}}" was successfully deleted.',
                    EDIT: 'The mutua {{mutual}} was successfully edited.',
                    ADD: 'The mutua {{mutual}} was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the mutua?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            BANK: {
                BTN_ADD: 'New bank',
                ITEM: 'bank',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'List of banks',
                TITLE_ADD: 'New bank',
                TITLE_EDIT: 'Edit bank',
                DATA: 'Bank data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the bank "{{bank}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the bank "{{bank}}". Please try again.',
                    ADD: 'Error while creating the bank "{{bank}}". The bank is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the bank "{{bank}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The bank "{{bank}}" was successfully deleted.',
                    EDIT: 'The bank "{{bank}}" was successfully edited.',
                    ADD: 'The bank "{{bank}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS:
                            'Do you want to delete the bank?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            INVOICE: {
                BTN_ADD: 'New invoice',
                ITEM: 'Invoice',
                COLUMNS: {
                    NUMBER: 'Invoice number',
                    SERIE: 'Series',
                    FORMTOPAYMENT: 'Payment methods',
                    EMITEDDATE: 'Office',
                    CODE: 'Address',
                    PATIENT: 'Post code',
                    TYPE: 'Country',
                    MUTUAL: 'Province',
                    COMPANY: 'Town',
                    PAID: 'Telephones',
                    WAYTOPAY: 'Fax',
                    CARDDATE: 'Email',
                    SENDDATE: 'Url Web',
                    DTO: 'Discount',
                    TOTAL: 'Total',
                },
                TITLE: 'List of invoices',
                TITLE_ADD: 'New invoice',
                TITLE_EDIT: 'Edit invoice',
                DATA: 'Invoice data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the invoice "{{invoice}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the invoice "{{invoice}}". Please try again.',
                    ADD: 'Error while creating the invoice "{{invoice}}". The bank is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the invoice "{{invoice}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The invoice "{{invoice}}" was successfully deleted.',
                    EDIT: 'The invoice "{{invoice}}" was successfully edited.',
                    ADD: 'The invoice "{{invoice}} was successfully created".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS:
                            'Do you want to delete the invoice?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            INVOICE_ASSISTANT: {
                TITLE: 'Invoicing assistant',
                BTN_CREAR_FACTURA: 'Create invoice',
                BTN_ADD_FACTURA: 'Add to invoice',
                TABLA: {
                    COD: 'COD',
                    DESCRIPTION: 'Description',
                    UNIDADES: 'Units',
                    CLINICA: 'Clinic',
                    DOCTOR: 'Doctor',
                    PERCENT_DISCOUNT: 'Discount %',
                    TOTAL: 'Total'
                }
            },
            INVOICE_CONCEPT: {
                BTN_ADD: 'New concept',
                ITEM: 'Invoice concept',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Invoice concepts',
                TITLE_ADD: 'New invoice concept',
                TITLE_EDIT: 'Editar invoice concept',
                DATA: 'Data of the concept',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while eliminating the concept "{{concept}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the concept "{{concept}}". Please try again.',
                    ADD: 'Error while creating the concept "{{concept}}". The code is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the concept "{{concept}}". Please try again.',
                    EDIT: 'Error while editing the concept "{{concept}}". The code is already registered in the system',
                },
                SUCCESS_SMS: {
                    DELETE: 'The concept "{{concept}}" was successfully deleted.',
                    EDIT: 'The concept "{{concept}}" was successfully edited.',
                    ADD: 'The concept "{{concept}} was successfully created".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the concept?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            MUTUAL_ENTERPRICE: {
                TITLE_MUTUA: 'List of mutuas',
                TITLE_ENTERPRICE: 'List of companies',
                MUTUA_ACTIVE: 'Mutua or active company:',
            },
            ESTABLISHMENT: {
                BTN_ADD: 'New establishment',
                ITEM: 'establishment',
                ITEM_PLURAL: 'Establishments',
                COLUMNS: {
                    NAME: 'Name',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    CLINIC: 'Clinic',
                    PHONES: 'Telephones',
                    MOBILES: 'Mobile phones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    NOTES: 'Notes',
                },
                TITLE: 'List of establishments',
                DATA: 'Establishment data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the establishment {{establishment}}. Please try again.',
                    EDIT_GENERIC: 'Error while editing the establishment {{establishment}}. Please try again.',
                    ADD: 'Error while creatig the establishment {{establishment}}. The establishment is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the establishment {{establishment}}. Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The establishment "{{establishment}}" was successfully deleted.',
                    EDIT: 'The establishment {{establishment}} was successfully edited.',
                    ADD: 'The establishment "{{establishment}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the establishment?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            TARIFF: {
                BTN_ADD: 'New price',
                ITEM: 'Price',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'List of prices',
                TITLE_ADD: 'New price',
                TITLE_EDIT: 'Edit price',
                DATA: 'Price data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the price "{{tariff}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the price "{{tariff}}". Please try again.',
                    EDIT: 'Error while editing the price "{{tariff}}". The code is already registered in the system.',
                    ADD: 'Error whice creating the price "{{tariff}}". The code is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the price "{{tariff}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The price "{{tariff}}" was successfully deleted.',
                    EDIT: 'The price "{{tariff}}" was successfully edited.',
                    ADD: 'The price "{{tariff}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the price?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            CATEGORY_FAMILY: {
                BTN_ADD: 'New family',
                ITEM: 'family',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'List of families',
                TITLE_ADD: 'New family',
                TITLE_EDIT: 'Edit family',
                DATA: 'Family data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the family "{{family}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the family "{{family}}". Please try again.',
                    EDIT: 'Error while editing the family "{{family}}". The code is already registered in the system.',
                    ADD: 'Error while creating the family "{{family}}". The code is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the family "{{family}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The family "{{family}}" was successfully deleted.',
                    EDIT: 'The family "{{family}}" was successfully edited.',
                    ADD: 'The family "{{family}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'If you delete the family, the associated concepts will also be deleted. Do you want to delete the family?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            COMPANY: {
                BTN_ADD: 'New company',
                ITEM: 'company',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephone',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                    OWNER: 'Owner'
                },
                TITLE: 'List of companies',
                TITLE_ADD: 'New company',
                TITLE_EDIT: 'Edit company',
                DATA: 'Company data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the company "{{company}}". Please try again.',
                    EDIT_GENERIC: 'Error while editing the company "{{company}}". Please try again.',
                    EDIT: 'Error while editing the company "{{company}}". The code is already registered in the system .',
                    ADD: 'Error while creating the company "{{company}}". The code is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the company "{{company}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The company "{{company}}" was successfully deleted.',
                    EDIT: 'The company "{{company}}" was successfully edited.',
                    ADD: 'The company "{{company}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the company?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
            },
            CODIFIER: {
                BTN_ADD: 'New codifier',
                ITEM: 'codifier',
                COLUMNS: {
                    NAME: 'Name',
                    ENTITY: 'Entity',
                    OFFICE: 'Office',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'List of codifiers',
                TITLE_ADD: 'New codifier',
                TITLE_EDIT: 'Edit codifier',
                DATA: 'Codifier data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the codifier "{{codifier}}". Please try again.',
                    EDIT: 'Error while editing the codifier "{{codifier}}". The codifier is already registered in the system.',
                    ADD: 'Error while creating the codifier "{{codifier}}". The codifier is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the codifier "{{codifier}}". Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The codifier "{{codifier}}" was successfully deleted.',
                    EDIT: 'The codifier "{{codifier}}" was successfully edited.',
                    ADD: 'The codifier "{{codifier}}" was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the codifier?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                    ADD: {
                        ADD_BTN_INSERT: 'Insert',
                        ADD_BTN_CANCEL: 'Cancel',
                    },
                    EDIT: {
                        BTN_EDIT: 'Edit',
                    },
                },
            },
            APPOINTMENT: {
                SINGULARTITLE: 'Appointments',
                BTN_ADD: 'New appointment',
                TITLE_ADD: 'New appointment',
                TITLE_EDIT: 'Edit appointment',
                TITLE_DELETE: 'Delete',
                TITLE_DUPLICATE: 'Duplicate',
                ITEM: 'appointment',
                REMAINDERS: 'Reminders',
                REMAINDERSDESCRIPTION: 'Reminders sent about this appointment',
                SENDREMAINDER: 'Remember',
                REMAINDERTITLE: 'Medical appointment reminder',
                REMAINDERTXT: 'We are writing to remind you that you have an appointment at the clinic {{clinicName}}' +
                    'on {{dateAndTime}}. If you cannot come, please let us know. ',
                JUSTIFYREPORTTITLE: 'note-appointment',
                JUSTIFY: 'Justify',
                COLUMNS: {
                    NAME: 'Name',
                    ADDRESS: 'Address',
                    POSTCODE: 'Post code',
                    COD_COUNTRY: 'Country',
                    COD_PROVINCE: 'Province',
                    COD_LOCALITY: 'Town',
                    PHONES: 'Telephones',
                    MOBILES: 'Mobile phones',
                    FAX: 'Fax',
                    EMAIL: 'Email',
                    NOTES: 'Notes',
                },
                TITLE: 'List of appointments',
                DATA: 'Appointment data',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error while deleting the appointment. Please try again.',
                    EDIT_GENERIC: 'Error while editing the appointment. Please try again.',
                    ADD: 'Error while creating the appointment. The clinic is already registered in the system.',
                    ADD_GENERIC: 'Error while creating the appointment. Please try again.',
                },
                SUCCESS_SMS: {
                    DELETE: 'The appointment was successfully deleted.',
                    EDIT: 'The appointment was successfully edited.',
                    ADD: 'The appointment was successfully created.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmation',
                        CONFIRMATION_SMS: 'Do you want to delete the appointment?',
                        CONFIRMATION_BTN_DELETE: 'Delete',
                        CONFIRMATION_BTN_CANCEL: 'Cancel',
                    },
                },
                RECURRENTAPPOINTMENT: 'Recurring apponintment',
                TITLERECURRENTAPPOINTMENT: 'Create recurring appointments',
                CONFIGRECURRENTAPPOINTMENT: 'Configure recurring appointment',
                CREATERECURRENTAPPOINTMENT: 'Generate Appointments',
                ALMOSTONEDAY: 'Must mark at least 1 day',
                EACHDAY: 'Each',
                INIGTEND: 'The start date cannot be greater than or equal to the end date.',
                CREATEAPPOINTMENTFROM: 'From'
            },
            PAGINATE: {
                ITEMS_PER_PAGE_LABEL: 'Elements by pages:',
                NEXT_PAGE_LABEL: 'Next',
                PREVIOUS_PAGE_LABEL: 'Previous',
                FIRST_PAGE_LABEL: 'First',
                LAST_PAGE_LABEL: 'Last',
                RANGE_PAGE_LABEL_1: '0 of {{length}}',
                RANGE_PAGE_LABEL_2: '{{startIndex}} - {{endIndex}} of {{length}}',
            },
            EMPTY_DATA: 'There are no data to be shown.',

        },
        MEDICALHISTORIAL: {
            TITLE: 'Medical record',
            ANTECEDENTS: 'History',
            ANTECEDENTSUPDATED: 'Saved history',
            ANTECEDENTSERRORUPDATING: 'Error  while saving the history',
            ALERTS: 'Allergies and other alerts',
            ALARMSUPDATED: 'Successfully updated alarms',
            CONSULTA: 'Consultation',
            CONSULTAS: 'Consultations',
            HISTORIALUPDATED: 'Successfully updated medical record',
            HISTORIALERRORUPDATING: 'Error while saving the medical record',
            MEDICALDIAGNOSTICADDED: 'Diagnosis added to record',
            MEDICALDIAGNOSTICERRORADDING: 'Error while adding diagnosis to record',
            MEDICALDIAGNOSTICDELETED: 'Deleted diagnosis from record',
            MEDICALDIAGNOSTICDELETEDERROR: 'Error while deleting diagnosis from record',
            MEDICALBIOMETRICITEMADDED: 'Diagnosis added to record',
            MEDICALBIOMETRICITEMERRORADDING: 'Error while adding diagnosis to record',
            MEDICALBIOMETRICITEMDELETED: 'Diagnosis deleted from records',
            MEDICALBIOMETRICITEMDELETEDERROR: 'Error while deleting diagnosis from records',
            DELETE: 'Are you sure you want to delete this entry from the record?',
            REASON: 'Reason'
        },
        BIOMETRICITEM: {
            TITLE: 'Consultation parameters',
            CONCEPT: 'Biometric concepts',
            UPDATED: 'Updated data and parameters for the consultation',
            DELETE: 'You are going to delete the following concept. Do you wish to continue?',
            DELETEDIALOGTITLE: 'Delete concept',
            DELETED: 'Concept deleted',
            NEW: 'New consultation parameter',
            HISTORIAL: 'Biometric history'
        },
        CALENDARIO: {
            VISTA_CITAS: 'View of the appointments',
            FILTRAR: 'Filter appointments',
            ESTADO: 'Status',
            POR_MEDICO: 'By doctor',
            POR_SALA: 'By room',
            TODAS: 'All',
            ATENDIDA: 'Attended',
            CONFIRMADA: 'In clinic',
            FALTA: 'Missing',
            COBRADA: 'Paid',
            COBRADA_FALTA: 'Paid-Missing',
            PERSONALCLINICAL: 'Clinic staff',
            INROOM: 'In room',
            INVOICED: 'Invoiced',
            MUTUAS: 'Mutuals',
            ROOMS: 'Rooms',
            LEYENDA: 'Legend',
            HOY: 'Today',
            VISTA_DIA: 'Day View',
            VISTA_SEMANA: 'Week View',
            VISTA_MULTI: 'Personal Clinical View',
            DIAS_SEMANA: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        },
        ROOM: {
            TITLE: 'List of rooms',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            UPDATED: 'Updated room',
            DELETE: 'You are going to delete the room. Do you want to continue?',
            DELETEDIALOGTITLE: 'Delete room',
            DELETED: 'Deleted room',
            ADD: 'Add room',
            ROOM_NAME: 'Name of the room',
            ROOM_DESCRIPTION: 'Description of the room',
            SAVE: 'Save',
            CANCEL: 'Cancel'
        },
    },
};
