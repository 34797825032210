import { Inject, Injectable } from '@angular/core';
import { Room } from '../../../../core/interfaces/room';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endPoint } from '../../../../../environments/environment';

@Injectable()
export class RoomService {
    private headers: HttpHeaders;
    private URL_ROOM = '/rooms';
    private URL_CONTAINS = '/rooms/contains';

    constructor(private http: HttpClient) {
        // Get token from local storage and save in headers attr.
        this.headers = new HttpHeaders({
            Authorization: localStorage.getItem('accessToken'),
            'Accept-Language': 'es',
        });
    }

    getRooms(): Observable<Room[]> {
        const { headers } = this;
        return this.http.get<Room[]>(endPoint + this.URL_ROOM, { headers });
    }

    getRoomContains(search: string): Observable<Room[]> {
        return this.http.get<Room[]>(
            endPoint + this.URL_CONTAINS + '/' + search,
            { headers: this.headers },
        );
    }

    getRoom(id: string): Observable<Room> {
        return this.http.get<Room>(
            endPoint + this.URL_ROOM + '/' + id,
            { headers: this.headers },
        );
    }

    deleteRoom(room: Room): Observable<any> {
        return this.http.delete(endPoint + this.URL_ROOM + '/' + room.id, { headers: this.headers });
    }

    insertRoom(room: Room): Observable<Room> {
        return this.http.post<Room>(endPoint + this.URL_ROOM, room, { headers: this.headers });
    }

    updateRoom(room: Room): Observable<Room> {
        return this.http.put<Room>(endPoint + this.URL_ROOM + '/' + room.id, room, { headers: this.headers });
    }

}
