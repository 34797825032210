<div class="countrycity">
    <div id="countryField" class="col">
        <mat-form-field>
            <mat-label>{{'FORMS.PAIS'|translate}}</mat-label>
            <input type="text" id="country" (blur)="checkEmpty($event)"
                   placeholder="Indique el país"
                   aria-label="Number"
                   matInput
                   [formControl]="_countryFc"
                   [matAutocomplete]="auto"
                   autocomplete="nope">
            <mat-error *ngIf="_countryFc.invalid && _countryFc.errors?.noObject"> Seleccione un país de la lista
            </mat-error>
            <mat-error *ngIf="_countryFc.invalid && _countryFc.errors?.required"> Campo obligatorio</mat-error>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" (optionSelected)="setCountry()">
                <mat-option *ngFor="let country of countriesFiltered | async" [value]="country">
                    {{country.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div id="provinceField" class="col">
        <mat-form-field>
            <mat-label>{{'FORMS.PROVINCIA'|translate}}</mat-label>
            <input type="text" id="province" (blur)="checkEmpty($event)"
                   placeholder="Indique la provincia"
                   aria-label="Number"
                   matInput
                   [formControl]="_provinceFc"
                   [matAutocomplete]="autoProv"
                   autocomplete="nope">
            <mat-error *ngIf="_provinceFc.invalid && _provinceFc.errors?.noObject"> Seleccione una provincia de la
                lista
            </mat-error>
            <mat-error *ngIf="_provinceFc.invalid && _provinceFc.errors?.required"> Campo obligatorio</mat-error>
            <mat-autocomplete #autoProv="matAutocomplete" [displayWith]="displayName" (optionSelected)="setProvince()">
                <mat-option *ngFor="let prov of provincesFiltered | async" [value]="prov">
                    {{prov.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div id="localtyField" class="col">
        <mat-form-field>
            <mat-label>{{'FORMS.LOCALIDAD'|translate}}</mat-label>
            <input type="text" id="localty" (blur)="checkEmpty($event)"
                   placeholder="Indique la localidad"
                   aria-label="Number"
                   matInput
                   [formControl]="_localtyFc"
                   [matAutocomplete]="autoLoc"
                   autocomplete="nope">
            <mat-error *ngIf="_localtyFc.invalid && _localtyFc.errors?.noObject"> Seleccione una localidad de la lista
            </mat-error>
            <mat-error *ngIf="_localtyFc.invalid && _localtyFc.errors?.required"> Campo obligatorio</mat-error>
            <mat-autocomplete #autoLoc="matAutocomplete" [displayWith]="displayName" (optionSelected)="setLocalty()">
                <mat-option *ngFor="let loc of localtiesFiltered | async" [value]="loc">
                    {{loc.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
