<h1    mat-dialog-title>
    {{title}}
</h1>
<hr>
<mat-dialog-content>
    <span style="font-size: 15px !important;">
        <p [innerHTML]="messageText"></p>
     </span>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button *ngIf="buttons.indexOf('cancel')>-1" [ngClass]="cancelButtonClass" (click)="onNoClick()" cdkFocusInitial>
        <span>{{cancelButtonText}}</span>
    </button>
    <button mat-button mat-stroked-button *ngIf="buttons.indexOf('ok')>-1" [ngClass]="okButtonClass" [mat-dialog-close]="true">
        <span>{{okButtonText}}</span>
    </button>
</mat-dialog-actions>
