<!-- <div class="m-list-timeline__item"> -->

<span class="m-list-timeline__badge m-list-timeline__badge--state-warning"></span>

<div class="m-list-timeline__text" >
    <mat-card>
        <mat-card-content>
    <!--<a href={{item.link}}>-->
    <strong style="cursor: pointer" (click)="_edit(item.id)">{{_getDateToShow(item.datetime)}} - {{item.title}} &nbsp;<mat-icon>edit</mat-icon></strong>
    <!--</a>-->
    <br/>
    <p [innerHTML]="item.text" style="word-wrap:break-word; white-space:normal;"></p>

    <button mat-button mat-stroked-button (click)="_edit(item.id)" color="primary">Ver consulta completa</button>
            <button class="ml-2" mat-button mat-stroked-button (click)="_del(item.id)" color="warn">{{'BORRAR' | translate}}</button>


    <!--<span *ngIf="item.tags?.length > 0" class="m-badge m-badge&#45;&#45;wide" [ngClass]="badgeClass()">
		{{item.tags}}
	</span>-->
        </mat-card-content>
    </mat-card>
</div>



<span *ngIf="item.datetime" class="m-list-timeline__time">
	{{item.datetime | mTimeElapsed}}
</span>
<!-- </div> -->

