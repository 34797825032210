import {Component, OnInit, HostBinding, Input, EventEmitter, Output} from '@angular/core';
import {DateengtospaPipe} from '../../../../../../core/pipes/dateengtospa.pipe';
import {TranslateService} from '@ngx-translate/core';


export interface TimeItem {
    id?: string;
    title: string;
    text: string;
    datetime: string;
    link?: string;
    read: boolean;
}

@Component({
    selector: 'm-timeline-item',
    templateUrl: './timeline-item.component.html',
    styleUrls: ['./timeline-item.component.scss']
})
export class TimelineItemComponent implements OnInit {
    @Input() item: TimeItem;
    @Input() editable: boolean = false;
    @Output() edit: EventEmitter<string> = new EventEmitter<string>();
    @Output() del: EventEmitter<string> = new EventEmitter<string>();

    @HostBinding('class') classes = 'm-list-timeline__item';


    constructor(private dateengtospaPipe: DateengtospaPipe) {
    }

    ngOnInit() {
        if (this.item.read) {
            this.classes += ' m-list-timeline__item--read';
        }

    }

    _getDateToShow(date) {
        return this.dateengtospaPipe.transform(date);
    }

    badgeClass() {
        const badges: any = {
            urgent: 'm-badge--info',
            important: 'm-badge--warning',
            resolved: 'm-badge--success',
            pending: 'm-badge--danger'
        };
        /*if (this.item.tags.length > 0) {
            return badges[this.item.tags[0]];
        }*/
    }

    _edit(id) {
        console.log('_click id', id);
        if (id) {
            this.edit.emit(id);
        }
    }

    _del(id) {
        if (id) {
            this.del.emit(id);
        }
    }
}
