import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {CodifierService} from '../../../pages/components/codifier/codifier.service';
import {Codifier} from '../../../../core/interfaces/codifier';

@Component({
    selector: 'm-countrycity',
    templateUrl: './countrycity.component.html',
    styleUrls: ['./countrycity.component.scss']
})
export class CountrycityComponent implements OnInit {
    @Input() countryFc: FormControl;
    @Input() provinceFc: FormControl;
    @Input() localtyFc: FormControl;

    @Input() mandatories: Boolean = false;

    _countryFc: FormControl = new FormControl;
    countriesFiltered: Observable<Codifier[]>;
    countries: Codifier[] = [];
    _provinceFc: FormControl = new FormControl;
    provincesFiltered: Observable<Codifier[]>;
    provinces: Codifier[] = [];
    _localtyFc: FormControl = new FormControl;
    localtiesFiltered: Observable<Codifier[]>;
    localties: Codifier[] = [];


    constructor(private codifierService: CodifierService) {

    }


    ngOnInit(): void {
        this.codifierService.getCountries().toPromise().then(data => {
            console.log('ngOnInit->getCountries', data);
            this.countries = data;
        }).then(() => {
            this.countriesFiltered = this._countryFc.valueChanges.pipe(
                map((v: string) => {
                        console.log('ngOnInit->this._countryFc', this._countryFc);
                        if (typeof v === 'string') {
                            return this.countries.filter(c => c.name.toLowerCase().startsWith(v.toLowerCase()));
                        }
                    }
                )
            );
        }).then(() => {
            this.provincesFiltered = this._provinceFc.valueChanges.pipe(
                map((v: string) => {
                        console.log('ngOnInit->this._provinceFc', this._provinceFc);
                        if (typeof v === 'string') {
                            return this.provinces.filter(p => p.name.toLowerCase().startsWith(v.toLowerCase()));
                        }
                    }
                )
            );
        }).then(() => {
            this.localtiesFiltered = this._localtyFc.valueChanges.pipe(
                map((v: string) => {
                        console.log('ngOnInit->this._localtyFc', this._localtyFc);
                        if (typeof v === 'string') {
                            return this.localties.filter(l => l.name.toLowerCase().startsWith(v.toLowerCase()));
                        }
                    }
                )
            );
        }).then(() => {
            if (this.mandatories) {
                this._countryFc.setValidators([this.isObject(), Validators.required]);
                this._provinceFc.setValidators([this.isObject(), Validators.required]);
                this._localtyFc.setValidators([this.isObject(), Validators.required]);
            }
            this.initValues();
        });
    }

    async initValues() {
        if (this.countryFc.value !== null && this.countryFc.value !== '') {
            this._countryFc.setValue(this.countries.find(e => e.code === this.countryFc.value));
        } else {
            this._countryFc.setValue(this.countries.find(e => e.code === 'ES'));
        }
        this.setCountry();
        console.log('hola');
        await this.loadProvinces();
        console.log('initValues->this.provincies', this.provinces);
        if (this.provinceFc.value !== null && this.provinceFc.value !== '' && this.provinces.length > 0) {
            console.log('initValues->setValue this.provinceFc.value', this.provinceFc.value);
            this._provinceFc.setValue(this.provinces.find(e => e.code === this.provinceFc.value));
            this.setProvince();
            await this.loadLocalties();
            this._localtyFc.setValue(this.localties.find(e => e.code === this.localtyFc.value));
            this.setLocalty();
        }
        console.log('adios');


    }

    displayName(obj) {
        console.log('displayName', obj);
        if (obj) {
            return obj.name;
        }
    }

    isObject(): ValidatorFn {
        return (control: AbstractControl): {
            [key: string]: any
        } | null =>
            typeof control.value === 'object'
                ? null : {noObject: 'No es un objeto'};
    }

    setCountry() {
        console.log('setCountry');
        if (this._countryFc.value && typeof this._countryFc.value === 'object' && this._countryFc.value?.code) {
            this.countryFc.setValue(this._countryFc.value.code);
            this.loadProvinces();
        } else {
            this._countryFc.setValue(null);
            this.countryFc.setValue(null);
        }
    }


    setProvince() {
        console.log('setProvince');
        if (this._provinceFc.value && typeof this._provinceFc.value === 'object' && this._provinceFc.value?.code) {
            console.log('aaaa');
            this.provinceFc.setValue(this._provinceFc.value.code);
            this.loadLocalties();
        } else {
            this._provinceFc.setValue(null);
            this.provinceFc.setValue(null);
        }
    }

    setLocalty() {
        console.log('setLocalty');
        if (this._localtyFc.value && typeof this._localtyFc.value === 'object' && this._localtyFc.value?.code) {
            this.localtyFc.setValue(this._localtyFc.value.code);
        } else {
            this._localtyFc.setValue(null);
            this.localtyFc.setValue(null);
        }
    }

    async loadProvinces() {
        try {
            if (typeof this._countryFc?.value === 'object') {
                this.provinces = await this.codifierService.getProvinces(this._countryFc.value.code).toPromise();
            }
        } catch (err) {
            throw err;
        }
    }

    async loadLocalties() {
        try {
            if (typeof this._provinceFc?.value === 'object') {
                this.localties = await this.codifierService.getLocalties(this._provinceFc.value.code).toPromise();
            }
        } catch (err) {
            throw err;
        }
    }

    checkEmpty(ev) {
        console.log('checkEmpty->ev', ev);
        setTimeout(() => {
                switch (ev.target.id) {
                    case'country':
                        if (typeof ev.target.value !== 'object' || !ev.target.value?.code) {
                            this.setCountry();
                        }
                        break;
                    case'province':
                        if (typeof ev.target.value !== 'object' || !ev.target.value?.code) {
                            this.setProvince();
                        }
                        break;
                    case'localty':
                        if (typeof ev.target.value !== 'object' || !ev.target.value?.code) {
                            this.setLocalty();
                        }
                        break;
                    default:
                        break;
                }
            }, 500
        );

    }

}



