<style>
	.menu {
		display: flex;
		justify-content: flex-end;
	}

	#m_aside_left_offcanvas_toggle {
		line-height: 4;
	}

	@media(min-width: 1025px) {
		.only-mobile {
			display: none;
		}
	}

	.menu span {
		color: #fff;
	}
</style>
<!-- BEGIN: Brand -->
<!--<div class="m-stack__item m-brand">-->
<div class="m-stack m-stack--ver m-stack--general">
	<div class="m-stack__item m-stack__item--middle m-brand__logo">
		<!--<a routerLink="/" class="m-brand__logo-wrapper">-->
		<h2 style="color: white">Inceme</h2>
		<!--</a>-->
	</div>
	<div class="m-stack__item m-stack__item--middle m-brand__tools">
		<!-- BEGIN: Left Aside Minimize Toggle -->
		<a *ngIf="menuAsideMinimizToggle" href="javascript:;"
			[ngClass]="{ 'm-brand__toggler--active': menuAsideMinimizeDefault }" mMenuAsideToggle
			id="m_aside_left_minimize_toggle"
			class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
			<span></span>
		</a>
		<!-- END -->

		<!-- BEGIN: Responsive Aside Left Menu Toggler -->
		<div class="menu">
			<div id="m_aside_left_offcanvas_toggle">
				<span class="only-mobile" *ngIf="menuAsideDisplay">Menu</span>
				<a class="m-brand__icon m-brand__toggler m-brand__toggler--left
					m--visible-tablet-and-mobile-inline-block">
					<span></span>
				</a>


			</div>
			<m-avatar class="only-mobile"></m-avatar>

			<!-- END -->

			<!-- BEGIN: Responsive Header Menu Toggler -->
			<!-- [BUG] issue with *ngIf="menuHeaderDisplay", mOffcanvas trigger first before this DOM exist -->
			<!-- <a *ngIf="menuHeaderDisplay" id="m_aside_header_menu_mobile_toggle" href="javascript:;" class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
			<span></span>
		</a> -->
			<!-- END -->

			<!-- BEGIN: Topbar Toggler -->
			<!-- <a id="m_aside_header_topbar_mobile_toggle" (click)="clickTopbarToggle($event)" href="javascript:;"
			class="m-brand__icon m--visible-tablet-and-mobile-inline-block">
			<i class="flaticon-more"></i>
		</a> -->
		</div>
		<!-- BEGIN: Topbar Toggler -->


	</div>
</div>
<!--</div>-->
<!-- END: Brand -->