import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateengtospa'
})
export class DateengtospaPipe implements PipeTransform {

    transform(value: string): string {
        //recibe la fecha en formato yyyy-mm-dd
        let res = '';
        const temp = value.split('-');
        if (temp[2] && temp[2].length === 1) {
            res += '0' + temp[2];
        }
        if (temp[2] && temp[2].length === 2) {
            res += temp[2];
        }
        if (temp[1] && temp[1].length === 1) {
            res += '-' + '0' + temp[1];
        }
        if (temp[1] && temp[1].length === 2) {
            res += '-' + temp[1];
        }

        if (temp[0] && temp[0].length === 4) {
            res += '-' + temp[0];
        }

        if (res.length === 10) {
            return res;
        }

        return value;
    }

}
