<div>
<p class="text-center text-capitalize font-weight-bold">{{'FILTERS' | translate}}:</p>

<div class="formfilter form-group">

    <div *ngFor="let c of displayedColumns; index as i">
                    <span *ngIf="c.filterable!=false">

                        <label>{{c.label}}</label>
                        <input [id]="c.name" autocomplete="none" type="text" [(ngModel)]="filterField[i].value"
                               (keydown)="keypressFilter($event)" class="form-control filter-field">
                        <span class="exactCheck">
                            <label>Exacto</label>
                            <input type="checkbox" [(ngModel)]="filterField[i].exact">

                        </span>
                    </span>
    </div>
    <div class="btFiltrar">
        <button type="button" color="primary" (click)="buildFilter()" mat-stroked-button mat-icon-button
                matSuffix>
            <mat-icon color="primary">search</mat-icon>
        </button>
        <button type="button" color="warn" (click)="clearFilter()" mat-stroked-button mat-icon-button
                matSuffix>
            <mat-icon color="warn">clear</mat-icon>
        </button>
    </div>
</div>

</div>
