import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable()
export class TokenStorage {
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('accessToken');
		return of(token);
	}

    /**
     * Get access user
     * @returns {Observable<user>}
     */
    public getAccessUser() {
        const user: string = <string>localStorage.getItem('user');
        return of(JSON.parse(user));
    }


	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('refreshToken');
		return of(token);
	}

	// /**
	//  * Get user roles in JSON string
	//  * @returns {Observable<any>}
	//  */
	// public getUserRoles(): Observable<any> {
	// 	const roles: any = localStorage.getItem('userRoles');
	// 	try {
	// 		return of(JSON.parse(roles));
	// 	} catch (e) {}
	// }

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(accessData): TokenStorage {
		console.log('token-storage.service saveAccessToken token=>', accessData.token);
		localStorage.setItem('accessToken', accessData.token);
		localStorage.setItem('user', JSON.stringify(accessData));
		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('user');
		localStorage.removeItem('p');
        /*localStorage.removeItem('language');*/
	}
}
