import {enableProdMode, ɵresetCompiledComponents} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import LogRocket from 'logrocket';

declare var klaro;

import {AppModule} from './app/app.module';
import {environment, version} from './environments/environment';
/*import {hmrBootstrap} from './hmr';
import {hmrModule} from '@angularclass/hmr';*/


// Sentry.init({
// 	dsn: "https://f86a6223056145bb8b2cfc97aadb2167@o482954.ingest.sentry.io/5533923",
// 	integrations: [
// 		new Integrations.BrowserTracing({
// 			tracingOrigins: ["https://app.inceme.com"],
// 			routingInstrumentation: Sentry.routingInstrumentation,
// 		}),
// 	],

// 	// We recommend adjusting this value in production, or using tracesSampler
// 	// for finer control
// 	tracesSampleRate: 1.0,
// });


/*const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);*/

if (environment.production) {
    enableProdMode();
    LogRocket.init('juzbsg/inceme', {
        release: version
    });
    if (window) {
        window.console.log = window.console.warn = window.console.info = function () {
            // Don't log anything.
        };
    }
}

/*if (environment.hmr) {
    if (module['hot']) {

        bootstrap().then((ngModuleRef) => {
            module['hot'].accept();
            module['hot'].dispose(() => ɵresetCompiledComponents());
            return hmrModule(ngModuleRef, module);
        }).catch(err => console.log(err));
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
    /!*if (module[ 'hot' ]) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }*!/
} else {
    bootstrap().catch(err => console.log('hmrBootstrap error', err));
}*/

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(success => console.log(`Bootstrap success`))
	.catch(err => console.error(err));

