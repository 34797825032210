<!-- BEGIN: Left Aside -->

<!-- <div mMenuAsideOffcanvas id="m_aside_left" class="m-grid__item m-aside-left" [ngClass]="asideLeftClasses"> -->
<!-- BEGIN: Aside Menu -->
<div style="z-index: 800;" mMenuAside (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
	id="m_ver_menu" class="m-aside-menu" m-menu-vertical="1"
	[ngClass]="classInitService.onClassesUpdated$ | async | mGetObject:'aside_menu' | mJoin"
	[attr.m-menu-dropdown]="menuAsideService.isDropdown"
	[attr.m-menu-dropdown-timeout]="menuAsideService.dropdownTimeout"
	[attr.m-menu-scrollable]="menuAsideService.isScrollable">
	<ul class="m-menu__nav"
		[ngClass]="classInitService.onClassesUpdated$ | async | mGetObject:'aside_menu_nav' | mJoin">
		<ng-container [ngTemplateOutlet]="mMenuListing"></ng-container>
	</ul>
	<a routerLink="/subscription" *ngIf="user?.superuser">
		<m-subscription-button-info></m-subscription-button-info>
	</a>
	
	<div class="row" style="text-align: center;">
		<div class="col-12" style="cursor: pointer;" (click)="setCookies()">{{'SETCOOKIES' | translate}}</div>
	</div>
	<div class="row" style="text-align: center;">
		<div class="col-12" style="cursor: pointer;background:antiquewhite" (click)="goTutorial()"><span
				class="material-icons">
				import_contacts
			</span> Tutorial</div>
	</div>
	
	<div class="row">
		<div class="col-12" style="text-align:center">
			<img src="../../../../assets/inceme/img/ivace.jpg" style="width: 100%;">
		</div>
	</div>
	<div class="row" class="recovery-link" style="text-align: center;">
		<div class="col-12">V: {{version}}</div>
	</div>
	<div class="row" style="text-align: center;">
		<div class="col-12">
			<a [href]="urlPrivacyPolicy" target="_blank">Política Privacidad</a>
		</div>
	</div>
	<div class="row" style="text-align: center;">
		<div class="col-12">
			<a [href]="urlLegalAdvice" target="_blank">Aviso Legal</a>
		</div>
	</div>
	<!-- END: Aside Menu -->
	<!-- </div> -->

	<ng-template #mMenuListing>

		<ng-container *ngFor="let child of menuAsideService.menuList$ | async">
			<!--<m-menu-section *ngIf="child.section" [item]="child"></m-menu-section>-->
			<ng-container *ngIf="child.name" [ngTemplateOutlet]="mMenuItem"
				[ngTemplateOutletContext]="{ item: child, parentItem: item , active:isMenuItemIsActive(child)}">
			</ng-container>
		</ng-container>
	</ng-template>

	<ng-template #mMenuItem let-item="item" let-parentItem="parentItem" let-active="active">
		<li class="m-menu__item"
			[ngClass]="{ 'm-menu__item--submenu': !item.options,  'm-menu__item--open' : item.options && isMenuItemIsActive(item) }"
			aria-haspopup="true">

			<!-- if menu item hasn't submenu -->
			<a *ngIf="item.url" [routerLink]="'/'+item.url" class="m-menu__link m-menu__toggle"
				[matTooltip]="item.tooltip" matTooltipPosition="left">
				<ng-container [ngTemplateOutlet]="mMenuItemText"
					[ngTemplateOutletContext]="{ item: item , parentItem: parentItem,active:active }"></ng-container>
			</a>
			<!-- if menu item has sumnenu child  -->
			<a href="javascript:;" *ngIf="item.options" class="m-menu__link m-menu__toggle">
				<ng-container [ngTemplateOutlet]="mMenuItemText"
					[ngTemplateOutletContext]="{ item: item, parentItem: parentItem, active:active}"></ng-container>
			</a>

			<!-- if menu item has submenu child then recursively call new menu item component -->
			<div *ngIf="item.options" class="m-menu__submenu">
				<span class="m-menu__arrow"></span>
				<ul class="m-menu__subnav">
					<ng-container *ngFor="let child of item.options">
						<ng-container [ngTemplateOutlet]="mMenuItem"
							[ngTemplateOutletContext]="{ item: child, parentItem: item, active:isMenuItemIsActive(child) }">
						</ng-container>
					</ng-container>
				</ul>
			</div>
		</li>
	</ng-template>

	<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem" let-active="active">

		<!-- if menu item has icon -->
		<i *ngIf="item.options" class="m-menu__link-icon" [ngClass]="item.icon"></i>

		<!-- if menu item using bullet -->
		<i *ngIf="!item.options" class="m-menu__link-bullet m-menu__link-bullet--dot">
			<span></span>
		</i>
		<!--<i *ngIf="item.options" class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;line">-->
		<!--<span></span>-->
		<!--</i>-->

		<ng-container *ngIf="!item.badge; else mMenuLinkBadge">
			<!-- menu item title text -->
			<span *ngIf="item.options" class="m-menu__link-text" [ngClass]="{'activeMenu': active==true}">
				{{item.name}}
			</span>
			<span *ngIf="!item.options" class="m-menu__link-text" [ngClass]="{'activeSubMenu': active==true}">
				{{item.opt}}
			</span>
		</ng-container>

		<ng-template #mMenuLinkBadge>
			<!-- menu item with badge -->
			<span class="m-menu__link-title">
				<span class="m-menu__link-wrap">
					<span class="m-menu__link-text" [translate]="item.translate">{{item.name}}</span>
					<span class="m-menu__link-badge">
						<span class="m-badge" [ngClass]="item.badge.type">{{item.badge.value}}</span>
					</span>
				</span>
			</span>
		</ng-template>

		<!-- if menu item has submenu child then put arrow icon -->
		<i *ngIf="item.options" class="m-menu__ver-arrow la la-angle-right m-global-menu"></i>
	</ng-template>
	<!-- END: Left Aside -->