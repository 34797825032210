<div class="m-messenger__message m-messenger__message--out">
	<div class="m-messenger__message-body">
		<div class="m-messenger__message-arrow"></div>
		<div class="m-messenger__message-content">
			<div class="m-messenger__message-text">
				{{message.text}}
			</div>
		</div>
	</div>
</div>
