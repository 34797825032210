<ng-container [ngSwitch]="mode">
    <div class="mat-elevation-z8" *ngSwitchCase="'table'">
        <table #table mat-table [dataSource]="arrayElements" matSort width="100%" (matSortChange)="_sort($event)">
            <ng-container *ngFor="let col of displayedColumns" matColumnDef={{col.name}}>
                <th mat-header-cell [disabled]="col.sortable==false" mat-sort-header
                    *matHeaderCellDef> {{col.label}}</th>
                <td mat-cell
                    [width]="col.width ? col.width : 'auto'" *matCellDef="let row"
                    [class]="col.class ? col.class : ''" >
                    <span *ngIf="col.type===undefined || col.type!=='select'"  [class.clickable]="col.clickable===true"
                          (click)="col.clickable===true && select!==true ? _edit(row): (col.clickable===true && select===true ? _selected(row) : null)">
                        {{row[col.name]!==undefined ? row[col.name] : ''}}
                    </span>
                    <span *ngIf="col.type && col.type==='select'">
                        <mat-checkbox (change)="_checkToggle($event,col.name, row)"
                                      [checked]="row[col.name] ? row[col.name] : false"></mat-checkbox>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="padding-right: 10px; width:140px;background: ghostwhite">
                    <a *ngIf="_buttons.indexOf('edit') > -1 && select ==false && !checkDisableAction(row, _disableConditionEdit)" style="cursor: pointer"
                       (click)="select!==true ? _edit(row): (select===true ? _selected(row) : null)">
                        <mat-icon color="primary">create</mat-icon>
                    </a>
                    <a *ngIf="_buttons.indexOf('del') > -1 && select ==false && !checkDisableAction(row, _disableConditionDel)" style="cursor: pointer"
                       (click)="_del(row)">
                        <mat-icon color="warn">delete</mat-icon>
                    </a>
                    <a *ngIf="_buttons.indexOf('print') > -1 && select ==false && !checkDisableAction(row, _disableConditionPrint)" style="cursor: pointer"
                       (click)="_print(row)">
                        <mat-icon>print</mat-icon>
                    </a>
                    <a *ngIf="select === true" style="cursor: pointer" (click)="_selected(row)">
                        Select <mat-icon color="primary">exit_to_app</mat-icon>
                    </a>
                </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="_displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: _displayedColumns"></tr>
        </table>


    </div>
    <div *ngSwitchCase="'card'">

        <mat-card *ngFor="let row of (arrayElements | async )">
            <mat-card-title style="background: lightskyblue">
            </mat-card-title>
            <mat-card-content>
                <div class="col" *ngFor="let col of displayedColumns">
                    <span *ngIf="col.type===undefined || col.type!=='select'"
                          [class]="col.class ? col.class : ''" [class.clickable]="col.clickable===true" (click)="col.clickable===true && select!==true ? _edit(row): (col.clickable===true && select===true ? _selected(row) : null)"> {{col.label}}: {{row[col.name]}}</span>

                    <mat-checkbox *ngIf="col.type && col.type==='select'" (change)="_checkToggle($event,col.name, row)"
                                  [checked]="row[col.name] ? row[col.name] : false"></mat-checkbox>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-stroked-button *ngIf="_buttons.indexOf('edit') > -1 && select ==false && !checkDisableAction(row, _disableConditionEdit)"
                        (click)="_edit(row)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
                <button mat-stroked-button *ngIf="_buttons.indexOf('del') > -1 && select ==false && !checkDisableAction(row, _disableConditionDel)" (click)="_del(row)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
                <button class="printButton" mat-stroked-button *ngIf="_buttons.indexOf('print') > -1 && select ==false && !checkDisableAction(row, _disableConditionPrint)"
                        (click)="_print(row)">
                    <mat-icon>print</mat-icon>
                </button>
                <button mat-stroked-button *ngIf="select ===true" (click)="_selected(row)">
                    <mat-icon>exit_to_app</mat-icon> Select
                </button>
            </mat-card-actions>
        </mat-card>
    </div>

    <div *ngIf="length<1" class="nodata">
        <br/>
        {{'NODATA' | translate}}
        <hr>
    </div>
    <mat-paginator *ngIf=!hidePaginatorBar [pageSizeOptions]="[5, 10, 20]" (page)="_changePaginator($event)" [pageSize]="pageSize"
                   [pageIndex]="pageIndex" [length]="length" showFirstLastButtons></mat-paginator>
</ng-container>
