<!-- BEGIN: Topbar -->
<!-- <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav"> -->
	<div class="img-margin">
		<m-avatar></m-avatar>
	</div>
<!-- <div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general"> -->
	<!-- <div class="m-stack__item m-topbar__nav-wrapper"> -->
		<!-- <ul class="m-topbar__nav m-nav m-nav--inline"> -->
			<!-- <m-search-dropdown *ngIf="searchType === 'search-dropdown'"></m-search-dropdown> -->
			<!-- <m-search-default *ngIf="searchType === 'search-default'"></m-search-default> -->

			<!-- <m-notification></m-notification> -->
			<!-- <m-quick-action></m-quick-action> -->
			<!-- <m-language-selector></m-language-selector> -->
			<!-- <m-user-profile></m-user-profile> -->
			

			<!-- TODO; if layout config quick_sidebar.display is enabled -->
			<!-- <li id="m_quick_sidebar_toggle" class="m-nav__item">
				<a href="javascript:;" class="m-nav__link m-dropdown__toggle">
					<span class="m-nav__link-icon">
						<i class="flaticon-grid-menu"></i>
					</span>
				</a>
			</li> -->
		<!-- </ul> -->
	<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- END: Topbar -->