import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'm-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    buttons: Array<string> = ['cancel', 'ok'];
    okButtonText: string = this.translate.instant('ACCEPT');
    okButtonClass: string = 'text-primary';
    cancelButtonText: string = this.translate.instant('CANCEL');
    cancelButtonClass: string = 'text-danger';
    messageText: string = '';
    title: string = 'Confirmación';


    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string,
            buttons: Array<string>, okButtonText: string, cancelButtonText: string,
            okButtonClass: string, cancelButtonClass: string, messageText: string
        },
        public translate: TranslateService,
    ) {
        if (data.buttons) {
            this.buttons = data.buttons;
        }
        if (data.okButtonText) {
            this.okButtonText = data.okButtonText;
        }
        if (data.cancelButtonText) {
            this.cancelButtonText = data.cancelButtonText;
        }
        if (data.messageText) {
            this.messageText = data.messageText;
        }

        if (data.okButtonClass) {
            this.okButtonClass = data.okButtonClass;
        }
        if (data.cancelButtonClass) {
            this.cancelButtonClass = data.cancelButtonClass;
        }
        if (data.title) {
            this.title = data.title;
        }

    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
