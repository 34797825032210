import {Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {TableColumn} from '../../../../core/interfaces/tablecolumn';
import {FilterField} from '../../../../core/interfaces/filterfield';
import {FilterElement} from '../../../pages/components/patient/patient-list.component';

@Component({
    selector: 'm-golfilter',
    templateUrl: './golfilter.component.html',
    styleUrls: ['./golfilter.component.scss']
})
export class GolfilterComponent implements OnInit, OnChanges {
    @Input() displayedColumns: Array<TableColumn>;
    @Input() addToFilter: Array<FilterElement>;
    @Output() filterModified: EventEmitter<any> = new EventEmitter();

    filterField: Array<FilterField> = [];
    filterParam: string = '';

    constructor() {
    }

    ngOnInit(): void {
        console.log('ngOninit displayedcolumns', this.displayedColumns);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('ngOnChanges changes', changes);
        if (changes.displayedColumns && changes.displayedColumns.currentValue !== changes.displayedColumns.previousValue
            && changes.displayedColumns.currentValue.length > 0) {
            this.initFilterFields();
        }
        if (changes.addToFilter && changes.addToFilter.currentValue !== changes.addToFilter.previousValue
            && changes.addToFilter.currentValue.length > 0) {
            this.addFilter(changes.addToFilter.currentValue);
        }
    }

    initFilterFields() {
        this.displayedColumns.map((value, index) => {
            console.log('initFilterFields value', value);
            if (value.filterable !== false) {
                this.filterField[index] = {value: '', exact: value.equal ? true : false};
            }
        });
        console.log('initFilterfields filterField inicializado', this.filterField);
    }

    keypressFilter(e) {
        if (e.key === 'Enter' || e.key === 'Tab') {
            this.buildFilter();
        }
    }

    buildFilter() {
        const filter: Array<FilterElement> = [];
        this.filterField.map((value, index) => {
            if (this.displayedColumns[index] && value.value !== '') {
                filter.push({fieldName: this.displayedColumns[index].name, value: value.value, exact: value.exact});
            }
        });
        console.log('buildFilter');
        if (filter.length > 0) {
            console.log('buildfilter addFilter', filter);
            this.addFilter(filter);
        } else {
            console.log('buildfilter clearFilter');
            this.clearFilter();
        }
    }

    clearFilter() {
        if (this.filterParam !== '') {
            this.initFilterFields();

            this.filterParam = '';
            this.filterModified.emit(this.filterParam);
        }
    }

    /** Construye la cadena para enviar la condicion al servidor. El formato es
     * //formato filterParam'filter[name1][like]=fra&filter[last_name1][like]=gar';
     * @param filterToData
     */
    addFilter(filterToData: Array<FilterElement>) {
        console.log('addFilter filterToData', filterToData);
        let _filterParam = '';
        filterToData.forEach(e => {
            if (_filterParam.length > 0) {
                _filterParam += '&';
            }
            if (e.exact !== true) {
                _filterParam += `filter[${e.fieldName}][like]=${e.value}`;
            } else {
                _filterParam += `filter[${e.fieldName}][eq]=${e.value}`;
            }
        });

        if (_filterParam !== this.filterParam) {
            this.filterParam = _filterParam;
            /*       this.filter();*/
            this.filterModified.emit(this.filterParam);
        }
    }

}
