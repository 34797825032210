<m-portlet>
	<!-- use ng-container[mPortletHeadTitle] for the title -->
	<ng-container mPortletHeadTitle>
		<div class="m-portlet__head-title">
			<h3 class="m-portlet__head-text">
				Recent Activities
			</h3>
		</div>
	</ng-container>

	<!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
	<ng-container mPortletHeadTools>
		<ul class="m-portlet__nav">
			<li class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover" aria-expanded="true">
				<a href="#" class="m-portlet__nav-link m-portlet__nav-link--icon m-portlet__nav-link--icon-xl m-dropdown__toggle">
					<i class="la la-ellipsis-h m--font-brand"></i>
				</a>
				<div class="m-dropdown__wrapper" style="z-index: 101;">
					<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style="left: auto; right: 21.5px;"></span>
					<div class="m-dropdown__inner">
						<div class="m-dropdown__body">
							<div class="m-dropdown__content">
								<ul class="m-nav">
									<li class="m-nav__section m-nav__section--first">
										<span class="m-nav__section-text">Quick Actions</span>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-share"></i>
											<span class="m-nav__link-text">Activity</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-chat-1"></i>
											<span class="m-nav__link-text">Messages</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-info"></i>
											<span class="m-nav__link-text">FAQ</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-lifebuoy"></i>
											<span class="m-nav__link-text">Support</span>
										</a>
									</li>
									<li class="m-nav__separator m-nav__separator--fit">
									</li>
									<li class="m-nav__item">
										<a href="#" class="btn btn-outline-danger m-btn m-btn--pill m-btn--wide btn-sm">Cancel</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</ng-container>

	<!-- use ng-container[mPortletBody] for the body content -->
	<ng-container mPortletBody>
		<perfect-scrollbar class="m-scrollable m-scroller" style="height: 40vh;">
			<!--Begin::Timeline 2 -->
			<div class="m-timeline-2">
				<div class="m-timeline-2__items  m--padding-top-25 m--padding-bottom-30">
					<div class="m-timeline-2__item">
						<span class="m-timeline-2__item-time">10:00</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-danger"></i>
						</div>
						<div class="m-timeline-2__item-text  m--padding-top-5">
							Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
							<br> incididunt ut labore et dolore magna
						</div>
					</div>
					<div class="m-timeline-2__item m--margin-top-30">
						<span class="m-timeline-2__item-time">12:45</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-success"></i>
						</div>
						<div class="m-timeline-2__item-text m-timeline-2__item-text--bold">
							AEOL Meeting With
						</div>
						<div class="m-list-pics m-list-pics--sm m--padding-left-20">
							<a href="#">
								<img src="assets/app/media/img/users/100_4.jpg" title="">
							</a>
							<a href="#">
								<img src="assets/app/media/img/users/100_13.jpg" title="">
							</a>
							<a href="#">
								<img src="assets/app/media/img/users/100_11.jpg" title="">
							</a>
							<a href="#">
								<img src="assets/app/media/img/users/100_14.jpg" title="">
							</a>
						</div>
					</div>
					<div class="m-timeline-2__item m--margin-top-30">
						<span class="m-timeline-2__item-time">14:00</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-brand"></i>
						</div>
						<div class="m-timeline-2__item-text m--padding-top-5">
							Make Deposit
							<a href="#" class="m-link m-link--brand m--font-bolder">USD 700</a> To ESL.
						</div>
					</div>
					<div class="m-timeline-2__item m--margin-top-30">
						<span class="m-timeline-2__item-time">16:00</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-warning"></i>
						</div>
						<div class="m-timeline-2__item-text m--padding-top-5">
							Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
							<br> incididunt ut labore et dolore magna elit enim at minim
							<br> veniam quis nostrud
						</div>
					</div>
					<div class="m-timeline-2__item m--margin-top-30">
						<span class="m-timeline-2__item-time">17:00</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-info"></i>
						</div>
						<div class="m-timeline-2__item-text m--padding-top-5">
							Placed a new order in
							<a href="#" class="m-link m-link--brand m--font-bolder">SIGNATURE MOBILE</a> marketplace.
						</div>
					</div>
					<div class="m-timeline-2__item m--margin-top-30">
						<span class="m-timeline-2__item-time">16:00</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-brand"></i>
						</div>
						<div class="m-timeline-2__item-text m--padding-top-5">
							Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
							<br> incididunt ut labore et dolore magna elit enim at minim
							<br> veniam quis nostrud
						</div>
					</div>
					<div class="m-timeline-2__item m--margin-top-30">
						<span class="m-timeline-2__item-time">17:00</span>
						<div class="m-timeline-2__item-cricle">
							<i class="fa fa-genderless m--font-danger"></i>
						</div>
						<div class="m-timeline-2__item-text m--padding-top-5">
							Received a new feedback on
							<a href="#" class="m-link m-link--brand m--font-bolder">FinancePro App</a> product.
						</div>
					</div>
				</div>
			</div>
			<!--End::Timeline 2 -->
		</perfect-scrollbar>
	</ng-container>
</m-portlet>
