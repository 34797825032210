import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Clinic} from '../../../../core/interfaces/clinic';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {endPoint} from '../../../../../environments/environment';
import {Establishment} from '../../../../core/interfaces/establishment';
import {PersonalClinical} from '../../../../core/interfaces/personal-clinical';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentService {
    private headers: HttpHeaders;
    private URL_ESTABLISHMENT = '/establishments';
    private URL_CONTAINS = '/establishments/contains';

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({'Authorization': localStorage.getItem('accessToken')});
    }

    // todo: ver para q se utiliza esto, creo q esto no va aqui
    form: FormGroup = new FormGroup({
        id: new FormControl(null),
        name: new FormControl('', Validators.required),
        address: new FormControl(null),
        postcode: new FormControl(null),
        cod_country: new FormControl(null),
        cod_province: new FormControl(null),
        cod_locality: new FormControl(null),
        phones: new FormControl(null),
        mobiles: new FormControl(null),
        fax: new FormControl(null),
        email: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')),
        notes: new FormControl(null),
        status: new FormControl(null),
        created_at: new FormControl(null),
        updated_at: new FormControl(null),
    });

    getEstablishment(id: string): Observable<Establishment> {
        return this.http.get<Establishment>(
            endPoint + this.URL_ESTABLISHMENT + '/' + id,
            {headers: this.headers},
        );
    }

    getEstablishments(): Observable<Establishment[]> {
        return this.http.get<Establishment[]>(
            endPoint + this.URL_ESTABLISHMENT + '?sort=created_at',
            {headers: this.headers}
        );
    }

    deleteEstablishment(id): Observable<any> {
        return this.http.delete(endPoint + this.URL_ESTABLISHMENT + '/' + id, {headers: this.headers});
    }

    insertEstablishment(establishment):Observable<any> {
        return this.http.post(endPoint + this.URL_ESTABLISHMENT, establishment, {headers: this.headers});
    }

    updateEstablishment(id,data) {
        return this.http.put(endPoint + this.URL_ESTABLISHMENT + '/' + id,data, {headers: this.headers});
    }

    getEstablishmentContains(search: string): Observable<Establishment[]> {
        return this.http.get<Establishment[]>(
            endPoint + this.URL_CONTAINS + '/' + search,
            {
                headers: this.headers,
            },
        );
    }
}
