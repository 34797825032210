import { Component, OnInit,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { Establishment } from '../../../../core/interfaces/establishment';
import { User } from '../../../../core/interfaces/user';
import { EstablishmentService } from '../../../pages/components/establishment/establishment.service';






@Component({
  selector: 'm-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  public showUser: boolean = false;
  public user: User;
  public establishment: Establishment

  constructor(private router: Router,private auth: AuthenticationService, private establ:EstablishmentService) {
    
    this.auth.getLoggedUser().toPromise().then(u => {
      this.user = u;
      return this.establ.getEstablishment(this.user.id_establishment).toPromise();
    }).then(est=>{
      this.establishment = est;
    })
  }

  ngOnInit(): void {
  }
  show() {
    console.log('show-> showUser',this.showUser);
    this.showUser = !this.showUser;
  }
  public logout() {
    this.auth.logout(true);
    this.show();
    this.router.navigate(['']);
  }

  public editUser() {
    this.show();
    this.router.navigate(['/users', this.user.id]);
    
  }
}
