<button type="button" matTooltip={{info}} matTooltipClass='m-info-tooltip' (click)="openUrl()">
    <mat-icon color="primary">info</mat-icon>
    Info
</button>
<button *ngIf="introButton" (click)="_showIntro()">
    <mat-icon color="primary">play_circle_filled</mat-icon>
    {{'VIRTUALTOUR' | translate}}
</button>


