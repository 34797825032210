import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { User } from '../../../../../core/interfaces/user';
import { Clinic } from '../../../../../core/interfaces/clinic';
import { ClinicService } from '../../../../pages/components/clinic/clinic.service';
import { Establishment } from '../../../../../core/interfaces/establishment';
import { EstablishmentService } from '../../../../pages/components/establishment/establishment.service';


@Component({
    selector: 'm-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
    @HostBinding('class')
    // tslint:disable-next-line:max-line-length
    classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

    @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

    @Input() avatar: string = './assets/inceme/img/logo-2.png';
    @Input() avatarBg: SafeStyle = '';

    @ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;
    public user: User;
    private clinic: Clinic;
    public establishment: Establishment;

    constructor(private router: Router, private authService: AuthenticationService, private clinicService: ClinicService, private establishmentService: EstablishmentService, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {

    }
    initClinic() {
        this.authService.getLoggedUser().toPromise().then(u => {
            this.user = u;
            console.log('user', this.user);
            return this.establishmentService.getEstablishment(this.user.id_establishment).toPromise();
        }).then(est => {
            this.establishment = est;
            console.log('establishment', this.establishment)
        }).then(() => {
            this.cdr.detectChanges();
        })
    }
    ngOnInit(): void {
        if (!this.avatarBg) {
            this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/inceme/img/bg-user-component.jpg)');
        }
        this.initClinic();
        console.log('User-profile user', this.user);
    }

    public logout() {
        this.authService.logout(true);
        const token = <string>localStorage.getItem('accessToken');
        console.log(token);
        this.router.navigate(['']);
        this.cdr.detectChanges();
    }

    public editUser() {
        this.router.navigate(['/users', this.user.id]);
    }
}
