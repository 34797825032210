export const environment = {
    production: true,
    hmr: false
};
// Api routes.http://api.inceme.com/v1
export const urlApi = 'https://apitest.inceme.com/';
export const endPoint = urlApi + 'v1';
export const recoveryPassUrl = 'https://frontend.inceme.com/site/request-password-reset';

// Default lang.
export const defaultLang = 'es';

// Session expired time.
export const sessionExpired = 86400;

export const pageIndex = 1;
export const pageSize = 20;

export const stripePublicKey = 'pk_live_51HSN4GCK8jrEdDuwCSUM4xVFZKvNWFMjRFuBeOI6s4jGx5W0A8WXfgDPJOmYhHoWpF85aRfaZMP2Qml9oThoDsCH00NftdQhTK';

export const version = '2.0.1-test';
export const schedulerConfig = {startHour: 7, endHour: 24, firstWeekDay: 0, lastWeekDay: 6, hourDivision: 'quarterHour'};
export const legalAdviseUrl = 'https://www.inceme.com/aviso-legal';
export const privacyPolicyUrl = 'https://www.inceme.com/politica-privacidad-app';
