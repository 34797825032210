// Spain
export const locale = {
    lang: 'ca',
    data: {
        TRANSLATOR: {SELECT: 'Tria el teu idioma'},
        HORA: 'Hora',
        PACIENTE: 'Pacient',
        PACIENTES: 'Pacients',
        INVOICES: 'Factures',
        SALA: 'Sala',
        MODIFICAR: 'Modificar',
        MODIFYANDEXIT: 'Guardar i eixir',
        CANCELAR: 'Cancel·lar',
        BORRAR: 'Esborrar',
        ERRORGUARDANDO: 'Error al guardar',
        ERROROBTENERDATOS: 'Error amb l\'obtenció de les dades',
        BUSCARALARMA: 'Buscar Alarma',
        SEARCH: 'Buscar',
        PATIENTFULLNAME: 'Nom del pacient',
        TITLE: 'Títol',
        VALUE: 'Valor',
        NOTAS: 'Notes',
        GUARDAR: 'Guardar',
        NEW: 'Nuva',
        NEWM: 'Nou',
        MEDICO: 'Metge',
        FECHA: 'Data',
        TIPSHOWALLFILTER: 'Fes servir * per mostrar-ho tot',
        SELECT: 'Seleccionar',
        DRUGS: 'Fàrmacs',
        PRINT: 'Imprimir',
        INSERT: 'Inserir',
        LOADING: 'Carregant',
        EXPORTANDO: 'Obtenint dades per exportació',
        UDS: 'Uds',
        AMOUNT: 'Import',
        COD: 'Cod',
        DTO: 'Dte Imp',
        DTOPORC: 'Dte %',
        DESCRIPTION: 'Descripció',
        TOTAL: 'Total',
        PHONE: 'Telèfon',
        MOBILE: 'Mòbil',
        SIGNUP: 'Donar l\'alta',
        SIGNINGUP: 'Donant d\'alta l\'usuari',
        SIGNUPOK: 'Alta correcta',
        SIGNUPKO: 'Error al donar d\'alta',
        ERRORPASSWORDNOTMATCH: 'Les contrasenyes no coincideixen',
        ERRORMINLENGTH: 'Ha de tenir almenys {{num}} caracteres',
        INITDATE: 'Data d\'Inicio',
        INITHOUR: 'Hora d\'Inicio',
        ENDDATE: 'Data Fi',
        ENDHOUR: 'Hora Fi',
        PRINTCALENDAR: 'Imprimir calendari',
        SELECTPERSONALCLINICAL: 'Seleccioneu personal sanitari',
        FIELDMANDATORY: '{{nameField}} es obligatori',
        LOGOUT: 'Tancar sesió',
        SAVEOK: 'Guardat correctament',
        SAVEERROR: 'Error al guardar',
        SELECTLOGO: 'Seleccioneu logotip',
        CLOSE: 'Tancar',
        SCHEDULEREPORTVOID: 'No es pot generar l\'informe perquè no hi ha cites a l\'interval / professional seleccionat',
        NOAUTH: 'No teniu permís per accedir a aquesta funcionalitat, consulteu amb el vostre administrador.',
        APPOINTMENT: 'Cita',
        IMAGES: 'Imatges',
        IMAGEGALLERY: 'Galeria d\'imatges',
        IMAGETOOBIG: 'L\'imatge és molt gran, la mida màxima és de {{size}}',
        SELECTINGIMAGE: 'Seleccionant imatge',
        SAVING: 'Guardant',
        CLINIC: 'Clínica',
        RECOVERYPASS: 'Recuperar contrasenya',
        ADDDIAGNOSTIC: 'Afegir diagnòstic',
        ADDSELECTEDTREATMENT: 'Afegir tractament seleccionat',
        EXPAND: 'Expandir',
        CONTRACT: 'Contraure',
        ACCEPT: 'Acceptar',
        FILTERBYDESCRIPTION: 'Filtrar per descripció',
        FILTERBYCODE: 'Filtrar per codi',
        BILLTYPE: 'Tipus de factura',
        NOLINESINVOICE: 'No tens línies associades a la factura',
        CREATINGINVOICE: 'Generant factura',
        IVATYPE: 'Tipus IVA',
        ADDIVA: 'Afegir IVA',
        CANCELANDRESETFORM: 'Cancel·lar y netejar formulari',
        CODEEXISTS: 'El codi ja existeix',
        NCONTRACT: 'Nombre Contrate',
        DOCTORNAME: 'Personal Clínic',
        CONCEPT: 'Concepte',
        GOTOUNSUBSCRIBE: 'Es disposa a cancel·lar la subscripció {{text}}',
        GOTOREACTIVESUBSCRIBE: 'Es disposa a reactivar la subscripció {{text}}',
        CANTSUBSCRIBECAUSEINTERVAL: 'No pot contractar aquest pla. Posis en contacte amb INCEME a info@inceme.com o al telèfon 966787629 ',
        NOCHANGES: 'No s\'han fet canvis',
        ACTIVE: 'Actiu',
        NOACTIVE: 'No actiu',
        year: 'Any',
        month: 'Mes',
        WILLCANCELAT: 'S\'ha de cancel·lar el',
        WILLRENEWAT: 'Es renovarà el',
        REMOVESUBSCRIPTION: 'Eliminar subscripció',
        REACTIVESUBSCRIPTION: 'Re-activar subscripció',
        CARDEXPIRATIONDATE: 'Data de cad',
        CHANGECARD: 'Canviar Targeta',
        ADDCARD: 'Afegeix Targeta',
        NOACTIVESUBSCRIPTION: 'No hi ha cap subscripció activa',
        FREESUBSCRIPTIONTEXT: 'Subscripció Gratuïta',
        OTHERPLANS: 'Altres Plans Disponibles',
        USERS: 'Usuaris',
        CALENDARS: 'Agendes',
        ESTABLISHMENTS: 'Establiments',
        UNLIMITED: 'Il·limitat',
        SUBSCRIBETOIT: 'Subscriure',
        YES: 'Si',
        MODIFYSUBSCRIPTION: 'Modificar suscripcion',
        SUBSCRIPTIONDETAILS: 'Detalls de la subscripció',
        User: 'Usuari normal',
        Administrator: 'Administrador',
        BACKUP: 'Còpia de seguretat',
        INCLUDEINYOURPLAN: 'El teu pla inclou',
        MOREINFOPLAN: 'El meu plan (+ info)',
        REQUIRECOMPLETEPAYMENTMETHOD: 'Abans de subscriure necessita emplenar el mètode de pagament',
        SUBSCRIBEPLAN: 'Contractar un pla',
        CREDITCARD: 'Targeta de crèdit',
        UNIQUECANTDELETE: 'Aquest és l\'últim registre. Cal a el menys un registre i per aquest motiu no és possible esborrar.',
        SELECTEDPATIENT: 'Pacient seleccionat',
        ALL: 'Tots',
        SEND: 'Enviar',
        INVOICEPENDING: 'Pendent de facturar',
        BILLING: 'Facturació',
        REMOVECLINIC: 'ESBORRAR TOTES LES DADES DE LA CLÍNICA',
        CONFIRMDELETE: 'He llegit i estic d\'acord amb esborrar totes les dades de la meva clínica.',
        LASTCHANCE: 'Aquesta és l\'última oportunitat per evitar que tota la seva informació s\'esborri Esteu segur que voleu continuar?',
        DELETINGDATA: 'Esborrant les dades',
        DELETEDREDIRECT: 'CLÍNICA ELIMINADA .. redireccionant A PANTALLA D\'INICI',
        ANTECEDENTTOOLTIP: 'Premi modificar per començar a escriure',
        AGEVALIDATOR: 'La data de naixement no pot ser posterior a l\'actual',
        VALIDATECHARGE: 'El seu banc requereix que validi la seva subscripció',
        VALIDATE: 'Validi',
        NOTVALIDUSER: 'Usuari no vàlid. <a href="https://goltratec.com"> Contacti amb suport tècnic</a>',
        PAID: 'Pagado',
        CIF: 'CIF',
        SETCOOKIES: 'Configurar cookies',
        CHANGESNOSAVED: 'Hi ha canvis sense desar. Si continua es perdran continuar? ',
        DELETESTROKE: 'Elimina línia',
        UNDO: 'Desfer',
        EDITIMAGE: 'Edició d\'imatge',
        THICKNESS: 'Grosor',
        NEWPHOTO: 'Nova Foto',
        INACTIVE: 'Inactivo',
        NUMSESSIONS: 'Num sessions',
        EXPIREDATE: 'Caducidad',
        USEDSESSIONS: 'Sessions utilitzades',
        SELECTAPATIENT: 'Ha de seleccionar un pacient',
        AREYOUSUREDELETE: 'Es disposa a esborrar un element. Això no podrà desfer ¿segur que voleu continuar?',
        BONOACTIVE: 'Bono actiu',
        BONOEXPIRED: 'Bono caducat',
        BONOOWNER: 'Titular bono',
        EMAILNOTVALID: 'No és una adreça de correu vàlida',
        RESOLUTIONNOTVALID: 'La resolució és molt petita. Potser no vegis bé totes les opcions. S\'aconsella mínim 960px',
        FIRSTAPPOINTMENT: 'Primera visita',
        SECONDAPPOINTMENT: 'Segona visita',
        THIRDAPPOINTMENT: 'Tercera visita',
        PRIVACYPOLICYACCEPT: 'He llegit i accepto la política de privacitat',
        INFOSIGNUP: 'Per crear les vostres <b> compte gratuït al programa de gestió de clíniques </ b> inceme necessitem certa informació.',
        CONTACTMEBYEMAILON: 'Si tens qualsevol dubte recorda que pots contactar amb nosaltres a',
        ACCESSDATA: 'Dades d\'accés',
        INFOACCESSDATA: 'Aquestes dades et permetran <strong> accedir a el programa </ strong> Gestió de clíniques',
        INCEMETITLE: 'Inceme Gestió de clíniques i centres mèdics',
        COMPANYDATA: 'Dades de la clínica',
        CREATEFREEACCOUNT: 'Crea un compte gratuïta',
        COMPANYDATAINFO: 'Dades de la teva empresa per quan creus <strong> cites i factures als teus pacients </ strong>',
        LEGALNOTICE: 'Avís legal',
        YOUSHOULDACCEPTPOLICY: 'Ha d\'acceptar la política de privacitat',
        PATIENTNAME: 'Nom de l\'pacient',
        INFOHOWCREATENEWBONO: 'Premi "nou" per crear un bo o editi un bo existent',
        CREATINGNEWBONO: 'Creant nou bo',
        EDITINGBONO: 'S\'està editant bo',
        UNIQUENUMBERERROR: ' El codi ja està en ús.',
        FILTERS: 'Filtres',
        AGREEMODELS: 'Models de consentiment',
        DELETEDOK: 'Esborrat correctament',
        DELETEDKO: 'Error a l\'esborrar',
        DOCUMENTSENDMARKOK: 'Document marcat com a enviat',
        DOCUMENTSENDMARKKO: 'No s\'ha pogut marcar el document com a enviat',
        GENERATINGLINK: 'Generant enllaç',
        PATIENTAGREEMENTSENDMESSAGE: 'Li enviem el següent document per a la seva signatura. Cliqueu a l\'enllaç i seguiu les instruccions ',
        EMAILCONFIGINCOMPLET: 'Dades no vàlides per enviar email',
        MOBILECONFIGINCOMPLET: 'Dades no vàlides per enviar a el telèfon',
        FILETOSIGN: 'Fitxer per signar',
        SENDOK: 'Enviament correcte',
        DELETEDPATIENTBENEFICIARY: 'Pacient Beneficiari esborrat',
        ADDPATIENTBENEFICIARY: 'Pacient Beneficiari afegit',
        PACIENTEBENEFICIARIOBONO: 'Beneficiaris',
        SENDKO: 'Hi va haver algun error amb l\'enviament',
        AGREEMODELSFIELDS: {
            TITLE: 'Títol',
            COMMENTS: 'Notes',
            CONTENT: 'Contingut'
        },
        PATIENTAGREEMODEL: 'Acords i consentiments',
        SENDTOSIGN: 'Envia per signatura',
        COMUNICACIONES: 'Comunicacions',
        DATESERROR: 'Les dates no són correctes',
        SELECTEDPERSONALCLINICA: 'Personal clinic seleccionat',
        PERMISSIONS: 'Permisos',
        ROL_OPTION: 'Permisos del rol',
        CONFIG: 'Configuració',
        REMAINDER24H: 'Recordar almenys 24h abans per email',
        REMAINDER24HADVISE: 'Només es pot activar el recordatori automàtic si queden més de 2 hores per a la cita',
        SENDING: 'Enviant',
        PATIENTINFO: {
            LIST: {
                INTRO: 'Sembla que encara no tens cap pacient, dejame explicar-te què pots fer des d\'aquí. <br/> ' +
                    'Podràs crear, cercar, modificar i eliminar els pacients de la teva clínica. ' +
                    'Si es vol continuar amb l\'explicació prem <strong> següent </ strong>, si no tanca aquesta finestra i podràs tornar a veure aquestes explicacions' +
                    'Quan tu vulguis prement en el botó "tour virtual" que tens a la part superior de la finestra.',
                NEWPATIENT: 'A l\'prémer aquest botó accediràs a un formulari per crear un nou pacient.',
                FILTER: 'Fes servir aquests camps per poder buscar els pacients, pots combinarnos per aconseguir' +
                    'Una cerca que s\'ajusti al que necessites.',
                GRID: 'Aquí veus el llistat dels teus pacients, a cada fila tindràs un botó per modificar el pacient o eliminar'
            }
        },
        CALENDARSINFO: {
            INTRO: 'Sembla que ho has vist el nostre tutorial sobre aquest apartat. ' +
                'Si es vol veure-ho prem el botó SEGÜENT. Si prefereixes fer-ho en un altre moment, tanca aquesta finestra i quan ho deses pots prémer a "Tour Virtual" <br/> ' +
                'Des de les agendes podràs gestionar les cites de tots els professionals de la teva clíniques així com' +
                'Definir que tractaments i costos es van a aplicar a la teva pacient. Prem <strong> següent per continuar </ strong> ',
            NEW: 'A l\'prémer aquest botó accediràs a l\'formulari en el qual crear una nova cita. Podràs: ' +
                '<ul>' +
                '<li> Definir el pacient </li>' +
                '<li> Definir l\'hora d\'inici i fi </li>' +
                '<li> Especificar el professional i especialitat que va a tractar a l\'pacient </li>' +
                '<li> Definir els tractaments i el cost dels mateixos </li>' +
                '<li> Utilitza un bo de sessions en cas que el pacient disposi d\'algun </li>' +
                '<li> Definir l\'estat (està a la clínica, ha estat atès, ha faltat) </li>' +
                '<li> Facturar la cita </li>' +
                '</ul>',
            REMAINDER: 'Quan selecciones una cita al calendari, podràs prémer aquest botó per enviar un' +
                'Recordatori per whatsapp o per email al teu pacient. Recorda que per enviar recordatoris per whatsapp ' +
                'Has de tenir-lo obert al navegador (si connectes des ordinador) o tenir instal·lada l\'aplicació. <br / > ' +
                'En cas de voler enviar el recordatori per email, has de configurar prèviament les dades de servidor d\'enviament ' +
                'des de l\'apartat de clínica.',
            FILTER: 'Des d\'aquí podràs filtrar les cites sobre la base de diversos criteris. Els criteris disponibles dependran ' +
                'De la llicència que tinguis. Els possibles filtres són: ' +
                '<ul>' +
                '<li> Establiments </li>' +
                '<li> Personal clínic </li>' +
                '<li> Sales </li>' +
                '<li> Mútues </li>' +
                '</ul>',
            SELECTDATE: 'Selecciona que data (dia o setmana) vols veure de la teva agenda.',
            SCHEDULE: 'Pots seleccionar veure l\'agenda en vista <strong> dia </ strong> o en vista <strong> setmana </ strong>.' +
                'Pots insteactuar amb les diferents cites clicant sobre elles i seleccionant una de les opcions que t\'ofereix.' +
                '<br/> A més tindràs informació en forma d\'icones o colors sobre el seu estat, si està facturada, si és primera visita ...' +
                'I molta més coses.'
        },
        PERSONALCLINICALINFO: {
            INTRO: 'Sembla que ho has vist el nostre tutorial sobre aquest apartat. ' +
                'Si es vol veure-ho prem el botó SEGÜENT. Si prefereixes fer-ho en un altre moment, tanca aquesta finestra i quan ho deses pots prémer a "Tour Virtual" ',
            NEW: 'Prement aquest botó s\'obrirà el formulari que et permet crear un nou registre de personal clínic. ' +
                'Recorda que segons la llicència que tinguis, pot ser que no puguis tenir més d\'una persona.',
            LIST: 'Es llista el personal clínic que tens donat d\'alta a la teva clínica. Des d\'aquest llistat podràs accedir a cada ' +
                'registre per modificar-lo i fins i tot eliminar-lo.'
        },
        APPOINTMENTADDINFO: {
            INTRO: 'Sembla que ho has vist el nostre tutorial sobre aquest apartat. ' +
                'Si es vol veure-ho prem el botó SEGÜENT. <br/> Si prefereixes fer-ho en un altre moment, tanca aquesta finestra i quan ho desitgis pots prémer a "Tour Virtual" ',
            PATIENT: 'Les cites van vinculades a un pacient. Sempre has de seleccionar un pacient a l\'crear una cita. ',
            APPOINTMENTTYPE: 'El sistema, de forma automàtica, determina si es tracta de la primera, segona, tercera o successives cites. ' +
                'No obstant això pots forçar el valor que consideris',
            PERSONALCLINIC: 'En aquest camp defineixes el personal clínic que va atendre el pacient i per tant en què agenda ha de mostrar-aquesta cita',
            ESPECIALITY: 'Aquest camp s\'auto omple segons l\'especialitat de el personal clínic seleccionat',
            MUTUA: 'Si el pacient té assignada alguna mútua, en aquest camp podràs indicar-la en cas que la cita hagi de vincular-se a aquesta mútua.',
            ESTABLISHMENT: 'Quan la teva clínica disposi de diversos establiments, des d\'aquí pots indicar en qual es passarà la consulta. ' +
                'Això et permetrà tenir agendes totalment diferenciades per establiment.',
            ROOM: 'És possible que la consulta hagi de passar-se en una sala concreta. Amb aquest camp pots seleccionar ' +
                'Entre les sales que hagis donat d\'alta en la teva clínica.',
            STATES: 'Mitjançant els estats pot veure si un pacient està ja a la clínica, si està sent antendido,' +
                'Si ha finalitzat la seva consulta o fins i tot si no ha acudit a la cita.',
            INIT: 'Indica la data i hora d\'inici estimada de la cita',
            END: 'Indica l\'hora de fi estimada de la cita',
            BO: 'Un pacient pot haver contractat un bo amb sessions. ' +
                'Des d\'aquest desplegable podeu seleccionar el bo a què desitges descomptar la sessió corresponent a aquesta cita.',
            Notifications: 'Les cites es poden notificar per correu electrònic o whatsapp. Aquí veuràs les vegades que s\'ha notificat al ' +
                'pacient la present cita.',
            ASSISTANCE: 'Factura de forma senzilla les cites a través d\'l\'assistent de facturació.',
            SAVE: 'Guarda els canvis realitzats a la cita',
        },
        VIRTUALTOUR: 'Tour virtual',
        DAY: {M: 'Dilluns', T: 'Dimarts', W: 'Dimecres', TH: 'Dijous', F: 'Divendres', S: 'Dissabte', SU: 'Diumenge'},
        SELECTEDPERSONALCLINICALNOWORK: 'El personal clínic seleccionat no passa consulta els:',
        INFO: ' Informaçió',
        NOTIFICATIONTITLEDIALOG: 'Enviament per {{sendBy}} a {{to}}',
        SIGNDATE: 'Data de signatura',
        ADDCLAUSE: 'Afegir clàusula',
        ADDCLAUSES: 'Afegeix clàusules a l\'contracte',
        ADDCLAUSETOTEXT: 'Afegir a el text',
        FILEPATIENT: 'Fitxers',
        FILE: 'Fitxer',
        DOCUMENTS:'Documents',
        SIGNUPFORM: {
            NAME: 'Indiqui el seu nom',
            EMAIL: 'Indiqui el seu email',
            PASSWORD: 'Indiqui la seva contrasenya',
            REPEATPASSWORD: 'Repeteixi la seva contrasenya',
            COMPANY: 'Nom de la clínica',
            ADDRESS: 'Direcció',
            POSTCODE: 'Codi Postal',
            COUNTRY: 'País',
            PROVINCE: 'Província',
            LOCALITY: 'Localitat',
            SIGNUPCONTACTPERSON: 'Persona de contacte'
        },
        PRESCRIPTION: {
            TITLE: 'Receptes',
            SINGLETITLE: 'Receptes',
            DRUGNAME: 'Fàrmac',
            UPDATED: 'Recepta actualitzada',
            ERRORUPDATING: 'Error a l\'actualitzar la recepta',
            DELETED: 'Receta borrada',
            CONFIRMDELETE: 'Aquesta acció no podrà desfer-se ¿vol continuar y esborrar aquesta recepta?',
            INSTRUCTIONS: 'Instruccions',
            CONTAINER: 'Envàs',
            UNITS: 'Unitats',
            POSOLOGY: 'Posologia',
            PATTERN: 'Pauta',
            ADMINISTRATION_WAY: 'Vía d\'administració',
            ADITIONAL_INFO: 'Informació addicional',
            PACTIVOS: 'P. Actius',
            DRUGSEARCH: 'Escribi el fàrmac que busca',
            INPUTDRAGNAME: 'Seleccioni un fàrmac o escribi el text que desitgi'
        },
        ALARMAS: {
            BUSCARALARMA: 'Buscar Alarma',
            TITLE: 'Alarmes mèdiques (al·lèrgies i altres alertes importants)'
        },
        MENU: {
            NEW: 'Nou',
            ACTIONS: 'Comportament',
            CREATE_POST: 'Crear nova publicació',
            REPORTS: 'Informs',
            APPS: 'Aplicacions',
            DASHBOARD: 'Tauler',
            USER: 'Usuari',
        },
        FORMS: {
            CAMPO_REQUERIDO: 'Camp requerit',
            VALOR_INCORRECTO: 'Valor incorrecte',
            COD_OR_NAME_PATIENT: 'Codi o nom (Pacient)',
            MUTUA: 'Mútua',
            FECHA_INICIO: 'Data d\'inici',
            FECHA_FIN: 'Data fin',
            FECHA_FACTURA: 'Data factura',
            SERIE: 'Sèrie',
            METODO_PAGO: 'Mètode de pagament',
            CODIGO_PROPIO: 'C.Propi',
            CODIGO: 'Codi',
            NOMBRE: 'Nom',
            NOMBRE_COMPLETO: 'Nom complet',
            APELLIDO1: 'Cognom1',
            APELLIDO2: 'Cognom2',
            FECHA_ALTA: 'Data d\'alta',
            FECHA_BAJA: 'Data de baixa',
            FECHA_NACIMIENTO: 'Data de naixement',
            EDAD: 'Edat',
            SEXO: 'Sexe',
            DNI: 'DNI',
            PASAPORTE: 'Passaport',
            NIE: 'NIE',
            CORREO: 'Correu',
            TELEFONOS: 'Telèfons',
            MOVILES: 'Mòbils',
            CODIGO_POSTAL: 'Codi postal',
            DIRECCION: 'Direcció',
            PAIS: 'País',
            PROVINCIA: 'Província',
            LOCALIDAD: 'Localitat',
            TUTOR_LEGAL: 'Tutor Legal',
            NOTAS: 'Notes'

        },
        BTN: {ADD: 'Afegir'},
        AUTH: {
            GENERAL: {
                OR: 'O',
                SUBMIT_BUTTON: 'Enviar',
                NO_ACCOUNT: 'No tens un compte?',
                SIGNUP_BUTTON: 'Regístra\'t',
                FORGOT_BUTTON: 'Has oblidat la teua contrasenya',
                BACK_BUTTON: 'Esquena',
                PRIVACY: 'Intimitat',
                LEGAL: 'Legal',
                CONTACT: 'Contacte',
                PLACEHOLDER: {
                    USERNAME: 'Email',
                    PASSWORD: 'Contrasenya',
                    REPEATPASSWORD: 'Repeteixi la constrasenya'
                },
            },
            LOGIN: {
                TITLE: 'Accés a Inceme',
                BUTTON: 'Accedir',
                UNKONW_ERROR: 'Error inesperat. Intenti novament',
            },
            FORGOT: {
                TITLE: 'Contrasenya oblidada?',
                DESC: 'Ingressi el seu correu electrónic per restablir la seua contrasenya',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.',
            },
            INPUT: {
                USERNAME: 'Usuario',
                EMAIL: 'Correo',
                FULLNAME: 'Nombre completo',
                PASSWORD: 'Contraseña',
                CONFIRM_PASSWORD: 'Confirmar contraseña',
            },
            VALIDATION: {
                INVALID: '{{name}} no es valido',
                REQUIRED: '{{name}} es requerido',
                MIN_LENGTH: '{{name}} minima longitud {{min}}',
                AGREEMENT_REQUIRED: 'Aceptación de terminos y condiciones es obligatoria',
                NOT_FOUND: 'El valor para {{name}} no se ha especificado',
                INVALID_LOGIN: 'Credenciales inválidas',
                REQUIRED_FIELD: 'El usuario y la contraseña son obligatorios.',
            },
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields',
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted',
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted',
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated',
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created',
                },
            },
        },
        ERROR_SMS: {
            UNKONW_ERROR: 'Error inesperat. Intenti novament',
        },
        TABLE: {
            LOADING: {
                DELETE: 'Eliminant',
                LOAD_DATA: 'Carregant',
                EDIT: 'Actualitzant',
                ADD: 'Creant {item}',
            },
            SEARCH: 'Buscar',
            COLUMNS: 'Colunmes',
            EXPORT: 'Exportar',
            TOOLTIP: {
                BTN_EDIT: 'Editar',
                BTN_DELETE: 'Eliminar',
                BTN_SELECTBENEFICIARY: 'Tria beneficiaris'
            },
            STATUS: 'Estat',
            CREATED_AT: 'Data de creació',
            UPDATED_AT: 'Data de modificació',
            REQUIRED_FIELD: 'Camp requerit',
            EMAIL_ERROR: 'El correu no té format correcte',
            ROLE: {
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar el role ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            SERIE: {
                COLUMNS: {
                    SERIE_NAME: 'Serie',
                    CONTADOR: 'Comptador',
                    DEFAULT_SERIE: 'Serie per defecte',
                    CREATED: 'Creado',
                    UPDATED: 'Actualitzat'
                },
                TITLE: 'Llistat de sèries per factures',
                TITLE_ADD: 'Nou serie',
                TITLE_EDIT: 'Editar serie',
                TITLE_DELETE: 'Esborrar serie',
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar la serie ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            IVA: {
                COLUMNS: {
                    IVA_NAME: 'Iva',
                    VALUE: 'Valor',
                    CREATED: 'Creat',
                },
                TITLE: 'Tipus d\'IVA',
                TITLE_ADD: 'Nou IVA',
                TITLE_EDIT: 'Editar IVA',
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament l\'iva "{{iva}}".',
                    EDIT: 'S\'ha editat satisfactòriament l\'iva ',
                    ADD: 'S\'ha creat satisfactòriament l\'iva "{{iva}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar l\'IVA ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            DIAGNOSTIC: {
                COLUMNS: {
                    DIAGNOSTIC: 'Diagnòstic',
                    OBSERVATIONS: 'Observacions',
                    LEVEL: 'Nivell',
                    SUBLEVEL: 'Subnivell',
                    CIE: 'Cie',
                    CIE10: 'Cie10',
                    FAVORITE: 'Favorit',
                    CREATED: 'Creat',
                    UPDATED: 'Actualitzant',
                },
                TITLE: 'Diagnòstics',
                TITLE_ADD: 'Nou Diagnòstic',
                TITLE_EDIT: 'Editar Diagnòstic',
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament el diagnòstic.',
                    EDIT: 'S\'ha editat satisfactòriament el diagnòstic. ',
                    ADD: 'S\'ha creat satisfactòriament el diagnòstic.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar el diagnòstic ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            USER: {
                BTN_ADD: 'Nou usuari',
                ITEM: 'usuari',
                COLUMNS: {
                    FULL_NAME: 'Nom complet',
                    USERNAME: 'Usuari',
                    EMAIL: 'Correu',
                    ROL: 'Paper',
                    DOC: 'Metge',
                    CREATED: 'Creat',
                },
                CANCELANDRETURN: 'Cancel·lar y tornar al llistat',
                TITLE: 'Llistat d\'usuaris',
                TITLE_ADD: 'Nou usuari',
                TITLE_EDIT: 'Editar usuaro',
                DATA: 'Dades d\'usuaris',
                ERROR_SMS: {
                    DELETE_YOURSELF: 'Error al eliminar l\'usuari "{{user}}". L\'usuari autenticat no pot ser eliminat.',
                    DELETE_GENERIC: 'Error al eliminar l\'usuari "{{user}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar l\'usuari "{{user}}". Per favor intenti de nou.',
                    ADD: 'Error al crear l\'usuari {{user}}. .',
                    ADD_GENERIC: 'Error al crear l\'usuari "{{user}}". Per favor intenti de nou.',
                    ADD_PERSONAL_CLINIC: 'Error al crear l\'usuari "{{user}}". El metge seleccionat ja ha estat utilitzat.',
                    EDIT: 'Error a l\'editar l\'usuari "{{user}}".',
                    EDIT_PERSONAL_CLINIC: 'Error a l\'editar l\'usuari "{{user}}". El metge seleccionat ja ha estat utilitzat.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament l\'usuari "{{user}}".',
                    EDIT: 'S\'ha editat satisfactòriament l\'usuari ',
                    ADD: 'S\'ha creat satisfactòriamente l\'usuari "{{user}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar l\'usuari ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            PERSONALCLINICAL: {
                BTN_ADD: 'Nou personal clínic',
                ITEM: 'personal clínic',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    MOBILES: 'Mòbils',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                    SPECIALTY: 'Especialitat',
                    IDENTIFICATIONTYPE: 'Tipus d\'identificació',
                    IDENTIFICATION: 'Identificació',
                    AFFILIATECODE: 'Nº Colegiat'
                },
                TITLE: 'Llistat del personal clínic',
                TITLE_ADD: 'Nou personal clínic',
                TITLE_EDIT: 'Editar personal clínic',
                DATA: 'Dades del personal clínic',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar el personal clínic "{{personal_clinico}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar el personal clínic "{{personal_clinico}}". Per favor intenti de nou.',
                    EDIT: 'Error a l\'editar el personal clínic "{{personal_clinico}}". L\'identificador ja es troba registrat al sistema.',
                    ADD: 'Error al crear el personal clínic "{{personal_clinico}}". L\'identificador ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear el personal clínic "{{personal_clinico}}". Per favor intenti de nou.',
                    AFFILIATEEXIST: 'Error, el nombre de col·legiat ja està donat d\'alta'
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament el personal clínic "{{personal_clinico}}".',
                    EDIT: 'S\'ha editat satisfactòriament el personal clínic "{{personal_clinico}}".',
                    ADD: 'S\'ha creat satisfactòriament el personal clínic "{{personal_clinico}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar el personal clínic ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            PATIENT: {
                BTN_ADD: 'Nou pacient',
                ITEM: 'personal clínic',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Provincia',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfonos',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de pacients',
                TITLE_ADD: 'Nou pacient',
                TITLE_EDIT: 'Editar pacient',
                DATA: 'Dades del pacient',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar el pacient "{{patient}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar el pacient "{{patient}}". Per favor intenti de nou.',
                    EDIT: 'Error a l\'editar el pacient "{{patient}}". L\'identificador ja es troba registrat al sistema.',
                    ADD: 'Error al crear el pacient "{{patient}}". L\'identificador ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear el pacient "{{patient}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament el pacient "{{patient}}".',
                    EDIT: 'S\'ha editat satisfactòriament el pacient "{{patient}}".',
                    ADD: 'S\'ha creat satisfactòriament el pacient "{{patient}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar el pacient ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
                TABS: {
                    GENERAL_DATA: 'Dades generals',
                    MUTUAL_ENTERPRICE: 'Mútues y empreses',
                    DONE: 'Finalitzar',
                },
            },
            CLINIC: {
                BTN_ADD: 'Novs clínica',
                ITEM: 'clínica',
                COLUMNS: {
                    NAME: 'Nom',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfonos',
                    MOBILES: 'Mòbils',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de clíniques',
                DATA: 'Dades de la clínica',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar la clínica {{clinic}}. Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar la clínica {{clinic}}. Per favor intenti de nou.',
                    ADD: 'Error al crear la clínica {{clinic}}. La clínica ja es troba registrada al sistema.',
                    ADD_GENERIC: 'Error al crear la clínica {{clinic}}. Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament la clínica "{{clinic}}".',
                    EDIT: 'S\'ha editat satisfactòriament la clínica ',
                    ADD: 'S\'ha creat satisfactòriament la clínica {{clinic}}.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: 'Es diponse a eliminar la clínica i totes les seves dades de sistema. Aquesta acció no pot desfer-se i comporta l\'esborrat de tota la informació així com de la baixa de la subscripció que tingui activa.Voleu continuar eliminant la clínica i les seves dades?',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            MUTUAL: {
                BTN_ADD: 'Nova mutua',
                TITLE_ADD: 'Nova mutua',
                TITLE_EDIT: 'Editar mutua',
                ITEM: 'mutua',
                COLUMNS: {
                    NAME: 'Nom',
                    CIF: 'CIF',
                    CONTRACTNUMBER: 'Nombre del contracte',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    COD_SPECIALTY: 'Especialitat',
                    PHONES: 'Telèfons',
                    MOBILES: 'Mòbils',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    NOTES: 'Notes',
                    CONTACTPERSON: 'Persona de contacte',
                    URLWEB: 'Url Web',
                    FILE: 'Arxiu',
                    LOGO: 'Logotip',
                    TARIFF: 'Tarifa',
                },
                TITLE: 'Llistat de mutues',
                DATA: 'Dades de la mutua',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar la mutua {{mutual}}. Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar la mutua {{mutual}}. Per favor intenti de nou.',
                    ADD: 'Error al crear la mutua {{mutual}}. La mutua ja es troba registrada al sistema.',
                    ADD_GENERIC: 'Error al crear la mutua {{mutual}}. Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament la mutua "{{mutual}}".',
                    EDIT: 'S\'ha editat satisfactòriament la mutua {{mutual}}.',
                    ADD: 'S\'ha creat satisfactòriament la mutua {{mutual}}.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar la mutua ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            BANK: {
                BTN_ADD: 'Nou banc',
                ITEM: 'banco',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de bancs',
                TITLE_ADD: 'Nou banc',
                TITLE_EDIT: 'Editar banc',
                DATA: 'Dades del banc',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar el banc "{{bank}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar el banc "{{bank}}". Per favor intenti de nou.',
                    ADD: 'Error al crear el banc "{{bank}}". El banc ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear el banc "{{bank}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament el banc "{{bank}}".',
                    EDIT: 'S\'ha editat satisfactòriament el banc "{{bank}}".',
                    ADD: 'S\'ha creat satisfactòriament el banc "{{bank}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS:
                            '¿Voleu eliminar el banc ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            INVOICE: {
                BTN_ADD: 'Nova factura',
                ITEM: 'Factura',
                COLUMNS: {
                    NUMBER: 'Nombre de factura',
                    SERIE: 'Serie',
                    FORMTOPAYMENT: 'Formes de pagament',
                    EMITEDDATE: 'Oficina',
                    CODE: 'Direcció',
                    PATIENT: 'Codi postal',
                    TYPE: 'País',
                    MUTUAL: 'Província',
                    COMPANY: 'Municipi',
                    PAID: 'Telèfons',
                    WAYTOPAY: 'Fax',
                    CARDDATE: 'Correu',
                    SENDDATE: 'Url Web',
                    DTO: 'Dto',
                    TOTAL: 'Total',
                },
                TITLE: 'Llistat de factures',
                TITLE_ADD: 'Nova factura',
                TITLE_EDIT: 'Editar factura',
                DATA: 'Dades de la factura',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar la factura "{{invoice}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar la factura "{{invoice}}". Per favor intenti de nou.',
                    ADD: 'Error al crear la factura "{{invoice}}". El banc ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear la factura "{{invoice}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament la factura "{{invoice}}".',
                    EDIT: 'S\'ha editat satisfactòriament la factura "{{invoice}}".',
                    ADD: 'S\'ha creat satisfactòriament la factura "{{invoice}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS:
                            '¿Voleu eliminar la factura ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            INVOICE_ASSISTANT: {
                TITLE: 'Assistent de facturació',
                BTN_CREAR_FACTURA: 'Crear factura',
                BTN_ADD_FACTURA: 'Afegir a factura',
                TABLA: {
                    COD: 'COD',
                    DESCRIPTION: 'Descripció',
                    UNIDADES: 'Uds',
                    CLINICA: 'Clínica',
                    DOCTOR: 'Doctor',
                    PERCENT_DISCOUNT: 'Dto %',
                    TOTAL: 'Total'
                }
            },
            INVOICE_CONCEPT: {
                BTN_ADD: 'Nou concepte',
                ITEM: 'concepte facturable',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Conceptes facturables',
                TITLE_ADD: 'Nou concepte facturable',
                TITLE_EDIT: 'Editar concepte facturable',
                DATA: 'Dades del concepte',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar el concepte "{{concept}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar el concept "{{concept}}". Per favor intenti de nou.',
                    ADD: 'Error al crear el concepte "{{concept}}". El codi ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear el concepte "{{concept}}". Per favor intenti de nou.',
                    EDIT: 'Error al editar el concepte "{{concept}}". El codi ja es troba registrat al sistema',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament el concepte "{{concept}}".',
                    EDIT: 'S\'ha editat satisfactòriament el concepte "{{concept}}".',
                    ADD: 'S\'ha creó satisfactòriament el concepte "{{concept}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar el concepte ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            MUTUAL_ENTERPRICE: {
                TITLE_MUTUA: 'Llistado de mutues',
                TITLE_ENTERPRICE: 'Llistado de empreses',
                MUTUA_ACTIVE: 'Mutua o Empresa activa:',
            },
            ESTABLISHMENT: {
                BTN_ADD: 'Nou establiment',
                ITEM: 'establiment',
                ITEM_PLURAL: 'Establiments',
                COLUMNS: {
                    NAME: 'Nom',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    CLINIC: 'Clinica',
                    PHONES: 'Telèfons',
                    MOBILES: 'Mòbiles',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat d\'establiment',
                DATA: 'Dades de l\'establiment',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar l\'establecimient {{establishment}}. Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar l\'establiment {{establishment}}. Per favor intenti de nou.',
                    ADD: 'Error al crear l\'establiment {{establishment}}. l\'establiment ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear l\'establiment {{establishment}}. Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament l\'establiment "{{establishment}}".',
                    EDIT: 'S\'ha editat satisfactòriament l\'establiment {{establishment}}.',
                    ADD: 'S\'ha creat satisfactòriament l\'establiment "{{establishment}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar l\'establiment ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            TARIFF: {
                BTN_ADD: 'Nova tarifa',
                ITEM: 'tarifa',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de tarifes',
                TITLE_ADD: 'Nova tarifa',
                TITLE_EDIT: 'Editar tarifa',
                DATA: 'Dades de la tarifa',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar la tarifa "{{tariff}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar la tarifa "{{tariff}}". Per favor intenti de nou.',
                    EDIT: 'Error a l\'editar la tarifa "{{tariff}}". El codi ja es troba registrat al sistema.',
                    ADD: 'Error al crear la tarifa "{{tariff}}". El codi ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear la tarifa "{{tariff}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament la tarifa "{{tariff}}".',
                    EDIT: 'S\'ha editat satisfactòriament la tarifa "{{tariff}}".',
                    ADD: 'S\'ha creat satisfactòriament la tarifa "{{tariff}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar la tarifa ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            CATEGORY_FAMILY: {
                BTN_ADD: 'Nova família',
                ITEM: 'família',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de families',
                TITLE_ADD: 'Nova familia',
                TITLE_EDIT: 'Editar familia',
                DATA: 'Dades de la familia',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar la familia "{{family}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar la familia "{{family}}". Per favor intenti de nou.',
                    EDIT: 'Error a l\'editar la familia "{{family}}". El codi ja es troba registrat al sistema.',
                    ADD: 'Error al crear la familia "{{family}}". El codi ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear la familia "{{family}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament la familia "{{family}}".',
                    EDIT: 'S\'ha editat satisfactòriament la familia "{{family}}".',
                    ADD: 'S\'ha creat satisfactòriament la familia "{{family}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: 'Si esborra la família també s\'esborraran els conceptes associats. ¿Voleu eliminar la familia?',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            COMPANY: {
                BTN_ADD: 'Nova empresa',
                ITEM: 'empresa',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                    OWNER: 'Titular'
                },
                TITLE: 'Llistat d\'empreses',
                TITLE_ADD: 'Nova empresa',
                TITLE_EDIT: 'Editar empresa',
                DATA: 'Dades de l\'empresa',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar l\'empresa "{{company}}". Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar l\'empresa "{{company}}". Per favor intenti de nou.',
                    EDIT: 'Error a l\'editar l\'empresa "{{company}}". El codi ja es troba registrat al sistema.',
                    ADD: 'Error al crear l\'empresa "{{company}}". El codi ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear l\'empresa "{{company}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament l\'empresa "{{company}}".',
                    EDIT: 'S\'ha editat satisfactòriament l\'empresa "{{company}}".',
                    ADD: 'S\'ha creat satisfactòriament l\'empresa "{{company}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar l\'empresa ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
            },
            CODIFIER: {
                BTN_ADD: 'Nou codificador',
                ITEM: 'codificador',
                COLUMNS: {
                    NAME: 'Nom',
                    ENTITY: 'Entitat',
                    OFFICE: 'Oficina',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    URLWEB: 'Url Web',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de codificadors',
                TITLE_ADD: 'Nou codificador',
                TITLE_EDIT: 'Editar codificador',
                DATA: 'Dades del codificador',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar el codificador "{{codifier}}". Per favor intenti de nou.',
                    EDIT: 'Error a l\'editar el codificador "{{codifier}}". El codificador ja es troba registrat al sistema.',
                    ADD: 'Error al crear el codificador "{{codifier}}". El codificador ja es troba registrat al sistema.',
                    ADD_GENERIC: 'Error al crear el codificador "{{codifier}}". Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament el codificador "{{codifier}}".',
                    EDIT: 'S\'ha editat satisfactòriament el codificador "{{codifier}}".',
                    ADD: 'S\'ha creat satisfactòriament el codificador "{{codifier}}".',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voeleu eliminar el codificador ',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                    ADD: {
                        ADD_BTN_INSERT: 'Inserir',
                        ADD_BTN_CANCEL: 'Cancelar',
                    },
                    EDIT: {
                        BTN_EDIT: 'Editar',
                    },
                },
            },
            APPOINTMENT: {
                SINGULARTITLE: 'Cites',
                BTN_ADD: 'Nova cita',
                TITLE_ADD: 'Nova cita',
                TITLE_EDIT: 'Edita cita',
                TITLE_DELETE: 'Eliminar',
                TITLE_DUPLICATE: 'Duplicar',
                ITEM: 'cita',
                REMAINDERS: 'Recordatoris',
                REMAINDERSDESCRIPTION: 'Recordatoris enviats sobre aquesta cita',
                SENDREMAINDER: 'Recorda',
                REMAINDERTITLE: 'Recordatori de cita mèdica',
                REMAINDERTXT: 'Us escrivim per recordar-li que té una cita a la clínica {{clinicName}}' +
                    'L\'{{dateAndTime}}. Si no pot acudir, si us plau avisi\'ns. ',
                JUSTIFYREPORTTITLE: 'justificant-cita',
                JUSTIFY: 'Justificant',
                COLUMNS: {
                    NAME: 'Nom',
                    ADDRESS: 'Direcció',
                    POSTCODE: 'Codi postal',
                    COD_COUNTRY: 'País',
                    COD_PROVINCE: 'Província',
                    COD_LOCALITY: 'Municipi',
                    PHONES: 'Telèfons',
                    MOBILES: 'Mòbils',
                    FAX: 'Fax',
                    EMAIL: 'Correu',
                    NOTES: 'Notes',
                },
                TITLE: 'Llistat de cites',
                DATA: 'Dades de la cita',
                ERROR_SMS: {
                    DELETE_GENERIC: 'Error a l\'eliminar la cita. Per favor intenti de nou.',
                    EDIT_GENERIC: 'Error a l\'editar la cita. Per favor intenti de nou.',
                    ADD: 'Error al crear la cita. La clínica ja es troba registrada al sistema.',
                    ADD_GENERIC: 'Error al crear la cita. Per favor intenti de nou.',
                },
                SUCCESS_SMS: {
                    DELETE: 'S\'ha eliminat satisfactòriament la cita.',
                    EDIT: 'S\'ha editat satisfactòriament la cita.',
                    ADD: 'S\'ha creat satisfactòriament la cita.',
                },
                MODAL: {
                    DELETE: {
                        CONFIRMATION_TITLE: 'Confirmació',
                        CONFIRMATION_SMS: '¿Voleu eliminar la cita?',
                        CONFIRMATION_BTN_DELETE: 'Eliminar',
                        CONFIRMATION_BTN_CANCEL: 'Cancel·lar',
                    },
                },
                RECURRENTAPPOINTMENT: 'Cites recurrents',
                TITLERECURRENTAPPOINTMENT: 'Crear cites recurrents',
                CONFIGRECURRENTAPPOINTMENT: 'Configura cita recurrent',
                CREATERECURRENTAPPOINTMENT: 'Generar cites',
                ALMOSTONEDAY: 'Ha de marcar almenys 1 dia',
                EACHDAY: 'Tots els',
                INIGTEND: 'La data inicial no pot ser més gran o igual que la final.',
                CREATEAPPOINTMENTFROM: 'Fins'
            },
            PAGINATE: {
                ITEMS_PER_PAGE_LABEL: 'Elements per pàgines:',
                NEXT_PAGE_LABEL: 'Següent',
                PREVIOUS_PAGE_LABEL: 'Anterior',
                FIRST_PAGE_LABEL: 'Primera',
                LAST_PAGE_LABEL: 'Última',
                RANGE_PAGE_LABEL_1: '0 de {{length}}',
                RANGE_PAGE_LABEL_2: '{{startIndex}} - {{endIndex}} de {{length}}',
            },
            EMPTY_DATA: 'No existeixen dades per mostrar.',
        },
        MEDICALHISTORIAL: {
            TITLE: 'Historial Mèdic',
            ANTECEDENTS: 'Antecedents',
            ANTECEDENTSUPDATED: 'Antecedents guardats',
            ANTECEDENTSERRORUPDATING: 'Error guardant els antecedentes',
            ALERTS: 'Alergies y altres alertes',
            ALARMSUPDATED: 'Alarmes actualitzades correctamente',
            CONSULTA: 'Consulta',
            CONSULTAS: 'Consultes',
            HISTORIALUPDATED: 'Historial guardat correctamente',
            HISTORIALERRORUPDATING: 'Error al guardar l\'historial',
            MEDICALDIAGNOSTICADDED: 'Diagnòstic afegit a la història',
            MEDICALDIAGNOSTICERRORADDING: 'Error afegint el diagnòstic a la història',
            MEDICALDIAGNOSTICDELETED: 'Eliminat diagnòstic de la història',
            MEDICALDIAGNOSTICDELETEDERROR: 'Error eliminant diagnòstic de la història',
            MEDICALBIOMETRICITEMADDED: 'Diagnòstic afegit a la història',
            MEDICALBIOMETRICITEMERRORADDING: 'Error afegint el diagnòstic a la història',
            MEDICALBIOMETRICITEMDELETED: 'Eliminat diagnòstic de la història',
            MEDICALBIOMETRICITEMDELETEDERROR: 'Error eliminant diagnòstic de la història',
            DELETE: '¿Segur que voleu esborrar aquesta entrada de la història?',
            REASON: 'Motivo'
        },
        BIOMETRICITEM: {
            TITLE: 'Parametres per consultes',
            CONCEPT: 'Conceptes Biometria',
            UPDATED: 'Dades y paràmetres per les consultes actualitzades',
            DELETE: 'Va a esborrar el següent concepte. ¿Voleu continuar?',
            DELETEDIALOGTITLE: 'Esborrar concepte',
            DELETED: 'Concepte eliminat',
            NEW: 'Nou paràmetre per consultes',
            HISTORIAL: 'Històric biometria'
        },
        CALENDARIO: {
            VISTA_CITAS: 'Vista de cites',
            FILTRAR: 'Filtrar cites',
            ESTADO: 'Estat',
            POR_MEDICO: 'Per metge',
            POR_SALA: 'Per sala',
            TODAS: 'Todes',
            ATENDIDA: 'Ateses',
            CONFIRMADA: 'En clínic',
            FALTA: 'Faltes',
            COBRADA: 'Cobrades',
            COBRADA_FALTA: 'Cobrades-Falta',
            PERSONALCLINICAL: 'Personal clínic',
            INROOM: 'En sala',
            INVOICED: 'Facturada',
            MUTUAS: 'Mutuas',
            ROOMS: 'Sales',
            LEYENDA: 'Llegenda',
            HOY: 'Avui',
            VISTA_DIA: 'Vista dia',
            VISTA_SEMANA: 'Vista Setmana',
            VISTA_MULTI: 'Vista per personal clinic',
            DIAS_SEMANA: ['Diu', 'Dil', 'Dim', 'Dim', 'Dij', 'Div', 'Dis']
        },
        ROOM: {
            TITLE: 'Llistado de sales',
            NAME: 'Nom',
            DESCRIPTION: 'Descripció',
            UPDATED: 'Sala actualitzada',
            DELETE: 'Va a esborrar la sala. ¿Voleu continuar?',
            DELETEDIALOGTITLE: 'Esborrar sala',
            DELETED: 'Sala eliminada',
            ADD: 'Afegir sala',
            ROOM_NAME: 'Nom de la sala',
            ROOM_DESCRIPTION: 'Descripció de la sala',
            SAVE: 'Guardar',
            CANCEL: 'Cancel·lar'
        },
    },
};
