<div class="m-list-settings">
	<div class="m-list-settings__group">
		<div class="m-list-settings__heading">
			General Settings
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Email Notifications
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" checked="checked" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Site Tracking
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				SMS Alerts
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Backup Storage
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Audit Logs
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" checked="checked" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
	</div>
	<div class="m-list-settings__group">
		<div class="m-list-settings__heading">
			System Settings
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				System Logs
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Error Reporting
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Applications Logs
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Backup Servers
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" checked="checked" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
		<div class="m-list-settings__item">
			<span class="m-list-settings__item-label">
				Audit Logs
			</span>
			<span class="m-list-settings__item-control">
				<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
					<label>
						<input type="checkbox" name="">
						<span></span>
					</label>
				</span>
			</span>
		</div>
	</div>
</div>
