<!-- <li class="m-nav__item m-topbar__quick-actions m-topbar__quick-actions--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push m-dropdown--mobile-full-width m-dropdown--skin-light" m-dropdown-toggle="click"> -->
<a href="javascript:;" class="m-nav__link m-dropdown__toggle">
	<span class="m-nav__link-icon">
		<span class="m-nav__link-icon-wrapper">
			<i class="flaticon-share"></i>
		</span>
	</span>
</a>
<div class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
	<div class="m-dropdown__inner">
		<div class="m-dropdown__header m--align-center" style="background: url(./assets/app/media/img/misc/quick_actions_bg.jpg); background-size: cover;">
			<span class="m-dropdown__header-title">
				Quick Actions
			</span>
			<span class="m-dropdown__header-subtitle">
				Shortcuts
			</span>
		</div>
		<div class="m-dropdown__body m-dropdown__body--paddingless">
			<div class="m-dropdown__content">
				<div class="data" data="false" data-max-height="380" data-mobile-max-height="200">
					<div class="m-nav-grid m-nav-grid--skin-light">
						<div class="m-nav-grid__row">
							<a href="javascript:;" matTooltip="Non functional dummy link" matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon flaticon-file"></i>
								<span class="m-nav-grid__text">
									Generate Report
								</span>
							</a>
							<a href="javascript:;" matTooltip="Non functional dummy link" matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon flaticon-time"></i>
								<span class="m-nav-grid__text">
									Add New Event
								</span>
							</a>
						</div>
						<div class="m-nav-grid__row">
							<a href="javascript:;" matTooltip="Non functional dummy link" matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon flaticon-folder"></i>
								<span class="m-nav-grid__text">
									Create New Task
								</span>
							</a>
							<a href="javascript:;" matTooltip="Non functional dummy link" matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon flaticon-clipboard"></i>
								<span class="m-nav-grid__text">
									Completed Tasks
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- </li> -->
