import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-notincluded',
  templateUrl: './notincluded.component.html',
  styleUrls: ['./notincluded.component.scss']
})
export class NotincludedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
