/*import 'hammerjs';*/


import { Router } from "@angular/router";
// import * as Sentry from "@sentry/angular";

import { LogRocketErrorHandler } from './core/logrocket.handler';


import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, OnInit, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule , HammerModule  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { AuthenticationModule } from './core/auth/authentication.module';
import { IdleTimeoutService } from './core/auth/idleTimeout.service';
import { CoreModule } from './core/core.module';
import { ClassInitService } from './core/services/class-init.service';
import { ClipboardService } from './core/services/clipboard.sevice';
import { DataTableService } from './core/services/datatable.service';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { HeaderService } from './core/services/layout/header.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { LogsService } from './core/services/logs.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { MessengerService } from './core/services/messenger.service';
import { PageConfigService } from './core/services/page-config.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { UserService } from './core/services/user.service';
import { UtilsService } from './core/services/utils.service';
import { AuthInterceptor } from './core/auth/auth-interceptor';
import { ResizewindowService } from './core/util/resizewindow.service';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LayoutModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		NgxPermissionsModule.forRoot(),
		NgbModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		/*HammerModule,*/
	],
	providers: [
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		UserService,
		UtilsService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		LogsService,
		QuickSearchService,
		DataTableService,
		SplashScreenService,
		ResizewindowService,
		{
			provide:ErrorHandler,
			useClass:LogRocketErrorHandler
		},
		// {
		// 	provide: ErrorHandler,
		// 	useValue: Sentry.createErrorHandler({
		// 		showDialog: false,
		// 		logErrors: true,
		// 	}),
		// },
		// {
		// 	provide: Sentry.TraceService,
		// 	deps: [Router],
		// },
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: () => () => { },
		// 	deps: [Sentry.TraceService],
		// 	multi: true,
		// },
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
		IdleTimeoutService
	],
	bootstrap: [AppComponent]
})
export class AppModule implements OnInit {
	ngOnInit(): void {

	}
}
