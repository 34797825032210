import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Helper} from '@util/helper';

@Component({
    selector: 'm-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InfoComponent implements OnInit {
    @Input() info: string;
    @Input() url: string;
    /**
     * Muestra el botón para mostrar un tour. Este botón lleva asociado el evento showIntro
     */
    @Input() introButton: boolean = false;
    /**
     *este evento lo usamos para mostrar el tour con introjs. En realidad este evente solo disparará la función que muestre el tour.
     */
    @Output() showIntro: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {

    }

    openUrl(): void {
        Helper.openUrl(this.url);
    }

    _showIntro() {
        this.showIntro.emit();
    }

}
