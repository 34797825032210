<m-portlet [options]="{headOverlay: true}">
	<!-- use ng-container[mPortletHeadTitle] for the title -->
	<ng-container mPortletHeadTitle>
		<div class="m-portlet__head-title">
			<h3 class="m-portlet__head-text m--font-light">
				Finance Stats
			</h3>
		</div>
	</ng-container>

	<!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
	<ng-container mPortletHeadTools>
		<ul class="m-portlet__nav">
			<li class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
				<a href="#" class="m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn btn--sm m-btn--pill m-btn btn-outline-light m-btn--hover-light">
					2018
				</a>
				<div class="m-dropdown__wrapper">
					<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
					<div class="m-dropdown__inner">
						<div class="m-dropdown__body">
							<div class="m-dropdown__content">
								<ul class="m-nav">
									<li class="m-nav__section m-nav__section--first">
										<span class="m-nav__section-text">Reports</span>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-share"></i>
											<span class="m-nav__link-text">Activity</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-chat-1"></i>
											<span class="m-nav__link-text">Messages</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-info"></i>
											<span class="m-nav__link-text">FAQ</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-lifebuoy"></i>
											<span class="m-nav__link-text">Support</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</ng-container>

	<!-- use ng-container[mPortletBody] for the body content -->
	<ng-container mPortletBody>
		<div class="m-widget28">
			<div class="m-widget28__pic m-portlet-fit--sides"></div>
			<div class="m-widget28__container">
				<!-- begin::Nav pills -->
				<ul class="m-widget28__nav-items nav nav-pills nav-fill" role="tablist">
					<li class="m-widget28__nav-item nav-item">
						<a class="nav-link active" data-toggle="pill" href="#menu11"><span><i class="fa flaticon-pie-chart"></i></span><span>GMI Taxes</span></a>
					</li>
					<li class="m-widget28__nav-item nav-item">
						<a class="nav-link" data-toggle="pill" href="#menu21"><span><i class="fa flaticon-file-1"></i></span><span>IMT Invoice</span></a>
					</li>
					<li class="m-widget28__nav-item nav-item">
						<a class="nav-link" data-toggle="pill" href="#menu31"><span><i class="fa flaticon-clipboard"></i></span><span>Main Notes</span></a>
					</li>
				</ul>
				<!-- end::Nav pills -->

				<!-- begin::Tab Content -->
				<div class="m-widget28__tab tab-content">
					<div id="menu11" class="m-widget28__tab-container tab-pane active">
						<div class="m-widget28__tab-items">
							<div class="m-widget28__tab-item">
								<span>Company Name</span>
								<span>SLT Back-end Solutions</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>INE Number</span>
								<span>D330-1234562546</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>Total Charges</span>
								<span>USD 1,250.000</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>Project Description</span>
								<span>Creating Back-end Components</span>
							</div>
						</div>
					</div>
					<div id="menu21" class="m-widget28__tab-container tab-pane fade">
						<div class="m-widget28__tab-items">
							<div class="m-widget28__tab-item">
								<span>Project Description</span>
								<span>Back-End Web Architecture</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>Total Charges</span>
								<span>USD 2,170.000</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>INE Number</span>
								<span>D110-1234562546</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>Company Name</span>
								<span>SLT Back-end Solutions</span>
							</div>
						</div>
					</div>
					<div id="menu31" class="m-widget28__tab-container tab-pane fade">
						<div class="m-widget28__tab-items">
							<div class="m-widget28__tab-item">
								<span>Total Charges</span>
								<span>USD 3,450.000</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>Project Description</span>
								<span>Creating Back-end Components</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>Company Name</span>
								<span>SLT Back-end Solutions</span>
							</div>
							<div class="m-widget28__tab-item">
								<span>INE Number</span>
								<span>D510-7431562548</span>
							</div>
						</div>
					</div>
				</div>
				<!-- end::Tab Content -->
			</div>
		</div>
	</ng-container>
</m-portlet>
