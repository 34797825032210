import {
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {QuillEditorComponent} from 'ngx-quill';
import {debounceTime} from 'rxjs/operators';


@Component({
    selector: 'm-custom-email-remainder',
    templateUrl: './custom-email-remainder.component.html',
    styleUrls: ['./custom-email-remainder.component.scss']
})
export class CustomEmailRemainderComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('quillEditor') quill: QuillEditorComponent;
    @Input() message: string;
    @Input() noSaveButton: boolean = true;
    @Output() save: EventEmitter<string> = new EventEmitter<string>();

    texto: string = '';

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.message && changes.message.previousValue !== changes.message.currentValue) {
            this.texto = changes.message.currentValue;
        }
    }

    ngAfterViewInit() {
        this.quill.onContentChanged.pipe(debounceTime(1000)).subscribe(data => {
            this._save();
        });
    }

    setText(msg: string) {
        if (msg) {
            this.quill.quillEditor.updateContents(JSON.parse(msg));
        }
    }

    qlCustom1(ev) {
        try {
            const textField: string = '{' + ev.target.id + '}';
            this.quill.quillEditor.insertText(this.quill.quillEditor.getSelection().index, '{' + ev.target.id + '}', {
                'bold': true
            }, 'user');
            const pos: number = this.quill.quillEditor.getSelection().index + textField.length;
            this.quill.quillEditor.insertText(pos, ' ', {
                'bold': false
            }, 'user');
            this.quill.quillEditor.setSelection({index: pos + 1, length: 0});
        } catch (ex) {
            console.error('Probablemente el editor no tiene el foco');
        }
    }

    _save() {
        if (this.quill.quillEditor.getContents()) {
            this.save.emit(this.texto);
        } else {
            this.save.emit(undefined);
        }
    }

}

export const customEmailVariables = ['{nombreCompleto}', '{dia}', '{hora_ini}', '{hora_fin}'];

