<!-- <li class="m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width" m-dropdown-toggle="click"> -->
<a href="javascript:;" class="m-nav__link m-dropdown__toggle">
	<span class="m-nav__link-text">
		<img class="m-topbar__language-selected-img" src="{{language?.flag}}">
	</span>
</a>
<div class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
	<div class="m-dropdown__inner">
		<div class="m-dropdown__header m--align-center"
			style="background: url('../../assets/app/media/img/misc/quick_actions_bg.jpg'); background-size: cover;">
			<span class="m-dropdown__header-subtitle" translate="TRANSLATOR.SELECT"></span>
		</div>
		<div class="m-dropdown__body">
			<div class="m-dropdown__content">
				<ul class="m-nav m-nav--skin-light">
					<ng-container *ngFor="let language of languages">
						<li class="m-nav__item" [ngClass]="{'m-nav__item--active': language.active}">
							<a href="javascript:;" (click)="setLanguage(language.lang)" [ngClass]="{'m-nav__link--active': language.active}" class="m-nav__link">
								<span class="m-nav__link-icon">
									<img class="m-topbar__language-img" src="{{language.flag}}">
								</span>
								<span class="m-nav__link-title m-topbar__language-text m-nav__link-text">{{language.country}}</span>
							</a>
						</li>
					</ng-container>
				</ul>
			</div>
		</div>
	</div>
</div>
<!-- </li> -->
