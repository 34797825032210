import {Injectable} from '@angular/core';

import {AuthenticationService} from './authentication.service';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {SnackService} from '../../snack.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthenticationService, private error: SnackService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('auth-interceptor', req);

        return next.handle(req).pipe(
            tap(evt => {
                console.log('auth-interceptor evt', evt);
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    console.log('auth-interceptor catchError');
                    let data = {reason: '', status: null, message: ''};
                    let errorMessage: string = '';


                    console.log('errorResponse', errorResponse);
                    if (errorResponse) {
                        if (errorResponse.error && errorResponse.error.message && typeof errorResponse.error.message === 'string') {
                            errorMessage = errorResponse.error.message;
                        }
                        if (errorResponse.error && Array.isArray(Object.values(errorResponse.error))) {
                            Object.values(errorResponse.error).forEach(e => {
                                if (Array.isArray(e) && e.length > 0) {
                                    errorMessage += e[0] + '\r\n';
                                } else {
                                    // @ts-ignore
                                    if (e.message) {
                                        // @ts-ignore
                                        errorMessage += e.message + '\r\n';
                                    }
                                }
                            });
                        }
                    }
                    data = {
                        reason: errorMessage,
                        message: errorMessage, // no se porque en unos sitio uso message pero desde aquí devuelvo reason así que lo añado como atributo con el mismo valor que reason
                        status: errorResponse.status
                    };

                    if (data.status == 401) {
                        console.log('auth interceptor -> 401 data', data);
                        /*this.auth.setRequestStatus({code: data.status, message: data.message});*/
                        this.error.showSnack.next({code: data.status, message: data.message});
                    }
                    if (data.status == 403) {
                        this.auth.logout(true);
                    }
                    console.log('errorResponse data', data);
                    return throwError(data);
                } catch (e) {
                    console.log('authInterceptor error', e);
                }

            }));
    }
}
