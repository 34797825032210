import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, HostBinding, Input, isDevMode, OnInit, ViewChild,
} from '@angular/core';
import {LayoutConfigService} from './core/services/layout-config.service';
import {ClassInitService} from './core/services/class-init.service';
import {TranslationService} from './core/services/translation.service';
import * as objectPath from 'object-path';
import {DomSanitizer} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {PageConfigService} from './core/services/page-config.service';
import {filter} from 'rxjs/operators';
import {SplashScreenService} from './core/services/splash-screen.service';


// language list
import {locale as enLang} from './config/i18n/en';
import {locale as esLang} from './config/i18n/es';
import {locale as caLang} from './config/i18n/ca';
import {ResizewindowService} from './core/util/resizewindow.service';

// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnInit {
    title = 'Metronic';

    @HostBinding('style') style: any;
    @HostBinding('class') classes: any = '';

    @ViewChild('splashScreen', {read: ElementRef})
    splashScreen: ElementRef;
    splashScreenImage: string;

    public width: number = 0;
    public isDevMode = isDevMode();

    constructor(
        private layoutConfigService: LayoutConfigService,
        private classInitService: ClassInitService,
        private sanitizer: DomSanitizer,
        private translationService: TranslationService,
        private router: Router,
        private pageConfigService: PageConfigService,
        private splashScreenService: SplashScreenService,
        private resizeWindowsWidth: ResizewindowService,
        private cdr: ChangeDetectorRef
    ) {
        // subscribe to class update event
        this.classInitService.onClassesUpdated$.subscribe(classes => {
            // get body class array, join as string classes and pass to host binding class
            setTimeout(() => this.classes = classes.body.join(' '));
        });


        this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
            this.classInitService.setConfig(model);

            this.style = '';
            if (objectPath.get(model.config, 'self.layout') === 'boxed') {
                const backgroundImage = objectPath.get(model.config, 'self.background');
                if (backgroundImage) {
                    this.style = this.sanitizer.bypassSecurityTrustStyle(
                        'background-image: url(' + objectPath.get(model.config, 'self.background') + ')'
                    );
                }
            }
            this.splashScreenImage = objectPath.get(model.config, 'loader.image');
            // console.log('splashscreen',this.splashScreenImage);
        });

        // register translations
        this.translationService.loadTranslations(enLang, caLang, esLang);

        // override config by router change from pages config
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                this.layoutConfigService.setModel({page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config')}, true);
            });
    }

    ngOnInit(): void {
        this.resizeWindowsWidth.$resize.subscribe(width => {
            this.width = width;
            this.cdr.detectChanges();
        });
        this.width = this.resizeWindowsWidth.width;
    }

    ngAfterViewInit(): void {
        if (this.splashScreen) {
            this.splashScreenService.init(this.splashScreen.nativeElement);
        }
    }
}
